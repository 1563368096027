import React, { FC, SVGProps } from 'react';

export const PaymentIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#EB3D3D',
  ...props
}) => (
  <svg
    fill='none'
    height='20'
    viewBox='0 0 20 20'
    width='20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119ZM6.22534 10.8379C5.94604 10.8379 5.71753 10.7681 5.53979 10.6284C5.36841 10.4824 5.28271 10.2793 5.28271 10.019C5.28271 9.75244 5.36841 9.54614 5.53979 9.40015C5.71118 9.25415 5.9397 9.18115 6.22534 9.18115H13.9187C14.2043 9.18115 14.4297 9.25415 14.5947 9.40015C14.7661 9.54614 14.8518 9.75244 14.8518 10.019C14.8518 10.2793 14.7629 10.4824 14.5852 10.6284C14.4138 10.7681 14.1917 10.8379 13.9187 10.8379H6.22534Z'
      fill={color}
    />
  </svg>
);
