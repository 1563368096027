import React, { FC } from 'react';

import { Box, VStack, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AppButton } from '@/elements';

import { ISimpleMerchant } from '@/types/domain';

interface IMerchantSelect {
  selected?: string;
  merchants: ISimpleMerchant[];
  onChange: (merchant: ISimpleMerchant) => void;
}

/**
 * Component that renders merchants and gives ability to choose one
 * @component
 * @param {IMerchantSelect} props
 * @param {ISimpleMerchant[]} props.merchants
 * @param {(merchant: ISimpleMerchant) => void} props.onChange
 * @example
 * <MerchantSelect merchants={merchants} onChange={merchant => console.log(merchant)} />
 */
const MerchantSelect: FC<IMerchantSelect> = ({
  selected,
  merchants,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <VStack spacing='lg' width='100%'>
      {merchants.map((x) => (
        <AppButton
          borderRadius='lg'
          height='auto'
          isActive={selected === x.displayId}
          key={x.displayId}
          onClick={() => onChange(x)}
          size='lg'
          variant='outlined'
        >
          <Box>
            <Flex direction='column'>
              {t('merchantProfile.merchantId.label')}: <Box>{x.displayId}</Box>
            </Flex>

            <Flex direction='column'>
              {t('merchantProfile.merchantName.label')}: <Box>{x.name}</Box>
            </Flex>
          </Box>
        </AppButton>
      ))}
    </VStack>
  );
};

export default MerchantSelect;
