import React, {
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel as ChakraLabel,
  Image,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Select, {
  components,
  DropdownIndicatorProps,
  MultiValue,
  MultiValueProps,
  OptionProps,
  Props,
  StylesConfig,
  Theme,
} from 'react-select';

import { ReactComponent as DropdownArrowClose } from '@/assets/icons/dropdown-arrow-close.svg';
import { ReactComponent as DropdownArrowOpen } from '@/assets/icons/dropdown-arrow-open.svg';
import { ReactComponent as RadioEmpty } from '@/assets/icons/radio-empty.svg';
import { ReactComponent as RadioSelected } from '@/assets/icons/radio-selected.svg';
import { FormLabel } from '@/elements';

import { ISelectOption } from '@/types/domain';

export type MultiSelectProps = Omit<
  Props,
  | 'onChange'
  | 'indicator'
  | 'formatValue'
  | 'floatingLabel'
  | 'options'
  | 'value'
  | 'variant'
> & {
  containerProps?: FormControlProps;
  error?: string;
  value?: unknown[];
  options: ISelectOption[];
  onChange: (val: ISelectOption[] | null) => void;
  indicator?: ReactNode;
  formatValue?: (values: ISelectOption[]) => string;
  floatingLabel?: boolean;
  allOptionLabel?: string;
  indicatorPosition?: 'left' | 'right';
  variant?: 'primary' | 'secondary';
  hasTooltip?: boolean;
  label?: string;
  name: string;
  tooltipText?: string | React.ReactNode;
  defaultValue?: ISelectOption;
  autoHeight?: boolean;
  id?: string;
  search?: string;
  isInvalid?: boolean;
  onSearchChange?: (search: string) => void;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps } = props;

  return (
    <components.DropdownIndicator {...props}>
      {(selectProps as any).indicator ? (
        (selectProps as any).indicator
      ) : selectProps.menuIsOpen ? (
        <DropdownArrowOpen />
      ) : (
        <DropdownArrowClose />
      )}
    </components.DropdownIndicator>
  );
};

const InputOption = (props: OptionProps) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props}>
      <Flex alignItems='center' justifyContent='space-between'>
        <Box width='90%'>{children}</Box>
        {isSelected ? <RadioSelected /> : <RadioEmpty />}
      </Flex>
    </components.Option>
  );
};

const MultiValueComponent = (props: MultiValueProps) => {
  const { index, selectProps } = props;

  return (
    <Flex flexDir='column' justifyContent='center'>
      {!index && (
        <>
          {(selectProps as any).floatingLabel && (
            <ChakraLabel
              fontSize='10px'
              fontWeight='500'
              htmlFor={selectProps.inputId}
              m='0'
            >
              {(selectProps as any).label}
            </ChakraLabel>
          )}
          <Flex fontSize='13px' fontWeight='700'>
            {(selectProps as any).formatValue(selectProps.value)}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export const MultiSelect = forwardRef<any, MultiSelectProps>(
  (
    {
      onChange,
      options,
      hasTooltip = false,
      tooltipText,
      containerProps,
      error,
      isInvalid,
      value: rawValue,
      isDisabled,
      id,
      closeMenuOnSelect,
      search,
      onSearchChange,
      allOptionLabel,
      formatValue,
      indicatorPosition = 'right',
      variant = 'primary',
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const customTheme = useCallback(
      (inputTheme: Theme) => ({
        ...inputTheme,
        borderRadius: 0,
        colors: {
          ...inputTheme.colors,
          primary: theme.colors.app.blue,
          primary75: theme.colors.app.lightBlue,
          primary50: theme.colors.app.lightBlue,
          primary25: theme.colors.app.lightBlue,
          neutral10: theme.colors.app.lightBlue,
          neutral20: theme.colors.app.lightBlue,
          neutral30: theme.colors.app.blue,
          neutral40: theme.colors.app.blue,
          neutral50: theme.colors.app.blue,
          neutral60: theme.colors.app.blue,
          neutral70: theme.colors.app.blue,
          neutral80: theme.colors.app.textDark,
          neutral90: theme.colors.app.blue,
        },
      }),
      [
        theme.colors.app.blue,
        theme.colors.app.lightBlue,
        theme.colors.app.textDark,
      ],
    );
    const customStyles = useMemo<StylesConfig<any, boolean, any>>(
      () => ({
        multiValue: (provided, state) => ({
          ...provided,
          background: theme.colors.app.lightBlue,
          color: theme.colors.app.textDark,
          textTransform: 'uppercase',
          fontWeight: '600',
          paddingRight: state.data.isDisabled ? 'xs' : provided.paddingRight,
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          display: state.data.isDisabled ? 'none' : provided.display,
        }),
        option: (provided, { isDisabled }) => ({
          ...provided,
          backgroundColor: 'transparent',
          opacity: isDisabled ? '0.5' : '1',
          marginTop: '0.063rem',
          color: theme.colors.app.black,
          padding: '8px 9px',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          transition: 'all 0.5s ease-out',
          ':hover': {
            backgroundColor: theme.colors.app.neutralGray,
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: theme.colors.app.widgetGray,
        }),
        control: (_, state) => ({
          minHeight: '2.813rem',
          display: 'flex',
          paddingTop: '3px',
          paddingBottom: '3px',
          outline: 'none',
          boxShadow: 'none',
          borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
          border: '1px solid',
          borderBottom: state.menuIsOpen ? 'none' : '1px solid',
          borderColor: isInvalid
            ? theme.colors.app.widgetRed
            : state.isFocused
            ? theme.colors.app.widgetGray
            : theme.colors.app.borderGray,
          background: theme.colors.app.white,
          transition: 'all 0.5s ease-out',
          opacity: state.isDisabled ? '0.5' : '1',
          flexDirection: indicatorPosition === 'right' ? 'row' : 'row-reverse',
          cursor: 'pointer',
          ...(variant === 'secondary' && {
            borderRadius: '4px',
            borderBottom: '1px solid',
          }),
          ':hover': {
            borderColor: theme.colors.app.widgetGray,
          },
        }),
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          minWidth: '15.625rem',
          borderRadius: '0 0 0.25rem 0.25rem',
          border: '1px solid',
          borderColor: theme.colors.app.widgetGray,
          boxShadow: 'none',
          marginTop: '0px',
          ...(variant === 'secondary' && {
            marginTop: '0.625rem',
            borderRadius: '0.25rem',
          }),
        }),
        menuList: (provided) => ({
          ...provided,
          zIndex: 9999,
          paddingTop: '0px',
        }),
        indicatorSeparator: () => ({ display: 'none' }),
      }),
      [
        theme.colors.app.lightBlue,
        theme.colors.app.textDark,
        theme.colors.app.black,
        theme.colors.app.neutralGray,
        theme.colors.app.widgetGray,
        theme.colors.app.white,
        theme.colors.app.widgetRed,
        theme.colors.app.borderGray,
        indicatorPosition,
        isInvalid,
        variant,
      ],
    );
    const value = useMemo(() => rawValue ?? [], [rawValue]);
    const selectOptions = useMemo(
      () =>
        allOptionLabel
          ? [{ label: allOptionLabel, value: 'all' }, ...options]
          : options,
      [allOptionLabel, options],
    );
    const defaultValueFormatter = useCallback(
      (values) => t('multiselect.value', { numOfSelected: values?.length }),
      [t],
    );
    const valueFormatter = formatValue ?? defaultValueFormatter;

    const handleChange = useCallback(
      (opt: MultiValue<ISelectOption>) => {
        const newValues = opt?.map((x) => x.value) ?? [];
        const actualNewValues = newValues.filter((x) => x !== 'all');
        const areAllSelected = options.every((x) =>
          value.includes(x.value as string),
        );

        if (newValues.includes('all') && !areAllSelected) {
          onChange(options.map((x) => x.value));
          return;
        }
        if (!newValues.includes('all') && areAllSelected) {
          onChange([]);
          return;
        }

        const disabledOptions =
          value.filter((x) =>
            options.some((y: any) => y.value === x && y.isDisabled),
          ) ?? [];

        onChange(Array.from(new Set([...actualNewValues, ...disabledOptions])));
      },
      [onChange, options, value],
    );

    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    const components = useMemo(
      () => ({
        DropdownIndicator,
        Option: InputOption,
        IndicatorSeparator: () => null,
        MultiValue: MultiValueComponent,
      }),
      [],
    );
    const selectValue = useMemo(() => {
      const selectedOptions = options.filter((x) => value.includes(x.value));
      if (allOptionLabel && options.every((x) => value.includes(x.value))) {
        return [selectOptions[0], ...selectedOptions];
      }
      return selectedOptions;
    }, [allOptionLabel, options, selectOptions, value]);

    const noFilter = useCallback(() => true, []);

    return (
      <FormControl {...containerProps} id={inputId} isInvalid={isInvalid}>
        {rest.label && !rest?.floatingLabel && (
          <FormLabel
            hasTooltip={hasTooltip}
            id={inputId}
            label={rest.label}
            tooltipText={tooltipText}
          />
        )}

        <Select
          closeMenuOnSelect={closeMenuOnSelect || false}
          components={components}
          filterOption={noFilter}
          formatOptionLabel={(option) => (
            <Flex alignItems='center'>
              {option.image ? (
                typeof option.image === 'string' ? (
                  <Image boxSize='5' src={option.image} />
                ) : (
                  <option.image />
                )
              ) : null}
              <Box ml='0.5rem'>{option.label}</Box>
            </Flex>
          )}
          hideSelectedOptions={false}
          inputId={inputId}
          inputValue={search}
          isClearable={false}
          isDisabled={isDisabled}
          isSearchable={false}
          menuPortalTarget={document.body}
          menuPosition='absolute'
          onChange={handleChange}
          onInputChange={onSearchChange}
          options={selectOptions}
          ref={ref as RefObject<any>}
          styles={customStyles}
          theme={customTheme}
          value={selectValue}
          {...{
            // hack to hide error about unknown prop
            // select forwards props to its child components, which is needed in this case
            formatValue: valueFormatter,
          }}
          {...rest}
          isMulti
        />
        <FormErrorMessage fontSize='sm'>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

MultiSelect.displayName = 'MultiSelect';
