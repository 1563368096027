import React, { FC, SVGProps } from 'react';

export const InsufficientIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#A7A7A7',
  ...props
}) => (
  <svg
    fill='none'
    height={23}
    viewBox='0 0 25 23'
    width={25}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.2676 19.7705C11.5942 19.7705 12.8416 19.5166 14.0095 19.0088C15.1838 18.501 16.2185 17.7996 17.1135 16.9045C18.0085 16.0095 18.71 14.978 19.2178 13.8101C19.7256 12.6357 19.9795 11.3853 19.9795 10.0586C19.9795 8.73193 19.7256 7.48462 19.2178 6.31665C18.71 5.14233 18.0085 4.10767 17.1135 3.21265C16.2185 2.31763 15.1838 1.61621 14.0095 1.1084C12.8352 0.600586 11.5847 0.34668 10.2581 0.34668C8.9314 0.34668 7.68091 0.600586 6.50659 1.1084C5.33862 1.61621 4.30713 2.31763 3.41211 3.21265C2.52344 4.10767 1.8252 5.14233 1.31738 6.31665C0.80957 7.48462 0.555664 8.73193 0.555664 10.0586C0.555664 11.3853 0.80957 12.6357 1.31738 13.8101C1.8252 14.978 2.52661 16.0095 3.42163 16.9045C4.31665 17.7996 5.34814 18.501 6.51611 19.0088C7.69043 19.5166 8.94092 19.7705 10.2676 19.7705ZM9.20117 14.7051C9.03613 14.7051 8.88696 14.6702 8.75366 14.6003C8.62036 14.5305 8.49023 14.4131 8.36328 14.248L5.97339 11.3154C5.90356 11.2202 5.84644 11.1218 5.802 11.0203C5.76392 10.9124 5.74487 10.8044 5.74487 10.6965C5.74487 10.4807 5.81787 10.2966 5.96387 10.1443C6.10986 9.9856 6.29077 9.90625 6.50659 9.90625C6.64624 9.90625 6.77002 9.93481 6.87793 9.99194C6.99219 10.0491 7.10962 10.157 7.23022 10.3157L9.16309 12.8103L13.2288 6.27856C13.4065 5.98657 13.635 5.84058 13.9143 5.84058C14.1174 5.84058 14.3015 5.90723 14.4666 6.04053C14.6316 6.16748 14.7141 6.34204 14.7141 6.56421C14.7141 6.67212 14.6887 6.78003 14.6379 6.88794C14.5872 6.99585 14.5332 7.09741 14.4761 7.19263L10.001 14.248C9.89941 14.4004 9.78198 14.5146 9.64868 14.5908C9.51538 14.667 9.36621 14.7051 9.20117 14.7051Z'
      fill={color}
    />
    <path
      d='M18.4358 19.0329L18.4359 19.0329L18.4304 19.0276C18.3461 18.9467 18.3076 18.8575 18.3076 18.7363C18.3076 18.6147 18.3467 18.5194 18.4358 18.4302C18.5273 18.3387 18.6204 18.302 18.7324 18.302C18.8583 18.302 18.9482 18.3423 19.0282 18.4289L19.0332 18.4344L19.0385 18.4397C19.13 18.5312 19.1667 18.6243 19.1667 18.7363C19.1667 18.8575 19.1282 18.9467 19.044 19.0276L19.044 19.0275L19.0385 19.0329C18.9494 19.1221 18.854 19.1611 18.7324 19.1611C18.6204 19.1611 18.5273 19.1244 18.4358 19.0329ZM18.4598 13.9841L18.4598 13.9825C18.4562 13.8719 18.4857 13.8195 18.5215 13.7837C18.5586 13.7466 18.6179 13.7126 18.7419 13.7126C18.8558 13.7126 18.9135 13.7443 18.9529 13.7837C18.9886 13.8195 19.0182 13.8719 19.0146 13.9825L19.0146 13.9841L18.9385 16.7046C18.9354 16.7723 18.9147 16.8145 18.8767 16.8525C18.8561 16.8731 18.8236 16.895 18.7419 16.895C18.6586 16.895 18.6158 16.8748 18.5881 16.8517C18.558 16.8165 18.539 16.7731 18.5359 16.7047L18.4598 13.9841ZM18.7324 21.6631C19.4363 21.6631 20.1062 21.5261 20.7386 21.2511C21.3648 20.9789 21.918 20.6036 22.3958 20.126C22.8804 19.648 23.2597 19.0942 23.5324 18.4669C23.8076 17.834 23.9443 17.1608 23.9443 16.4512C23.9443 15.7363 23.808 15.0604 23.5331 14.4275C23.2669 13.8002 22.8941 13.2461 22.4158 12.7678C21.9375 12.2894 21.3834 11.9167 20.7561 11.6505C20.1232 11.3756 19.4473 11.2393 18.7324 11.2393C18.0233 11.2393 17.3507 11.3758 16.7183 11.6505C16.0902 11.917 15.5359 12.2934 15.0576 12.7783C14.5802 13.2559 14.205 13.8089 13.9328 14.4347C13.657 15.0624 13.5205 15.736 13.5205 16.4512C13.5205 17.1667 13.6571 17.8431 13.9325 18.4764C14.2049 19.103 14.5805 19.6565 15.0586 20.1346C15.5369 20.6129 16.0909 20.9856 16.7182 21.2518C17.3507 21.5265 18.0233 21.6631 18.7324 21.6631Z'
      fill='#EB3D3D'
      stroke='white'
      strokeWidth='0.75'
    />
  </svg>
);
