export enum ApiTagsEnum {
  Merchant = 'Merchant',
  LinkedMerchants = 'LinkedMerchants',
  MerchantUser = 'MerchantUser',
  SimpleMerchant = 'SimpleMerchant',
  User = 'User',
  UserPendingAction = 'UserPendingAction',
  AdminUser = 'AdminUser',
  AdminNetwork = 'AdminNetwork',
  AcceptedCoin = 'AcceptedCoin',
  AvailableCoin = 'AvailableCoin',
  AdminMerchants = 'AdminMerchants',
  MerchantReport = 'MerchantReport',
  MerchantPendingAction = 'MerchantPendingAction',
  MerchantTransfer = 'MerchantTransfer',
  MerchantBanner = 'MerchantBanner',
  MerchantTransaction = 'MerchantTransaction',
  ApiKey = 'ApiKey',
  NotificationKey = 'NotificationKey',
  MerchantDetail = 'MerchantDetail',
  MerchantReportList = 'MerchantReportList',
  AdminTransfer = 'AdminTransfer',
  AdminTransaction = 'AdminTransaction',
  AdminTransactionDetails = 'AdminTransactionDetails',
  TransactionDetails = 'TransactionDetails',
  MerchantCashbackTransaction = 'MerchantCashbackTransaction',
  AdminReportList = 'AdminReportList',
  AdminReport = 'AdminReport',
  AdminMerchantUser = 'AdminMerchantUser',
  MerchantInvitedUser = 'MerchantInvitedUser',
  AdminInvitedUser = 'AdminInvitedUser',
  AdminAvailableCoins = 'AdminAvailableCoins',
  MerchantFiat = 'MerchantFiat',
  UserRole = 'UserRole',
  MerchantUserRole = 'MerchantUserRole',
  ExcludedPermissions = 'ExcludedPermissions',
  IncludedPermissions = 'IncludedPermissions',
  Audit = 'Audit',
}
