import React, { FC, SVGProps } from 'react';

export const FailureIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#A7A7A7',
  ...props
}) => (
  <svg
    fill='none'
    height={23}
    viewBox='0 0 23 23'
    width={23}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.9375 19.75C11.2642 19.75 12.5115 19.4961 13.6794 18.9883C14.8538 18.4805 15.8884 17.7791 16.7834 16.884C17.6785 15.989 18.3799 14.9575 18.8877 13.7896C19.3955 12.6152 19.6494 11.3647 19.6494 10.0381C19.6494 8.71143 19.3955 7.46411 18.8877 6.29614C18.3799 5.12183 17.6785 4.08716 16.7834 3.19214C15.8884 2.29712 14.8538 1.5957 13.6794 1.08789C12.5051 0.580078 11.2546 0.326172 9.92798 0.326172C8.60132 0.326172 7.35083 0.580078 6.17651 1.08789C5.00854 1.5957 3.97705 2.29712 3.08203 3.19214C2.19336 4.08716 1.49512 5.12183 0.987305 6.29614C0.479492 7.46411 0.225586 8.71143 0.225586 10.0381C0.225586 11.3647 0.479492 12.6152 0.987305 13.7896C1.49512 14.9575 2.19653 15.989 3.09155 16.884C3.98657 17.7791 5.01807 18.4805 6.18604 18.9883C7.36035 19.4961 8.61084 19.75 9.9375 19.75ZM8.87109 14.6846C8.70605 14.6846 8.55688 14.6497 8.42358 14.5798C8.29028 14.51 8.16016 14.3926 8.0332 14.2275L5.64331 11.2949C5.57349 11.1997 5.51636 11.1013 5.47192 10.9998C5.43384 10.8918 5.41479 10.7839 5.41479 10.676C5.41479 10.4602 5.48779 10.2761 5.63379 10.1238C5.77979 9.96509 5.96069 9.88574 6.17651 9.88574C6.31616 9.88574 6.43994 9.91431 6.54785 9.97144C6.66211 10.0286 6.77954 10.1365 6.90015 10.2952L8.83301 12.7898L12.8987 6.25806C13.0764 5.96606 13.3049 5.82007 13.5842 5.82007C13.7874 5.82007 13.9714 5.88672 14.1365 6.02002C14.3015 6.14697 14.384 6.32153 14.384 6.5437C14.384 6.65161 14.3586 6.75952 14.3079 6.86743C14.2571 6.97534 14.2031 7.0769 14.146 7.17212L9.6709 14.2275C9.56934 14.3799 9.4519 14.4941 9.3186 14.5703C9.1853 14.6465 9.03613 14.6846 8.87109 14.6846Z'
      fill={color}
    />
    <path
      d='M15.4358 19.145L15.4357 19.1449L15.4291 19.1519C15.381 19.2025 15.3465 19.2111 15.3012 19.2076C15.2293 19.2021 15.1763 19.177 15.1257 19.1237L15.1188 19.1163L15.1114 19.1094C15.0581 19.0588 15.033 19.0058 15.0275 18.9339C15.0239 18.888 15.0332 18.8468 15.0901 18.7898L16.4803 17.3997L16.7435 17.1365L16.4822 16.8713L15.2063 15.5764L15.2063 15.5764L15.2044 15.5744C15.159 15.5291 15.1428 15.4874 15.1428 15.4302C15.1428 15.3625 15.1616 15.3266 15.1967 15.2934C15.2438 15.2489 15.2858 15.2339 15.3391 15.2339C15.4018 15.2339 15.4453 15.2512 15.4883 15.2908L16.7793 16.5723L17.0444 16.8355L17.3086 16.5713L18.7083 15.1717C18.7542 15.1258 18.7907 15.1145 18.8428 15.1185C18.921 15.1245 18.9761 15.1509 19.0254 15.2002C19.0848 15.2596 19.111 15.3194 19.1166 15.3923C19.1201 15.4375 19.1114 15.4721 19.0608 15.5201L19.0607 15.52L19.053 15.5277L17.6629 16.9274L17.3995 17.1926L17.6638 17.4568L18.9446 18.7376C18.9814 18.7778 19.0012 18.8233 19.0012 18.896C19.0012 18.9574 18.9849 18.9936 18.9528 19.027C18.9103 19.0658 18.867 19.0828 18.8049 19.0828H18.7974L18.7899 19.0831C18.7379 19.0851 18.7024 19.0724 18.6607 19.0307L18.6597 19.0298L17.3648 17.7444L17.0996 17.4811L16.8354 17.7453L15.4358 19.145ZM17.0625 22.375C17.7663 22.375 18.4363 22.238 19.0687 21.963C19.6948 21.6908 20.2481 21.3155 20.7259 20.8379C21.2105 20.3599 21.5897 19.8061 21.8625 19.1788C22.1376 18.5459 22.2744 17.8728 22.2744 17.1631C22.2744 16.4482 22.1381 15.7723 21.8631 15.1394C21.597 14.5121 21.2242 13.9581 20.7459 13.4797C20.2675 13.0014 19.7135 12.6286 19.0862 12.3624C18.4533 12.0875 17.7774 11.9512 17.0625 11.9512C16.3534 11.9512 15.6808 12.0877 15.0483 12.3624C14.4203 12.6289 13.866 13.0053 13.3877 13.4902C12.9103 13.9678 12.5351 14.5208 12.2628 15.1467C11.9871 15.7743 11.8506 16.4479 11.8506 17.1631C11.8506 17.8786 11.9872 18.555 12.2625 19.1883C12.535 19.8149 12.9106 20.3684 13.3886 20.8465C13.867 21.3248 14.421 21.6976 15.0483 21.9637C15.6807 22.2384 16.3534 22.375 17.0625 22.375Z'
      fill='#EB3D3D'
      stroke='white'
      strokeWidth='0.75'
    />
  </svg>
);
