export const getMerchantRoute = (path: string, merchantDisplayId?: string) =>
  `/merchant/${merchantDisplayId ?? ':merchantDisplayId'}/${path}`;

export const AppRoutesEnum = {
  // other
  main: '/',
  page404: '*',
  thankYou: '/thank-you',
  expiredToken: '/expired-token',
  wrongToken: '/wrong-token',
  changedEmail: '/changed-email',
  confirmEmail: '/confirm-email',
  // auth
  login: '/login',
  requestResetPassword: '/request-reset-password',
  forgotPassword: '/forgot-password',
  signup: '/signup',
  merchantVerify: '/merchant_verify',
  kycFeedback: '/kyc-feedback',
  alreadyConfirmed: '/already-confirmed',
  handleActionConfirm: '/handle-action/confirm',
  handleActionInvite: '/handle-action/invite',
  // merchant
  summary: getMerchantRoute('summary'),
  merchantProfile: getMerchantRoute('profile'),
  settlement: getMerchantRoute('settlement'),
  users: getMerchantRoute('users'),
  linked: getMerchantRoute('linked'),
  storeSettings: getMerchantRoute('store-settings'),
  cashback: getMerchantRoute('cashback'),
  paymentWidget: getMerchantRoute('payment-widget'),
  remunoShopDemo: getMerchantRoute('remuno-shop-demo'),
  coinsSettings: getMerchantRoute('coins-settings'),
  viewTransactions: getMerchantRoute('view-transactions'),
  transactionDetails: getMerchantRoute('view-transactions/:txnId'),
  merchantTransfer: getMerchantRoute('merchant-transfer'),
  viewCashback: getMerchantRoute('view-cashback'),
  merchantLastMonthReports: getMerchantRoute('last-month-reports'),
  merchantReporting: getMerchantRoute('reporting'),
  merchantVerification: getMerchantRoute('verification'),
  apiKeys: getMerchantRoute('api-keys'),
  roleManagement: getMerchantRoute('role-management'),

  // user
  profileSettings: '/profile/settings',
  security: '/profile/security',
  // admin
  adminUsers: '/admin/users',
  merchants: '/admin/merchants',
  networks: '/admin/networks',
  merchantUsers: '/admin/merchant-users',
  systemSettlementTransfers: '/admin/system-settlement-transfers',
  systemSettlementTransactions: '/admin/system-settlement-transactions',
  adminTransactionDetails: '/admin/system-settlement-transactions/:txnId',
  adminManager: '/admin/admin-manager',
  reporting: '/admin/reporting',
  adminSummary: '/admin/summary',
  lastMonthReports: '/admin/last-month-reports',
  adminMerchantTransfer: '/admin/merchant-transfers',
  systemMaintenance: '/admin/system-maintenance',
  adminRoleManagement: '/admin/system-role-management',
};

export const addMerchantIdToRoute = (
  path: string,
  merchantDisplayId?: string,
) =>
  path.replace(
    '/:merchantDisplayId',
    `/${merchantDisplayId ?? ':merchantDisplayId'}`,
  );
