import { TFunction } from 'i18next';
import * as yup from 'yup';

import { checkIfFilesAreTooBig } from '@/utils/files';

export const lowerCaseRegexp = /^(?=.*[a-z])/;
export const upperCaseRegexp = /^(?=.*[A-Z])/;
export const containNumberRegexp = /^(?=.*[0-9])/;
export const specialSymbolRegexp = /[^a-zA-Z0-9]+/;
export const neededLengthRegexp = /^.{8,20}$/;
export const twoDigitsAfterCommaRegexp = /^\d+(\.\d{0,2})?$/;

/**
 * Regular expression for email validation
 * @constant
 * @type {RegExp}
 **/
const regExpForEmail = new RegExp(
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  '',
);

/**
 * Function to check if there is at least one lowercase letter in the string
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isLowerCase = (t: TFunction) => {
  return yup
    .string()
    .matches(lowerCaseRegexp, t('validationErrors.atLeastOneLowercase'));
};

/**
 * Function to check if there is at least one uppercase letter in the string
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isUpperCase = (t: TFunction) => {
  return yup
    .string()
    .matches(upperCaseRegexp, t('validationErrors.atLeastOneUppercase'));
};

/**
 * Function to check if there is at least one number in the string
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isContainNumber = (t: TFunction) => {
  return yup
    .string()
    .matches(containNumberRegexp, t('validationErrors.atLeastOneNumber'));
};

/**
 * Function to check if there is at least one special symbol in the string
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isContainSpecialSymbol = (t: TFunction) => {
  return yup
    .string()
    .matches(specialSymbolRegexp, t('validationErrors.atLeastOneSpecSymbol'));
};

/**
 * Function to check if the string contains from 8 to 20 characters
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const haveEightCharLength = (t: TFunction) => {
  return yup
    .string()
    .matches(neededLengthRegexp, t('validationErrors.shouldBeFrom8To20Chars'));
};

/**
 * Function to check if the email format is correct or not
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isEmailFormatValid = (t: TFunction) => {
  return yup
    .string()
    .matches(regExpForEmail, t('validationErrors.invalidEmailFormat'));
};

/**
 * Function to check if the string don't contain whitespaces
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isWithoutWhitespaces = (t: TFunction) => {
  return yup.string().matches(/^\S*$/, t('validationErrors.noWhitespaces'));
};

/**
 * Function to check if the website link is valid
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const isWebsiteLinkValid = (t: TFunction) => {
  return yup
    .string()
    .matches(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
      t('validationErrors.isWebsiteLinkValid'),
    );
};

/**
 * Function to check if the uploaded file is valid
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const validateUploadedFile = (t: TFunction) => {
  return yup
    .mixed()
    .test(
      'required',
      t('validationErrors.requiredField', {
        name: t('common.this'),
      }),
      (value) => {
        return value && value.length;
      },
    )
    .test(
      'fileSize',
      t('validationErrors.notExceed4MB'),
      checkIfFilesAreTooBig,
    );
};

/**
 * Function to check if the value have maximum two decimal places
 * @function
 * @param {TFunction} t - translator function
 * @return {yup} - Yup validation chain
 */
export const validateMaxTwoDecimalPlaces = (t: TFunction) => {
  return yup
    .number()
    .typeError(t('validationErrors.mustBeNumber'))
    .test('required', t('validationErrors.requiredField'), (value) => {
      return typeof value === 'number';
    })
    .test(
      'is-decimal',
      t('validationErrors.twoDigitsAfterComma'),
      (value: any) => {
        if (value !== undefined) {
          return twoDigitsAfterCommaRegexp.test(value);
        }
        return false;
      },
    );
};
