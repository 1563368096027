import React from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { DropdownIndicatorProps } from 'react-select';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps } = props;
  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = <TriangleUpIcon color='app.widgetGray' fontSize='sm' />;
  } else {
    arrow = <TriangleDownIcon color='app.widgetGray' fontSize='sm' />;
  }
  return (
    <Flex alignItems='top' pl='4px'>
      {arrow}
    </Flex>
  );
};

export default DropdownIndicator;
