import React from 'react';

import { Input as ChakraInput } from '@chakra-ui/react';

interface CodeInputProps {
  handleCode: (
    ev: React.ChangeEvent<HTMLInputElement>,
    value: string,
    index: number,
  ) => void;
  handleKey: (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  char: string;
  index: number;
  maxLength: number;
}

const VerificationCodeInput = ({
  handleCode,
  handleKey,
  char,
  index,
  maxLength,
}: CodeInputProps) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = /^\d*$/;
    const target = ev.currentTarget as HTMLInputElement;
    const isValidChar = pattern.test(target.value);

    if (!isValidChar) return;

    handleCode(ev, target.value, index);
  };
  const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    (ev.currentTarget as HTMLInputElement).select();
  };

  return (
    <ChakraInput
      autoComplete='one-time-code'
      inputMode='numeric'
      maxLength={maxLength}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={(ev) => handleKey(ev, index)}
      px='8px'
      textAlign='center'
      type='text'
      value={char}
      variant='primary'
      w='30px'
    />
  );
};

export default VerificationCodeInput;
