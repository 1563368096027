import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import {
  Input as ChakraInput,
  Button,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  useTheme,
  Progress,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import { FormPasswordInputProps } from '@/elements/FormPasswordInput';

import usePasswordStrength from '@/hooks/usePasswordStrength';

import FormLabel from '../FormLabel';

export type PasswordInputProps = FormPasswordInputProps & {
  error?: string;
  password: string;
};

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      isDisabled,
      label,
      containerProps,
      isStrengthBarNeeded,
      id,
      variant = 'primary',
      password,
      isInvalid,
      error,
      hasTooltip,
      tooltipText,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { strength, maxStrength } = usePasswordStrength({
      password,
    });

    const [show, setShow] = useState(false);

    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    const handleClick = useCallback(() => setShow(!show), [show]);

    const strongPwdTextCondition =
      isStrengthBarNeeded && strength === maxStrength && !error;

    const indicatorColorScheme = useMemo(() => {
      switch (strength) {
        case 1:
          return 'red';
        case 2:
          return 'orange';
        case 3:
          return 'yellow';
        case 4:
          return 'teal';
        case 5:
          return 'green';
        default:
          return 'gray';
      }
    }, [strength]);

    return (
      <FormControl
        id={inputId}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        {...containerProps}
      >
        <FormLabel
          hasTooltip={hasTooltip}
          id={inputId}
          label={label}
          tooltipText={tooltipText}
        />
        <InputGroup>
          <ChakraInput
            id={inputId}
            ref={ref}
            type={show ? 'text' : 'password'}
            variant={variant}
            {...rest}
          />
          <InputRightElement
            height='100%'
            justifyContent='flex-end'
            width='5.5rem'
          >
            <Button
              _active={{ background: '0' }}
              _focus={{ background: '0' }}
              _hover={{ background: '0' }}
              background='0'
              height='1.875rem'
              onClick={handleClick}
              paddingLeft='0'
              paddingRight='0'
              width='1.875rem'
            >
              {show ? (
                <AiFillEyeInvisible
                  color={theme.colors.app.lightPink}
                  size='1.4rem'
                />
              ) : (
                <AiFillEye color={theme.colors.app.lightPink} size='1.4rem' />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>

        {isStrengthBarNeeded && (
          <Box pt='sm'>
            <Progress
              colorScheme={indicatorColorScheme}
              max={maxStrength}
              min={0}
              value={strength}
            />
          </Box>
        )}

        {strongPwdTextCondition && (
          <Box color='app.green' fontSize='0.875rem' mt='xs'>
            {t('common.veryStrongPassword')}
          </Box>
        )}

        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
