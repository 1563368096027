import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

import VerificationCodeInput from './VerificationCodeInput';

interface OtpProps {
  codeLength: number;
  onChange: (code: string) => void;
  value: string;
}

const VerificationCodeInputs = ({ codeLength, onChange, value }: OtpProps) => {
  const emptyCode = Array(codeLength).fill('');
  const [code, setCode] = useState(emptyCode);

  const handleCode = (
    ev: React.ChangeEvent<HTMLInputElement>,
    value: string,
    index: number,
  ) => {
    const newCode = [...code];
    const remainingFields = codeLength - index;
    const newValue = value.length ? value.split('', remainingFields) : [''];
    const newValueSize = value.length ? value.length : 1;
    const target = ev.currentTarget as HTMLInputElement;

    newCode.splice(index, newValueSize, ...newValue);
    setCode(newCode);
    onChange(newCode.join(''));

    if (value.length && value.length < codeLength && index !== codeLength - 1) {
      ((target.nextElementSibling as HTMLInputElement) || null).focus();
    }
    if (value.length === codeLength) {
      target.blur();
    }
  };

  useEffect(() => {
    setCode(Array.from({ length: codeLength }, (v, k) => value?.[k] || ''));
  }, [codeLength, value]);

  const handleKey = (
    ev: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const target = ev.currentTarget as HTMLInputElement;
    if (ev.key === 'Backspace' && target.value === '' && index) {
      ((target.previousElementSibling as HTMLInputElement) || null).focus();
    }
    if (ev.key === 'ArrowLeft') {
      const prevElement =
        (target.previousElementSibling as HTMLInputElement) || null;
      if (prevElement) prevElement.focus();
    }
    if (ev.key === 'ArrowRight') {
      const nextElement =
        (target.nextElementSibling as HTMLInputElement) || null;
      if (nextElement) nextElement.focus();
    }
  };

  return (
    <Box display='flex' gap={2} justifyContent='center'>
      {code.map((char, index) => (
        <VerificationCodeInput
          char={char}
          handleCode={handleCode}
          handleKey={handleKey}
          index={index}
          key={index}
          maxLength={codeLength}
        />
      ))}
    </Box>
  );
};

export default VerificationCodeInputs;
