import React from 'react';

import { Flex } from '@chakra-ui/react';
import { components, OptionProps } from 'react-select';

import { ReactComponent as RadioEmpty } from '@/assets/icons/radio-empty.svg';
import { ReactComponent as RadioSelected } from '@/assets/icons/radio-selected.svg';

const InputOption = (props: OptionProps) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props}>
      <Flex alignItems='center' justifyContent='space-between'>
        {children}
        {isSelected ? <RadioSelected /> : <RadioEmpty />}
      </Flex>
    </components.Option>
  );
};

export default InputOption;
