import React, { FC } from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { MultiSelect, MultiSelectProps } from '@/elements';

export type FormMultiSelectProps = Omit<
  FormControlProps,
  'label' | 'defaultValue'
> &
  Omit<MultiSelectProps, 'onChange'>;

export const FormMultiSelect: FC<FormMultiSelectProps> = ({
  name,
  options,
  ...rest
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <MultiSelect
      containerProps={{ mb: 'lg' }}
      error={fieldState.error?.message}
      isInvalid={Boolean(fieldState.error)}
      options={options}
      {...rest}
      {...field}
    />
  );
};

export default FormMultiSelect;
