import React, { forwardRef, useMemo } from 'react';

import {
  IconButton as ChakraIconButton,
  IconButtonProps,
  useBreakpointValue,
} from '@chakra-ui/react';

const AppIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      children,
      size,
      variant = 'primary',
      'aria-label': ariaLabel,
      ...rest
    },
    ref,
  ) => {
    const buttonSize = useBreakpointValue({ base: 'sm', lg: 'md' });
    const iconSize = useMemo(() => {
      switch (size ?? buttonSize) {
        case 'xs':
        case 'sm':
          return '1.2rem';
        case 'md':
        case 'lg':
        default:
          return '1.5rem';
      }
    }, [buttonSize, size]);

    const _children = useMemo(() => {
      const element = icon || children;

      return React.isValidElement(element)
        ? React.cloneElement(element as React.ReactElement, {
            'aria-hidden': true,
            focusable: false,
            boxSize: iconSize,
          })
        : null;
    }, [children, icon, iconSize]);

    return (
      <ChakraIconButton
        aria-label={ariaLabel}
        display='inline-flex'
        justifyContent='center'
        ref={ref}
        size={size ?? buttonSize}
        variant={variant}
        {...rest}
      >
        {_children}
      </ChakraIconButton>
    );
  },
);

AppIconButton.displayName = 'AppIconButton';

export default AppIconButton;
