import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { saveAs } from 'file-saver';

import {
  AdminReport,
  IAcceptedCoin,
  IAdminMerchant,
  IAdminMerchantUser,
  IAdminTransfer,
  IAdminTxn,
  IAdminTxnDetails,
  IAdminUser,
  IApiKey,
  IAudit,
  IBusinessVerification,
  ICurrentMerchant,
  IExcludedPermissionDto,
  IFiat,
  IIncludedPermissionDto,
  IInviteAdminParams,
  IInvitedUser,
  IMerchant,
  IMerchantTxn,
  IMerchantUser,
  IMerchantUserRole,
  INetwork,
  PendingAction,
  IPersonalVerification,
  ISimpleMerchant,
  ITransfer,
  ITxnDetails,
  IUser,
  IUserRole,
  MerchantReport,
} from '@/types/domain';
import {
  ApplyInviteCodeParams,
  AuditParams,
  ChangeBaseCurrencyParams,
  ChangeEmailParams,
  ChangeMerchantUserRoleParams,
  ChangePasswordParams,
  ChangeUserRoleParams,
  CloseBannerParams,
  ConfirmUserParams,
  ContactUsParams,
  Create2FaParams,
  CreateAcceptedCoinParams,
  CreateApiKeyParams,
  CreateLinkedMerchant,
  CreateMerchantUserRolesParams,
  CreateUserRolesParams,
  Delete2FaParams,
  DeleteAcceptedCoinParams,
  DeleteAdminParams,
  DeleteApiKeyParams,
  DeleteMerchantPendingActionParams,
  DeleteMerchantUserParams,
  DeleteMerchantUserRolesParams,
  DeleteUserPendingActionParams,
  DeleteUserRolesParams,
  ExcludedPermissionsParams,
  ForgotPasswordParams,
  FreezeTransactionWalletParams,
  GetAcceptedCoinsParams,
  GetActionParams,
  GetAdminCoinsParams,
  GetAdminReportParams,
  GetAdminReportsParams,
  GetAdminTransactionDetailsParams,
  GetAdminTransactionsParams,
  GetAdminTransfersParams,
  GetAdminUsersParams,
  GetApiKeysParams,
  GetAvailableCoinsModalParams,
  GetBannersParams,
  GetCashBackTransactionsParams,
  GetEnabledCoinsParams,
  GetFiatListParams,
  GetLinkedMerchantsParams,
  GetMerchantActionParams,
  GetMerchantInfoParams,
  GetMerchantReportParams,
  GetMerchantReportsParams,
  GetMerchantsListParams,
  GetMerchantsParams,
  GetMerchantUserRolesParams,
  GetMerchantUsersParams,
  GetMerchantVerificationParams,
  GetNetworksParams,
  GetNotificationKeyParams,
  GetTransactionDetailsParams,
  GetTransactionsParamsWithSort,
  GetTransfersParams,
  GetUserParams,
  GetUserRolesParams,
  IGetInvitedAdminUsers,
  IGetInvitedUsers,
  IGetUsersData,
  IncludePermissionParams,
  InviteAdminParams,
  InviteUserParams,
  LoginParams,
  LogoutParams,
  RefreshTokenParams,
  RegisterParams,
  ResendForConfirmEmailParams,
  ResetPasswordParams,
  ResetTransactionPaymentWindowParams,
  ResetTransactionSettlementAttemptsParams,
  SendTestNotificationParams,
  ToggleAdminParams,
  ToggleMerchantUserParams,
  TogglePermissionParams,
  UnfreezeTransactionWalletParams,
  UnlinkMerchant,
  UpdateAcceptedCoinParams,
  UpdateApiKeyParams,
  UpdateMerchantFromAdminParams,
  UpdateMerchantInfoParams,
  UpdateMerchantUserRolesParams,
  UpdateMerchantVerificationParams,
  UpdateNetworkParams,
  UpdateUserParams,
  UpdateUserRolesParams,
  Verify2FaParams,
  VerifyKycParams,
} from '@/types/query-params';

import { ApiTagsEnum } from '@/enum/apiTags.enum';
import { MfaAuthTypeEnum } from '@/enum/mfaAuthTypeEnum.enum';

import { IBanner } from '@/context/banner.context';

import { extractAttachmentNameFromHeader, prepareHeaders } from '@/utils/api';
import { API_BASE_URL } from '@/utils/constants';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUser: builder.query<IUser, GetUserParams>({
      query: () => 'user',
      providesTags: (result) =>
        result ? [{ type: ApiTagsEnum.User, id: result.id }] : [],
    }),
    getUserPendingActions: builder.query<PendingAction[], GetActionParams>({
      query: () => 'user/pending-actions/changeEmail',
      providesTags: [ApiTagsEnum.UserPendingAction],
    }),

    updateUser: builder.mutation<IUser, UpdateUserParams>({
      query: (data) => ({
        url: 'user',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.UserPendingAction],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUser', undefined, (draft) => {
              Object.assign(draft, res.data);
            }),
          );
        } catch {}
      },
    }),
    confirmUser: builder.mutation<
      { alreadyConfirmed?: boolean },
      ConfirmUserParams
    >({
      query: (params) => ({
        url: 'user/confirm',
        method: 'POST',
        body: params,
      }),
    }),
    confirmNewEmail: builder.mutation<void, ChangeEmailParams>({
      query: (data) => ({
        url: 'user/confirm-action',
        method: 'POST',
        body: data,
      }),
    }),
    confirmNewMerchantEmail: builder.mutation<void, ChangeEmailParams>({
      query: (data) => ({
        url: 'merchant/confirm-action',
        method: 'POST',
        body: data,
      }),
    }),
    register: builder.mutation<{ accessToken?: string }, RegisterParams>({
      query: (data) => ({
        url: 'user/register',
        method: 'POST',
        body: data,
      }),
    }),
    login: builder.mutation<
      | { accessToken: string }
      | { mfaRequired: boolean; mfaAuthType: MfaAuthTypeEnum },
      LoginParams
    >({
      query: (data) => ({
        url: 'user/login',
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation<void, LogoutParams>({
      query: () => ({
        url: 'user/logout',
        method: 'POST',
      }),
    }),
    refreshToken: builder.mutation<{ accessToken: string }, RefreshTokenParams>(
      {
        query: () => ({
          url: 'user/refresh-token',
          method: 'POST',
        }),
      },
    ),
    changePassword: builder.mutation<void, ChangePasswordParams>({
      query: (data) => ({
        url: 'user/change-pass',
        method: 'POST',
        body: data,
      }),
    }),
    contactUs: builder.mutation<void, ContactUsParams>({
      query: (data) => ({
        url: 'contacts',
        method: 'POST',
        body: data,
      }),
    }),
    deleteUserPendingAction: builder.mutation<
      void,
      DeleteUserPendingActionParams
    >({
      query: (data) => ({
        url: `user/pending-action/${data.actionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagsEnum.UserPendingAction],
    }),
    verifyKYC: builder.mutation<void, VerifyKycParams>({
      query: (data) => ({
        url: 'contacts/kyc',
        method: 'POST',
        body: data,
      }),
    }),
    create2FA: builder.mutation<{ src?: string }, Create2FaParams>({
      query: (data) => ({
        url: 'user/tfa/initiate',
        method: 'POST',
        body: data,
      }),
    }),
    verify2FA: builder.mutation<void, Verify2FaParams>({
      query: (data) => ({
        url: 'user/tfa/verify',
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUser', undefined, (draft) => {
              Object.assign(draft, { mfaAuthType: arg.type });
            }),
          );
        } catch {}
      },
    }),
    delete2FA: builder.mutation<void, Delete2FaParams>({
      query: (data) => ({
        url: 'user/tfa',
        method: 'DELETE',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          if (!arg?.code) {
            return;
          }
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUser', undefined, (draft) => {
              Object.assign(draft, {
                mfaAuthType: MfaAuthTypeEnum.NotActivate,
              });
            }),
          );
        } catch {}
      },
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordParams>({
      query: (data) => ({
        url: 'user/forgot-pass',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordParams>({
      query: (data) => ({
        url: 'user/reset-pass',
        method: 'POST',
        body: data,
      }),
    }),
    sendFeedback: builder.mutation({
      query: (data) => ({
        url: 'user/reset-pass', // TODO: double check
        method: 'POST',
        body: data,
      }),
    }),
    resendForConfirmEmail: builder.mutation<void, ResendForConfirmEmailParams>({
      query: (data) => ({
        url: 'user/resend-confirm-email',
        method: 'POST',
        body: data,
      }),
    }),
    applyInviteCode: builder.mutation<void, ApplyInviteCodeParams>({
      query: (data) => ({
        url: 'user/apply-invite-code',
        method: 'POST',
        body: data,
      }),
    }),
    getTransfers: builder.query<
      { results: ITransfer[]; total: number },
      GetTransfersParams
    >({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/transfers`,
        params,
      }),
      providesTags: [ApiTagsEnum.MerchantTransfer],
    }),
    deleteMerchantPendingAction: builder.mutation<
      void,
      DeleteMerchantPendingActionParams
    >({
      query: (data) => ({
        url: `merchant/${data.merchantId}/pending-action/${data.actionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagsEnum.MerchantPendingAction],
    }),
    getMerchantPendingActions: builder.query<
      PendingAction[],
      GetMerchantActionParams
    >({
      query: ({ merchantId }) => `merchant/${merchantId}/pending-actions`,
      providesTags: [ApiTagsEnum.MerchantPendingAction],
    }),
    downloadTransfers: builder.query<void, GetTransfersParams>({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/transfers/download`,
        params,
        responseHandler: async (response) =>
          saveAs(
            await response.blob(),
            extractAttachmentNameFromHeader(
              response.headers.get('content-disposition') || '',
            ),
          ),
        cache: 'no-cache',
      }),
    }),
    getBanners: builder.query<IBanner[], GetBannersParams>({
      query: ({ merchantId }) => {
        return `merchant/${merchantId}/banner`;
      },
      providesTags: [ApiTagsEnum.MerchantBanner],
    }),
    getTransactions: builder.query<
      { results: IMerchantTxn[]; total: number },
      GetTransactionsParamsWithSort
    >({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/txns`,
        params,
      }),
      providesTags: [ApiTagsEnum.MerchantTransaction],
    }),
    downloadTransactions: builder.query<void, GetTransactionsParamsWithSort>({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/txns/download`,
        params,
        responseHandler: async (response) =>
          saveAs(
            await response.blob(),
            extractAttachmentNameFromHeader(
              response.headers.get('content-disposition') || '',
            ),
          ),
        cache: 'no-cache',
      }),
    }),
    getApiKeys: builder.query<IApiKey[], GetApiKeysParams>({
      query: (merchantId) => `merchant/${merchantId}/api-keys`,
      providesTags: [ApiTagsEnum.ApiKey],
    }),
    createApiKey: builder.mutation<IApiKey, CreateApiKeyParams>({
      query: ({ merchantId, ...body }) => ({
        url: `merchant/${merchantId}/api-keys`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getApiKeys', args.merchantId, (draft) => {
              draft.push(res.data);
            }),
          );
        } catch {}
      },
    }),
    updateApiKey: builder.mutation<IApiKey, UpdateApiKeyParams>({
      query: ({ merchantId, id, ...body }) => ({
        url: `merchant/${merchantId}/api-keys/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getApiKeys', arg.merchantId, (draft) => {
              draft.some((x) => {
                if (x.id === res.data.id) {
                  Object.assign(x, res.data);
                }
              });
            }),
          );
        } catch {}
      },
    }),
    deleteApiKey: builder.mutation<void, DeleteApiKeyParams>({
      query: (data) => ({
        url: `merchant/${data.merchantId}/api-keys/${data.keyId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getApiKeys', arg.merchantId, (draft) =>
              draft.filter((x) => x.id !== arg.keyId),
            ),
          );
        } catch {}
      },
    }),
    getNotificationKey: builder.query<
      { notificationSignatureKey: string },
      GetNotificationKeyParams
    >({
      query: (merchantId) => `merchant/${merchantId}/notificationSignatureKey`,
      providesTags: [ApiTagsEnum.NotificationKey],
    }),
    generateNotificationKey: builder.mutation<
      { notificationSignatureKey: string },
      GetNotificationKeyParams
    >({
      query: (merchantId) => ({
        url: `merchant/${merchantId}/notificationSignatureKey/generate`,
        method: 'POST',
      }),
      async onQueryStarted(merchantId, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getNotificationKey',
              merchantId,
              () => res.data,
            ),
          );
        } catch {}
      },
    }),
    getMerchantInfo: builder.query<ICurrentMerchant, GetMerchantInfoParams>({
      query: (merchantId) => `merchant/${merchantId}/info`,
      providesTags: (result) =>
        result ? [{ type: ApiTagsEnum.Merchant, id: result.id }] : [],
    }),
    getAvailableCoins: builder.query<
      { results: IAcceptedCoin[]; total: number },
      GetAvailableCoinsModalParams
    >({
      query: ({
        merchantId,
        page,
        pageSize,
        isPopular,
        isStableCoin,
        isTrending,
        search,
      }) => ({
        url: `merchant/${merchantId}/coins/available`,
        params: {
          page,
          pageSize,
          isPopular,
          isStableCoin,
          isTrending,
          search,
        },
      }),
      providesTags: [ApiTagsEnum.AvailableCoin],
    }),
    getMerchantsList: builder.query<ISimpleMerchant[], GetMerchantsListParams>({
      query: () => 'merchant/list',
      providesTags: (result) =>
        (result || []).map((x) => ({
          type: ApiTagsEnum.SimpleMerchant,
          id: x.id,
        })),
    }),
    getMerchantVerification: builder.query<
      IBusinessVerification | IPersonalVerification,
      GetMerchantVerificationParams
    >({
      query: (merchantId) => `merchant/${merchantId}/detail`,
      providesTags: [ApiTagsEnum.MerchantDetail],
    }),
    getMerchantReports: builder.query<any[], GetMerchantReportsParams>({
      query: ({ merchantId }) => `/merchant/${merchantId}/report/list`,
      providesTags: [ApiTagsEnum.MerchantReportList],
    }),
    getMerchantReport: builder.query<MerchantReport[], GetMerchantReportParams>(
      {
        query: ({ merchantId, reportName, startDate, endDate }) => ({
          url: `/merchant/${merchantId}/report/${reportName}/execute`,
          params: { startDate, endDate },
        }),
        providesTags: (_results, _error, { reportName }) => [
          { type: ApiTagsEnum.MerchantReport, id: reportName },
        ],
      },
    ),
    downloadMerchantReport: builder.query<void, GetMerchantReportParams>({
      query: ({ merchantId, reportName, startDate, endDate }) => ({
        url: `/merchant/${merchantId}/report/${reportName}/download`,
        params: { startDate, endDate },
        responseHandler: async (response) =>
          saveAs(
            await response.blob(),
            extractAttachmentNameFromHeader(
              response.headers.get('content-disposition') || '',
            ),
          ),
        cache: 'no-cache',
      }),
    }),
    updateMerchantInfo: builder.mutation<IMerchant, UpdateMerchantInfoParams>({
      query: ({ merchantId, data }) => ({
        url: `merchant/${merchantId}/update`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.MerchantPendingAction],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantInfo',
              arg.merchantId,
              (draft) => {
                Object.assign(draft, res.data);
              },
            ),
          );
        } catch {}
      },
    }),
    uploadImages: builder.mutation({
      query: (data: FormData) => ({
        url: 'upload',
        method: 'POST',
        body: data,
      }),
    }),
    updateMerchantVerification: builder.mutation<
      IBusinessVerification | IPersonalVerification,
      UpdateMerchantVerificationParams
    >({
      query: ({ merchantId, data }) => ({
        url: `merchant/${merchantId}/detail`,
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantVerification',
              arg.merchantId,
              (draft) => {
                Object.assign(draft, res.data);
              },
            ),
          );
        } catch {}
      },
    }),
    inviteUser: builder.mutation<void, InviteUserParams>({
      query: ({ merchantId, ...body }) => ({
        url: `merchant/${merchantId}/user/invite`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.MerchantInvitedUser],
    }),
    getMerchantUsers: builder.query<
      { results: IMerchantUser[]; total: number },
      GetMerchantUsersParams
    >({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/user/list`,
        params,
      }),
      providesTags: (result) =>
        (result?.results || []).map((x) => ({
          type: ApiTagsEnum.MerchantUser,
          id: `${x.merchantId}-${x.userId}`,
        })),
    }),
    toggleMerchantUser: builder.mutation<void, ToggleMerchantUserParams>({
      query: (data) => ({
        url: `merchant/${data.merchantId}/user/${data.userId}/toggle`,
        method: 'PUT',
      }),
      invalidatesTags: [ApiTagsEnum.MerchantUser],
    }),
    deleteMerchantUser: builder.mutation<void, DeleteMerchantUserParams>({
      query: (data) => ({
        url: `merchant/${data.merchantId}/user/${data.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagsEnum.MerchantUser],
    }),
    changeMerchantUserRole: builder.mutation<
      void,
      ChangeMerchantUserRoleParams
    >({
      query: ({ merchantId, userId, ...body }) => ({
        url: `merchant/${merchantId}/user/${userId}/change-roles`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.Merchant, ApiTagsEnum.MerchantUser],
    }),
    changeUserRole: builder.mutation<void, ChangeUserRoleParams>({
      query: ({ userId, ...body }) => ({
        url: `admin/user/${userId}/change-roles`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.User],
    }),
    getMerchants: builder.query<
      { results: IAdminMerchant[]; total: number },
      GetMerchantsParams
    >({
      query: (params) => ({
        url: 'admin/merchant/list',
        params,
      }),
      providesTags: [ApiTagsEnum.AdminMerchants],
    }),
    getAdminTransfers: builder.query<
      { results: IAdminTransfer[]; total: number },
      GetAdminTransfersParams
    >({
      query: (data) => ({
        url: 'admin/transfers',
        params: data,
      }),
      providesTags: [ApiTagsEnum.AdminTransfer],
    }),

    getAdminAvailableCoins: builder.query<
      { results: IAcceptedCoin[]; total: number },
      GetAdminCoinsParams
    >({
      query: (data) => ({
        url: 'admin/coins/available',
        params: data,
      }),
      providesTags: [ApiTagsEnum.AdminAvailableCoins],
    }),

    getAdminTransactions: builder.query<
      { results: IAdminTxn[]; total: number },
      GetAdminTransactionsParams
    >({
      query: (data) => ({
        url: 'admin/txns',
        params: data,
      }),
      providesTags: [ApiTagsEnum.AdminTransaction],
    }),
    getAdminTransactionDetails: builder.query<
      IAdminTxnDetails,
      GetAdminTransactionDetailsParams
    >({
      query: ({ txnId }) => ({
        url: `admin/txns/${txnId}`,
      }),
      providesTags: (res) => [
        { type: ApiTagsEnum.AdminTransactionDetails, id: res?.id },
      ],
    }),
    getTransactionDetails: builder.query<
      ITxnDetails,
      GetTransactionDetailsParams
    >({
      query: ({ txnId, merchantId }) => ({
        url: `merchant/${merchantId}/txns/${txnId}`,
      }),
      providesTags: (res) => [
        { type: ApiTagsEnum.TransactionDetails, id: res?.id },
      ],
    }),
    resetTransactionPaymentWindow: builder.mutation<
      void,
      ResetTransactionPaymentWindowParams
    >({
      query: ({ txnId }) => ({
        url: `admin/txns/${txnId}/reset-payment-window`,
        method: 'post',
      }),
      invalidatesTags: (res, err, args) => [
        { type: ApiTagsEnum.AdminTransactionDetails, id: args.txnId },
      ],
    }),
    resetTransactionSettlementAttempts: builder.mutation<
      void,
      ResetTransactionSettlementAttemptsParams
    >({
      query: ({ txnId }) => ({
        url: `admin/txns/${txnId}/reset-settlement-attempts`,
        method: 'post',
      }),
      invalidatesTags: (res, err, args) => [
        { type: ApiTagsEnum.AdminTransactionDetails, id: args.txnId },
      ],
    }),
    freezeTransactionWallet: builder.mutation<
      void,
      FreezeTransactionWalletParams
    >({
      query: ({ txnId }) => ({
        url: `admin/txns/${txnId}/freeze-wallet`,
        method: 'post',
      }),
      invalidatesTags: (res, err, args) => [
        { type: ApiTagsEnum.AdminTransactionDetails, id: args.txnId },
      ],
    }),
    unfreezeTransactionWallet: builder.mutation<
      void,
      UnfreezeTransactionWalletParams
    >({
      query: ({ txnId }) => ({
        url: `admin/txns/${txnId}/unfreeze-wallet`,
        method: 'post',
      }),
      invalidatesTags: (res, err, args) => [
        { type: ApiTagsEnum.AdminTransactionDetails, id: args.txnId },
      ],
    }),
    getCashbackTransactions: builder.query<
      { results: IAdminTxn[]; total: number },
      GetCashBackTransactionsParams
    >({
      query: ({ page, pageSize, merchantId }) =>
        `merchant/${merchantId}/txns?page=${page}&pageSize=${pageSize}&transactionType=cashback`,
      providesTags: [ApiTagsEnum.MerchantCashbackTransaction],
    }),
    getDownloadAdminTransactions: builder.query<
      void,
      GetAdminTransactionsParams
    >({
      query: ({ page, pageSize }) => {
        return {
          url: `admin/transfers/download?page=${page}&pageSize=${pageSize}`,
          responseHandler: async (response) =>
            saveAs(
              await response.blob(),
              extractAttachmentNameFromHeader(
                response.headers.get('content-disposition') || '',
              ),
            ),
          cache: 'no-cache',
        };
      },
    }),
    getDownloadAdminTxns: builder.query<void, GetAdminTransactionsParams>({
      query: ({ page, pageSize }) => {
        return {
          url: `admin/txns/download?page=${page}&pageSize=${pageSize}`,
          responseHandler: async (response) =>
            saveAs(
              await response.blob(),
              extractAttachmentNameFromHeader(
                response.headers.get('content-disposition') || '',
              ),
            ),
          cache: 'no-cache',
        };
      },
    }),
    getAdminUsers: builder.query<
      { results: IAdminUser[]; total: number },
      GetAdminUsersParams
    >({
      query: (params) => ({
        url: 'admin/user/list',
        params,
      }),
      providesTags: [ApiTagsEnum.AdminUser],
    }),
    inviteAdmin: builder.mutation<void, InviteAdminParams>({
      query: (data: IInviteAdminParams) => ({
        url: 'admin/invite',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.AdminInvitedUser],
    }),
    toggleAdmin: builder.mutation<void, ToggleAdminParams>({
      query: (data) => ({
        url: `admin/user/toggle/${data.userId}`,
        method: 'PUT',
      }),
      invalidatesTags: (res, err, arg) => [
        {
          type: ApiTagsEnum.AdminUser,
          id: arg.userId,
        },
      ],
    }),
    deleteAdmin: builder.mutation<void, DeleteAdminParams>({
      query: (data) => ({
        url: `admin/user/${data.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, arg) => [
        {
          type: ApiTagsEnum.AdminUser,
          id: arg.userId,
        },
      ],
    }),
    getAdminReports: builder.query<any[], GetAdminReportsParams>({
      query: () => 'admin/reports',
      providesTags: [ApiTagsEnum.AdminReportList],
    }),
    getAdminReport: builder.query<AdminReport[], GetAdminReportParams>({
      query: ({ reportName, ...params }) => ({
        url: `/admin/report/${reportName}/execute`,
        params,
      }),
      providesTags: (_results, _error, { reportName }) => [
        { type: ApiTagsEnum.AdminReport, id: reportName },
      ],
    }),
    downloadAdminReport: builder.query<void, GetAdminReportParams>({
      query: ({ reportName, ...params }) => ({
        url: `/admin/report/${reportName}/download`,
        params,
        responseHandler: async (response) =>
          saveAs(
            await response.blob(),
            extractAttachmentNameFromHeader(
              response.headers.get('content-disposition') || '',
            ),
          ),
        cache: 'no-cache',
      }),
    }),
    downloadAdminUsers: builder.query({
      query() {
        return {
          url: 'admin/user/list/download',
          responseHandler: async (response) =>
            saveAs(
              await response.blob(),
              extractAttachmentNameFromHeader(
                response.headers.get('content-disposition') || '',
              ),
            ),
          cache: 'no-cache',
        };
      },
    }),
    downloadMerchants: builder.query({
      query() {
        return {
          url: 'admin/merchants/download',
          responseHandler: async (response) =>
            saveAs(
              await response.blob(),
              extractAttachmentNameFromHeader(
                response.headers.get('content-disposition') || '',
              ),
            ),
          cache: 'no-cache',
        };
      },
    }),
    initiateWallets: builder.mutation<void, void>({
      query: () => ({
        url: 'admin/initiate-wallets',
        method: 'POST',
      }),
    }),
    updateMerchantFromAdmin: builder.mutation<
      IAdminMerchant,
      UpdateMerchantFromAdminParams
    >({
      query: ({ merchantId, ...body }) => ({
        url: `admin/merchant/${merchantId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        ApiTagsEnum.AdminMerchants,
        ApiTagsEnum.IncludedPermissions,
      ],
    }),
    getMerchantUsersPage: builder.query<
      { results: IAdminMerchantUser[]; total: number },
      IGetUsersData
    >({
      query: (data) => ({
        url: '/admin/user/list/all',
        params: data,
      }),
      providesTags: [ApiTagsEnum.AdminMerchantUser],
    }),
    getInvitedUsers: builder.query<
      { results: IInvitedUser[]; total: number },
      IGetInvitedUsers
    >({
      query: ({ merchantId, ...data }) => ({
        url: `merchant/${merchantId}/user/invited-users`,
        params: data,
      }),
      providesTags: [ApiTagsEnum.MerchantInvitedUser],
    }),
    getInvitedAdminUsers: builder.query<
      { results: IInvitedUser[]; total: number },
      IGetInvitedAdminUsers
    >({
      query: (data) => ({
        url: 'admin/invited-users',
        params: data,
      }),
      providesTags: [ApiTagsEnum.AdminInvitedUser],
    }),
    downloadUsers: builder.query({
      query() {
        return {
          url: 'admin/user/list/all/download?page=0&pageSize=999999999999',
          responseHandler: async (response) =>
            saveAs(
              await response.blob(),
              extractAttachmentNameFromHeader(
                response.headers.get('content-disposition') || '',
              ),
            ),
          cache: 'no-cache',
        };
      },
    }),
    getFiatList: builder.query<
      { baseFiatId: string; fiat: IFiat[] },
      GetFiatListParams
    >({
      query: (merchantId) => `merchant/${merchantId}/fiats`,
      providesTags: [ApiTagsEnum.MerchantFiat],
    }),
    changeBaseCurrency: builder.mutation<
      { results: IFiat[]; total: number },
      ChangeBaseCurrencyParams
    >({
      query: ({ merchantId, baseFiatId }) => ({
        url: `merchant/${merchantId}/baseFiat`,
        method: 'POST',
        body: { baseFiatId },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantInfo',
              arg.merchantId,
              (draft) => {
                Object.assign(draft, res.data);
              },
            ),
          );
        } catch {}
      },
    }),
    getAcceptedCoins: builder.query<
      { results: IAcceptedCoin[]; total: number },
      GetAcceptedCoinsParams
    >({
      query: ({
        codes,
        merchantId,
        page,
        pageSize,
        canSettleToCrypto,
        search,
      }) => {
        return {
          url: `merchant/${merchantId}/coins`,
          params: { codes, page, pageSize, canSettleToCrypto, search },
        };
      },
      providesTags: (result) =>
        result
          ? result.results.map((x) => ({
              type: ApiTagsEnum.AcceptedCoin,
              id: x.id,
            }))
          : [],
    }),
    getEnabledCoins: builder.query<
      { results: IAcceptedCoin[] },
      GetEnabledCoinsParams
    >({
      query: ({ merchantId }) => {
        return {
          url: `merchant/${merchantId}/coins`,
        };
      },
    }),
    createAcceptedCoin: builder.mutation<
      IAcceptedCoin,
      CreateAcceptedCoinParams
    >({
      query: ({ merchantId, data }) => ({
        url: `merchant/${merchantId}/coins`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.AcceptedCoin, ApiTagsEnum.AvailableCoin],
    }),
    updateAcceptedCoin: builder.mutation<
      IAcceptedCoin,
      UpdateAcceptedCoinParams
    >({
      query: ({ merchantId, data }) => ({
        url: `merchant/${merchantId}/coins/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.AcceptedCoin],
    }),
    deleteAcceptedCoin: builder.mutation<void, DeleteAcceptedCoinParams>({
      query: ({ merchantId, id }) => ({
        url: `merchant/${merchantId}/coins/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagsEnum.AcceptedCoin, ApiTagsEnum.AvailableCoin],
    }),
    closeBanner: builder.mutation<void, CloseBannerParams>({
      query: ({ merchantId, bannerid }) => ({
        url: `merchant/${merchantId}/banner/${bannerid}`,
        method: 'POST',
      }),
    }),
    getUserRoles: builder.query<IUserRole[], GetUserRolesParams>({
      query: () => ({
        url: 'admin/role/list',
      }),
      providesTags: [ApiTagsEnum.UserRole],
    }),
    getNetworks: builder.query<
      { results: INetwork[]; total: number },
      GetNetworksParams
    >({
      query: (params) => ({
        url: 'admin/network/list',
        params,
      }),
      providesTags: [ApiTagsEnum.AdminNetwork],
    }),
    getMerchantUserRoles: builder.query<
      IMerchantUserRole[],
      GetMerchantUserRolesParams
    >({
      query: ({ merchantId }) => ({
        url: `merchant/${merchantId}/role/list`,
        providesTags: [ApiTagsEnum.MerchantUserRole],
      }),
    }),
    getLinkedMerchants: builder.query<
      { results: IMerchantUser[]; total: number },
      GetLinkedMerchantsParams
    >({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/linked/list`,
        params,
      }),
      providesTags: [ApiTagsEnum.LinkedMerchants],
    }),
    updateNetwork: builder.mutation<void, UpdateNetworkParams>({
      query: (data) => ({
        url: 'admin/network/update',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ApiTagsEnum.AdminNetwork],
    }),
    updateMerchantUserRole: builder.mutation<
      IMerchantUserRole,
      UpdateMerchantUserRolesParams
    >({
      query: ({ merchantId, ...body }) => ({
        url: `merchant/${merchantId}/role`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.Merchant, ApiTagsEnum.MerchantUser],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantUserRoles',
              { merchantId: arg.merchantId },
              (draft) =>
                draft.map((x) => (x.id === res.data.id ? res.data : x)),
            ),
          );
        } catch {}
      },
    }),
    createMerchantUserRole: builder.mutation<
      IMerchantUserRole,
      CreateMerchantUserRolesParams
    >({
      query: ({ merchantId, ...body }) => ({
        url: `merchant/${merchantId}/role`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantUserRoles',
              { merchantId: arg.merchantId },
              (draft) => [res.data, ...draft],
            ),
          );
        } catch {}
      },
    }),
    createLinkedMerchant: builder.mutation<IMerchant, CreateLinkedMerchant>({
      query: ({ merchantId, data }) => ({
        url: `merchant/${merchantId}/linked`,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        ApiTagsEnum.LinkedMerchants,
        ApiTagsEnum.SimpleMerchant,
      ],
    }),
    unlinkMerchant: builder.mutation<void, UnlinkMerchant>({
      query: ({ merchantId, linkedMerchantId }) => ({
        url: `merchant/${merchantId}/linked/${linkedMerchantId}/unlink`,
        method: 'PUT',
      }),
      invalidatesTags: [
        ApiTagsEnum.LinkedMerchants,
        ApiTagsEnum.SimpleMerchant,
      ],
    }),
    deleteMerchantUserRole: builder.mutation<
      void,
      DeleteMerchantUserRolesParams
    >({
      query: ({ merchantId, roleId }) => ({
        url: `merchant/${merchantId}/role/${roleId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getMerchantUserRoles',
              { merchantId: arg.merchantId },
              (draft) => draft?.filter((x) => x.id !== arg.roleId),
            ),
          );
        } catch {}
      },
    }),
    updateUserRole: builder.mutation<IUserRole, UpdateUserRolesParams>({
      query: (body) => ({
        url: 'admin/role',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.User],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUserRoles', undefined, (draft) =>
              draft.map((x) => (x.id === res.data.id ? res.data : x)),
            ),
          );
        } catch {}
      },
    }),
    createUserRole: builder.mutation<IUserRole, CreateUserRolesParams>({
      query: (body) => ({
        url: 'admin/role',
        method: 'POST',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUserRoles', undefined, (draft) => [
              res.data,
              ...draft,
            ]),
          );
        } catch {}
      },
    }),
    deleteUserRole: builder.mutation<void, DeleteUserRolesParams>({
      query: ({ roleId }) => ({
        url: `admin/role/${roleId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getUserRoles', undefined, (draft) =>
              draft?.filter((x) => x.id !== arg.roleId),
            ),
          );
        } catch {}
      },
    }),
    excludedPermissions: builder.query<
      IExcludedPermissionDto[],
      ExcludedPermissionsParams
    >({
      query: () => ({
        url: 'admin/permission/excluded',
      }),
      providesTags: [ApiTagsEnum.ExcludedPermissions],
    }),
    includedPermissions: builder.query<
      IIncludedPermissionDto[],
      ExcludedPermissionsParams
    >({
      query: () => ({
        url: 'admin/permission/included',
      }),
      providesTags: [ApiTagsEnum.IncludedPermissions],
    }),
    togglePermission: builder.mutation<void, TogglePermissionParams>({
      query: (body) => ({
        url: 'admin/permission/toggle',
        method: 'PUT',
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'excludedPermissions',
              undefined,
              (draft) =>
                arg.isEnabled
                  ? draft?.filter((x) => x.permission !== arg.permission)
                  : [
                      ...(draft ?? []),
                      { permission: arg.permission, isEditable: true },
                    ],
            ),
          );
        } catch {}
      },
    }),
    includePermission: builder.mutation<void, IncludePermissionParams>({
      query: (body) => ({
        url: 'admin/permission/include',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.IncludedPermissions],
    }),
    audit: builder.query<{ results: IAudit[]; total: number }, AuditParams>({
      query: ({ merchantId, ...params }) => ({
        url: `merchant/${merchantId}/audit`,
        params,
      }),
      providesTags: [ApiTagsEnum.Audit],
    }),
    sendTestNotification: builder.mutation<void, SendTestNotificationParams>({
      query: ({ merchantId, ...body }) => ({
        url: `merchant/${merchantId}/audit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.Audit],
    }),
  }),
});

export const {
  util,

  useGetBannersQuery,
  useGetUserQuery,
  useGetUserPendingActionsQuery,
  useGetMerchantPendingActionsQuery,
  useRegisterMutation,
  useLoginMutation,
  useContactUsMutation,
  useChangePasswordMutation,
  useDeleteUserPendingActionMutation,
  useDeleteMerchantPendingActionMutation,
  useUpdateUserMutation,
  useCreate2FAMutation,
  useVerify2FAMutation,
  useDelete2FAMutation,
  useVerifyKYCMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendFeedbackMutation,
  useConfirmUserMutation,
  useConfirmNewEmailMutation,
  useConfirmNewMerchantEmailMutation,
  useResendForConfirmEmailMutation,
  useApplyInviteCodeMutation,
  useLogoutMutation,
  useRefreshTokenMutation,

  useGetTransfersQuery,
  useLazyDownloadTransfersQuery,

  useGetTransactionsQuery,
  useLazyDownloadTransactionsQuery,

  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useUpdateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetNotificationKeyQuery,
  useGenerateNotificationKeyMutation,

  useLazyDownloadUsersQuery,
  useGetMerchantUsersPageQuery,
  useGetInvitedUsersQuery,
  useGetInvitedAdminUsersQuery,
  useGetAvailableCoinsQuery,
  useGetMerchantInfoQuery,
  useUpdateMerchantInfoMutation,
  useUploadImagesMutation,
  useGetMerchantVerificationQuery,
  useGetLinkedMerchantsQuery,
  useGetMerchantsListQuery,
  useUpdateMerchantVerificationMutation,
  useCreateLinkedMerchantMutation,
  useUnlinkMerchantMutation,
  useInviteUserMutation,
  useGetMerchantUsersQuery,
  useToggleMerchantUserMutation,
  useDeleteMerchantUserMutation,
  useChangeMerchantUserRoleMutation,
  useChangeUserRoleMutation,
  useGetMerchantReportQuery,
  useLazyDownloadMerchantReportQuery,

  useGetMerchantsQuery,
  useGetAdminTransactionsQuery,
  useGetAdminTransactionDetailsQuery,
  useGetAdminAvailableCoinsQuery,
  useGetTransactionDetailsQuery,
  useResetTransactionPaymentWindowMutation,
  useResetTransactionSettlementAttemptsMutation,
  useFreezeTransactionWalletMutation,
  useUnfreezeTransactionWalletMutation,
  useGetCashbackTransactionsQuery,
  useLazyGetDownloadAdminTransactionsQuery,
  useLazyGetDownloadAdminTxnsQuery,
  useInviteAdminMutation,
  useGetAdminUsersQuery,
  useToggleAdminMutation,
  useDeleteAdminMutation,
  useGetAdminTransfersQuery,
  useGetAdminReportsQuery,
  useGetAdminReportQuery,
  useLazyDownloadAdminReportQuery,
  useLazyDownloadMerchantsQuery,
  useLazyDownloadAdminUsersQuery,
  useInitiateWalletsMutation,
  useUpdateMerchantFromAdminMutation,

  useGetFiatListQuery,
  useChangeBaseCurrencyMutation,

  useGetAcceptedCoinsQuery,
  useGetEnabledCoinsQuery,
  useUpdateAcceptedCoinMutation,
  useCreateAcceptedCoinMutation,
  useDeleteAcceptedCoinMutation,

  useCloseBannerMutation,

  useGetUserRolesQuery,
  useGetMerchantUserRolesQuery,
  useCreateMerchantUserRoleMutation,
  useUpdateMerchantUserRoleMutation,
  useDeleteMerchantUserRoleMutation,
  useCreateUserRoleMutation,
  useUpdateUserRoleMutation,
  useDeleteUserRoleMutation,

  useGetNetworksQuery,
  useUpdateNetworkMutation,

  useExcludedPermissionsQuery,
  useIncludedPermissionsQuery,
  useTogglePermissionMutation,
  useIncludePermissionMutation,

  useSendTestNotificationMutation,
  useAuditQuery,
} = api;
