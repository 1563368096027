import { useEffect, useState } from 'react';

import useTabVisibility from '@/hooks/useTabVisibility';

export interface ICountdownReturn {
  timeLeftSecs: number;
}

export interface ICountdownArgs {
  key: string;
  stopAt: string | number;
  start?: boolean;
}

const getDuration = (timestamp: string | number) =>
  Math.max(Math.round((new Date(timestamp).getTime() - Date.now()) / 1000), 0);

export const useCountdown = ({
  key,
  stopAt,
  start = false,
}: ICountdownArgs): ICountdownReturn => {
  const [timeLeft, setTimeLeft] = useState(getDuration(stopAt));
  const isHidden = useTabVisibility();

  useEffect(() => {
    if (start === false) {
      return;
    }
    const interval = setInterval(
      () => setTimeLeft((v) => Math.max(v - 1, 0)),
      1000,
    );
    return () => clearInterval(interval);
  }, [start]);

  useEffect(() => {
    setTimeLeft(getDuration(stopAt));
  }, [stopAt, key, isHidden]);

  return {
    timeLeftSecs: timeLeft,
  };
};
