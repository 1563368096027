import { useCallback, useMemo } from 'react';

import { MerchantPermissions } from '@/enum/permission.enum';

import { useCurrentMerchant } from '@/context/merchant.context';

function useMerchantAccess(
  permission: MerchantPermissions | MerchantPermissions[] = [],
) {
  const { merchant } = useCurrentMerchant();
  const checkAccess = useCallback(
    (permission: MerchantPermissions | MerchantPermissions[] = []) => {
      const permissionsToCheck = Array.isArray(permission)
        ? permission
        : [permission];

      return (
        !!merchant &&
        (!permissionsToCheck.length ||
          permissionsToCheck.some((x) =>
            merchant.roles.some((y) => y.permissions.includes(x)),
          ))
      );
    },
    [merchant],
  );
  const hasAccess = useMemo(
    () => checkAccess(permission),
    [checkAccess, permission],
  );

  return { hasAccess, checkAccess };
}

export default useMerchantAccess;
