import React from 'react';

import { Box, Button } from '@chakra-ui/react';

import { ButtonOverrideStyles } from '@/styles/Button';

import { useAppDispatch } from '@/store/hooks';
import { changeNavState } from '@/store/slices/headerSlice';

interface IBurgerMenu {
  isStickyHeader: boolean;
}

/**
 * Burger menu component with burger button and nav-links
 * @component
 * @param    {IBurgerMenu}   props     Component props
 * @param    {boolean}  props.isStickyHeader  Is header sticky or not
 * @example
 * <BurgerMenu />.
 */
export const BurgerMenu: React.FC<IBurgerMenu> = ({ isStickyHeader }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() => dispatch(changeNavState())}
      sx={{
        alignSelf: 'start',
        width: '26px',
        minWidth: '26px',
        position: 'relative',
        top: '0',
        right: '0',
        padding: '0',
        fontSize: '0',
        color: 'transparent',
        zIndex: '1',
        ...ButtonOverrideStyles,
      }}
    >
      <Box
        as={'span'}
        sx={{
          display: 'block',
          width: '100%',
          height: '4px',
          backgroundColor: isStickyHeader ? 'app.black' : 'app.white',
          borderRadius: '5px',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          margin: 'auto',
          transition: 'background 0.2s linear',
          _before: {
            content: '""',
            width: '100%',
            height: '4px',
            backgroundColor: isStickyHeader ? 'app.black' : 'app.white',
            borderRadius: '5px',
            position: 'absolute',
            left: '0',
            top: '-8px',
            zIndex: '1',
            transform: 'rotate(0) translate3d(0,0,0)',
            transformOrigin: '50% 50%',
            transition: 'transform 0.2s linear',
          },
          _after: {
            content: '""',
            width: '100%',
            height: '4px',
            backgroundColor: isStickyHeader ? 'app.black' : 'app.white',
            borderRadius: '5px',
            position: 'absolute',
            bottom: '-8px',
            left: '0',
            zIndex: '1',
            transform: 'rotate(0) translate3d(0,0,0)',
            transformOrigin: '50% 50%',
            transition: 'transform 0.2s linear',
          },
        }}
      >
        Menu
      </Box>
    </Button>
  );
};
