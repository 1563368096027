import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_SUMMARY = {
  template:
    'You will get:\\nAt least {d:decimalAmount} coins {i:coinImage:20px#20px}',
  variables: {
    decimalAmount: '10000',
    coinImage: 'https://remuno.com/icons/icon-placeholder.png',
  },
};

const widgetSlice = createSlice({
  name: 'widget',
  initialState: {
    widgetActive: true,
    apiKey: '',
    buttonSize: 'small',
    fromFiat: 'USD',
    amount: 1,
    variant: 'dialog',
    orderId:
      'Order' +
      Math.floor(Math.random() * 10) +
      Math.floor(Math.random() * 10) +
      Math.floor(Math.random() * 10) +
      Math.floor(Math.random() * 10),
    orderDescription: '',
    theme: 'light',
    quoteExpireAnimation: 'skew',
    showCoinProtocolBanner: true,
    notificationUrl: '',
    scriptHtml: '',
    buttonHtml: '',
    confirmCoinSelection: false,
    marketRate: false,
    isEnabledSelectedCoins: false,
    selectedCoins: [],
    isEnabledCustomDetails: false,
    customDetails: '{}',
    isEnabledSummary: false,
    completeOnFullPaymentDetected: false,
    summary: DEFAULT_SUMMARY,
  },
  reducers: {
    showWidget(state) {
      state.widgetActive = true;
    },
    hideWidget(state) {
      state.widgetActive = false;
    },
    setButtonSize(state, action) {
      state.buttonSize = action.payload.toLowerCase();
    },
    setFromFiat(state, action) {
      state.fromFiat = action.payload;
    },
    setVariant(state, action) {
      state.variant = action.payload.toLowerCase();
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setOrderDescription(state, action) {
      state.orderDescription = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload.toLowerCase();
    },
    setQuoteExpireAnimation(state, action) {
      state.quoteExpireAnimation = action.payload.toLowerCase();
    },
    setShowCoinProtocolBanner(state, action) {
      state.showCoinProtocolBanner = action.payload;
    },
    setCoinSelection(state, action) {
      state.confirmCoinSelection = action.payload;
    },
    setAmount(state, action) {
      state.amount = action.payload;
    },
    setNotificationUrl(state, action) {
      state.notificationUrl = action.payload;
    },
    setApiKey(state, action) {
      state.apiKey = action.payload;
    },
    setButton(state, action) {
      state.buttonHtml = action.payload;
    },
    setScript(state, action) {
      state.scriptHtml = action.payload;
    },
    setMarketRate(state, action) {
      state.marketRate = action.payload;
    },
    completeOnFullPaymentDetected(state, action) {
      state.completeOnFullPaymentDetected = action.payload;
    },
    setIsEnabledSelectedCoins(state, action) {
      state.isEnabledSelectedCoins = action.payload;
    },
    setSelectedCoins(state, action) {
      state.selectedCoins = action.payload;
    },
    setIsEnabledCustomDetails(state, action) {
      state.isEnabledCustomDetails = action.payload;
    },
    setCustomDetails(state, action) {
      state.customDetails = action.payload;
    },
    setIsEnabledSummary(state, action) {
      state.isEnabledSummary = action.payload;
    },
    setSummaryTemplate(state, action) {
      state.summary.template = action.payload;
    },
    setSummaryVariables(state, action) {
      state.summary.variables = {
        ...state.summary.variables,
        ...action.payload,
      };
    },
    init(state) {
      state.buttonSize = 'large';
      state.widgetActive = true;
    },
  },
});

export default widgetSlice.reducer;
export const {
  showWidget,
  hideWidget,
  setApiKey,
  setButtonSize,
  setFromFiat,
  setVariant,
  setOrderId,
  setOrderDescription,
  setMarketRate,
  completeOnFullPaymentDetected,
  setTheme,
  setQuoteExpireAnimation,
  setShowCoinProtocolBanner,
  setIsEnabledSelectedCoins,
  setIsEnabledCustomDetails,
  setIsEnabledSummary,
  setSummaryTemplate,
  setSummaryVariables,
  setCustomDetails,
  setSelectedCoins,
  setAmount,
  setNotificationUrl,
  setButton,
  setScript,
  setCoinSelection,
  init,
} = widgetSlice.actions;
