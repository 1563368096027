import * as React from 'react';
import { forwardRef, RefObject, useCallback, useMemo } from 'react';

import {
  FormControl,
  FormControlProps,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { format, getMonth, getYear, setMonth } from 'date-fns';
import { range } from 'lodash';
import {
  default as ReactDatePicker,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import { AiTwotoneCalendar } from 'react-icons/ai';

import { FormLabel } from '@/elements';
import { FormDatePickerProps } from '@/elements/FormDatePicker';
import 'react-datepicker/dist/react-datepicker.css';

type DatePickerProps = Omit<ChakraInputProps, 'label'> &
  FormDatePickerProps & {
    error?: string | undefined;
    containerProps?: FormControlProps;
    selected?: Date | null;
    setSelected?: Function;
  };

const customHeader = ({
  date,
  changeMonth,
  changeYear,
}: ReactDatePickerCustomHeaderProps) => {
  const months = new Array(12).fill(null).map((_, i) => ({
    value: i,
    label: format(setMonth(new Date(), i), 'MMMM'),
  }));

  const years = range(1922, getYear(new Date()) + 1, 1);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    changeYear(+event.target.value);
  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    changeMonth(+event.target.value);

  return (
    <div>
      <select onChange={handleMonthChange} value={getMonth(date)}>
        {months.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>

      <select onChange={handleYearChange} value={getYear(date)}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      selected,
      setSelected,
      label,
      dateFormat = 'dd/MM/yyyy',
      id,
      containerProps,
      hasTooltip,
      tooltipText,
      error,
      variant = 'primary',
      isDisabled,
      ...rest
    },
    ref,
  ) => {
    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    const handleOnChange = useCallback(
      (date: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelected) {
          setSelected(date);
        }
      },
      [setSelected],
    );

    return (
      <FormControl
        id={inputId}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        {...containerProps}
      >
        {label && (
          <FormLabel
            hasTooltip={hasTooltip}
            id={inputId}
            label={label}
            tooltipText={tooltipText}
          />
        )}
        <InputGroup>
          <ChakraInput
            as={ReactDatePicker}
            autoComplete='off'
            dateFormat={dateFormat}
            id={inputId}
            onChange={handleOnChange}
            ref={ref as RefObject<HTMLInputElement>}
            renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => {
              return customHeader({ ...props });
            }}
            selected={selected}
            variant={variant}
            {...rest}
          />
          <InputRightElement h='100%'>
            <AiTwotoneCalendar size='1rem' />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    );
  },
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
