import { WithCSSVar } from '@chakra-ui/react';
import { StylesConfig } from 'react-select';

const primaryStyles = (theme: WithCSSVar<any>): StylesConfig => ({
  singleValue: (provided, props: any) => ({
    ...provided,
    color: theme.colors.app.textDark,
    ...(props.selectProps.alignCenter && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '2.388rem',
    }),
  }),
  option: (provided, props) => ({
    backgroundColor: props.isSelected
      ? theme.colors.app.neutralGray
      : props.isFocused
      ? theme.colors.app.neutralGray
      : provided.backgroundColor,
    color: theme.colors.app.black,
    padding: '8px 9px',
    cursor: 'pointer',
    transition: 'all .5s',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colors.app.widgetGray,
  }),
  control: (_, props: any) => ({
    display: 'flex',
    outline: 'none',
    boxShadow: 'none',
    border: '1px solid',
    paddingLeft: '8px',
    borderRadius: props.menuIsOpen ? '0.25rem 0.25rem 0 0' : '0.25rem',
    borderColor: props.selectProps.isInvalid
      ? theme.colors.app.widgetRed
      : props.isFocused
      ? theme.colors.app.widgetGray
      : theme.colors.app.borderGray,
    background: props.isDisabled
      ? theme.colors.app.neutralGray
      : theme.colors.app.white,
    transition: 'all 0.5s ease-out',
    ':hover': {
      borderColor: props.isDisabled
        ? theme.colors.app.borderGray
        : theme.colors.app.widgetGray,
      cursor: props.isDisabled ? 'not-allowed' : 'default',
    },
  }),
  container: (provided) => {
    return {
      ...provided,
      pointerEvents: 'auto',
      width: '100%',
    };
  },
  menuPortal: (provided) => ({
    ...provided,
    border: 'none',
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: '0px',
    boxShadow: 'none',
    border: '1px solid',
    borderTop: 'none',
    borderColor: theme.colors.app.widgetGray,
    borderRadius: '0 0 0.25rem 0.25rem',
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 9999,
    paddingTop: '0px',
    border: '1px solid',
    borderColor: theme.colors.app.neutralGray,
    padding: '0',
    borderRadius: '0 0 0.25rem 0.25rem',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
});

export default primaryStyles;
