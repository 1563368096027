import React, { FC, useCallback } from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import Input from '../Input';

export type FormInputProps = Omit<
  FormControlProps,
  'label' | 'onChange' | 'defaultValue'
> & {
  hasTooltip?: boolean;
  label?: string | React.ReactNode;
  name: string;
  placeholder?: string;
  tooltipText?: string | React.ReactNode;
  type?: string;
  value?: string;
  variant?: string;
  autoComplete?: string;
};

const FormInput: FC<FormInputProps> = ({ name, variant, ...rest }) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    register,
  } = useFormContext();

  const clearFieldHandler = useCallback(() => {
    setValue(name, '', { shouldValidate: true });
  }, [name, setValue]);

  const showClear = getValues(name) && variant !== 'readonly';
  const error = errors[name]?.message as string;

  return (
    <Input
      clearFieldHandler={clearFieldHandler}
      containerProps={{ mb: 'lg' }}
      error={error}
      isInvalid={Boolean(errors[name]?.message)}
      showClear={showClear}
      variant={variant}
      {...register(name)}
      {...rest}
    />
  );
};

export default FormInput;
