import React from 'react';

import { Image, CSSObject } from '@chakra-ui/react';

interface IWebPImage {
  webpPath: string;
  commonPath: string;
  alt: string;
  sx?: CSSObject | undefined;
}

/**
 * Polyfill component for webp format of image
 * @component
 * @param    {IWebPImage}   props     Component props
 * @param    {string}  props.webpPath  Path to webp format of image
 * @param    {string}  props.commonPath  Path to common format of image
 * @param    {string}  props.alt  Alt for image
 * @param    {CSSObject | undefined}  props.sx  Needed styles for image
 * @example
 * <WebPImage
 *    alt='wooCommerce'
 *    commonPath='icons/wooCommerce.svg'
 *    webpPath='icons/webp/wooCommerce.webp'
 * />
 */
export const WebPImage: React.FC<IWebPImage> = ({
  webpPath,
  commonPath,
  alt,
  ...props
}) => {
  const getImageTypeFromPath = (path: string): string => {
    const arrFromStr = path.split('.');
    const fileExt = arrFromStr[1] === 'jpg' ? 'jpeg' : arrFromStr[1];

    return `image/${fileExt}`;
  };

  return (
    <picture>
      <source srcSet={webpPath} type='image/webp' />
      <source srcSet={commonPath} type={getImageTypeFromPath(commonPath)} />
      <Image alt={alt} src={commonPath} {...props} />
    </picture>
  );
};
