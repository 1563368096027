import React, { FC } from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { ReactDatePickerProps } from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';

import { DatePicker } from '@/elements';

export type FormDatePickerProps = Omit<FormControlProps, 'label'> &
  Omit<ReactDatePickerProps, 'onChange'> & {
    name: string;
    label: string | React.ReactNode;
    value?: string;
    tooltipText?: string | React.ReactNode;
    hasTooltip?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
  };

const FormDatePicker: FC<FormDatePickerProps> = ({ name, ...rest }) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <DatePicker
      containerProps={{ mb: 'lg' }}
      error={fieldState.error?.message}
      isInvalid={Boolean(fieldState.error)}
      selected={field.value}
      {...rest}
      {...field}
    />
  );
};

export default FormDatePicker;
