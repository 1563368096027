import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useTheme,
} from '@chakra-ui/react';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useTranslation } from 'react-i18next';
import { FiClock } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PasteIcon } from '@/assets/icons/paste.svg';
import { AppButton } from '@/elements';

import { CloseModalIconButton } from '@/components/IconButton/close-modal';
import VerificationCodeInput from '@/components/VerificationCodeInput';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';

import {
  useConfirmUserMutation,
  useResendForConfirmEmailMutation,
} from '@/services/api.service';

import { useCountdown } from '@/hooks/useCountdown';
import { ToastTypes, useCustomToast } from '@/hooks/useCustomToast';

import { RECAPTCHA_KEY } from '@/utils/constants';
import { getErrorMessageByCode } from '@/utils/getErrorMessage';

interface ISignupSuccessModal {
  isOpen: boolean;
  email: string | undefined;
  onClose(): void;
  blockScrollOnMount?: boolean;
}

const TOKEN_LIFESPAN = 900000;
const REFRESH_DELAY = 60000;

/**
 * Modal component for successful signup
 * @component
 * @param    {ISignupSuccessModal}   props     Component props
 * @param    {boolean}  props.isOpen  Modal open or not
 * @param    {string}  props.email  Email to send the link for verification
 * @param    {void}  props.onClose  Function to close modal
 * @example
 * <SignupSuccessModal
 *    isOpen={isOpen}
 *    email={email}
 *    onClose={onClose}
 * />
 */
export const SignupSuccessModal: React.FC<ISignupSuccessModal> = ({
  isOpen,
  email,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [tokenExpiration, setTokenExpiration] = useState(
    Date.now() + TOKEN_LIFESPAN,
  );
  const [refreshDelay, setRefreshDelay] = useState(Date.now());

  const { timeLeftSecs: refreshSecsLeft } = useCountdown({
    stopAt: refreshDelay,
    start: true,
    key: 'refreshTimer',
  });

  const { timeLeftSecs: tokenLeftSecs } = useCountdown({
    stopAt: tokenExpiration,
    start: true,
    key: 'tokenTimer',
  });

  const [code, setCode] = React.useState('');
  const captchaElement = React.useRef<ReCAPTCHA | null>();

  const customToast = useCustomToast();

  const handlePasteCode = async () => {
    const text = await navigator.clipboard.readText();
    if (/^\d{6}$/.test(text?.trim())) {
      setCode(text?.trim());
    }
  };

  const [resendLink, { isLoading }] = useResendForConfirmEmailMutation();
  const [confirmUser, { isLoading: isLoadingConfirmUser }] =
    useConfirmUserMutation();

  useEffect(() => {
    if (isOpen) {
      setTokenExpiration(Date.now() + TOKEN_LIFESPAN);
    }
  }, [isOpen]);

  const resendForConfirmEmail = async () => {
    if (!captchaElement.current) {
      throw new Error('Invalid capture');
    }
    const captureValue = await captchaElement.current?.executeAsync();

    await resendLink({
      security: captureValue || '',
      email: email || '',
    })
      .then(() => {
        customToast(t('signup.resendModal.successMessage'), ToastTypes.success);

        setRefreshDelay(Date.now() + REFRESH_DELAY);
        setTokenExpiration(Date.now() + TOKEN_LIFESPAN);
        setCode('');
      })
      .catch((e) => {
        customToast(t(getErrorMessageByCode(e.data.code)), ToastTypes.error);
      });
  };

  const submitToken = async () => {
    try {
      const res = await confirmUser({
        token: code || '',
        email: email || '',
      }).unwrap();
      if (res.alreadyConfirmed) {
        throw t('alreadyConfirmed.title');
      }
      onClose();
      navigate(AppRoutesEnum.thankYou);
    } catch (e) {
      customToast(t(getErrorMessageByCode(e.data.code)), ToastTypes.error);
    }
  };

  const formattedCountdown = moment.utc(tokenLeftSecs * 1000).format('mm:ss');

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size='2xl'
    >
      <ModalOverlay />

      <ModalContent>
        <CloseModalIconButton onClick={onClose} />

        <ModalBody pb='1.875rem' pt='lg' px='lg'>
          <Flex align='center' justify='center'>
            <FiClock color={theme.colors.app.pink} size='3.75rem' />
          </Flex>

          <Heading
            as='h3'
            colorScheme='app.pink'
            fontSize='3xl'
            my='lg'
            textAlign='center'
          >
            {t('signup.successModal.title')}
          </Heading>

          <Box
            sx={{
              width: '100%',
              maxWidth: '34.375rem',
              margin: '0 auto',
              textAlign: 'center',
              paddingBottom: '1.875rem',
              fontSize: 'md',
            }}
          >
            <Box>{t('signup.successModal.sentAnEmail')}</Box>

            <Box>
              <Text as={'span'} fontWeight='700'>
                {email}{' '}
              </Text>
              <span>{t('signup.successModal.oneTimeCode')}</span>
            </Box>
            <Box
              alignItems='center'
              display='flex'
              justifyContent='center'
              mt='lg'
            >
              <VerificationCodeInput
                codeLength={6}
                onChange={(e) => setCode(e)}
                value={code}
              />
              <IconButton
                aria-label='forward'
                bg='app.white'
                icon={<PasteIcon color='app.widgetGray' fontSize='22px' />}
                ml={3}
                onClick={handlePasteCode}
                size='sm'
              />
            </Box>

            <Flex justify='center' mt={3}>
              {tokenLeftSecs ? (
                <>
                  <Text as='span' mr={1}>
                    {t('signup.successModal.codeExpires')}
                  </Text>
                  <Text as='span' fontWeight={700}>
                    {formattedCountdown}
                  </Text>
                </>
              ) : (
                <Text fontWeight={700}>
                  {t('signup.successModal.codeExpired')}
                </Text>
              )}
            </Flex>

            <Box pt='md'>
              <Text>
                {t('signup.successModal.noEmail')}{' '}
                <AppButton
                  as={Link}
                  href='mailto:support@remuno.com?subject=Support Request'
                  isExternal
                  variant='link'
                >
                  {t('signup.successModal.contactSupport')}
                </AppButton>
              </Text>
            </Box>
          </Box>

          <Flex justify='center' sx={{ marginBottom: 'lg' }}>
            <AppButton
              isDisabled={isLoadingConfirmUser || code.length !== 6}
              isLoading={isLoadingConfirmUser}
              onClick={submitToken}
              size='lg'
              variant='primary'
            >
              {t('signup.successModal.oneTimeSubmit')}
            </AppButton>
          </Flex>

          {!!refreshSecsLeft && (
            <Flex justify='center'>
              <Text>Send again after {refreshSecsLeft} seconds</Text>
            </Flex>
          )}

          <Flex justify='center' sx={{ marginTop: 'lg' }}>
            <AppButton
              isDisabled={!!refreshSecsLeft || isLoading}
              isLoading={isLoading}
              onClick={resendForConfirmEmail}
              size='sm'
              variant='outlined'
            >
              {t('signup.successModal.resend')}
            </AppButton>
          </Flex>

          {RECAPTCHA_KEY && (
            <ReCAPTCHA
              ref={(el: any) => (captchaElement.current = el)}
              sitekey={RECAPTCHA_KEY}
              size='invisible'
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
