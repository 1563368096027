import primaryComponents from './primary/components';
import primaryStyles from './primary/styles';
import secondaryComponents from './secondary/components';
import secondaryStyles from './secondary/styles';

const variants = {
  primary: {
    styles: primaryStyles,
    components: primaryComponents,
  },
  secondary: {
    styles: secondaryStyles,
    components: secondaryComponents,
  },
  readonly: {
    styles: primaryStyles,
    components: primaryComponents,
  },
};

export default variants;
