import React from 'react';

import { IconButtonProps } from '@chakra-ui/react';

import { ReactComponent as CloseModalIcon } from '@/assets/icons/close-modal.svg';
import { AppIconButton } from '@/elements';

import { WithOptional } from '@/types/domain';

export const CloseModalIconButton: React.FC<
  WithOptional<IconButtonProps, 'aria-label'>
> = ({ 'aria-label': ariaLabel = 'close-modal', ...rest }) => {
  return (
    <AppIconButton
      aria-label={ariaLabel}
      backgroundColor='app.bgBoldGray'
      icon={<CloseModalIcon />}
      isRound
      position='absolute'
      right={{ base: '0.625rem', md: '1.25rem' }}
      top={{ base: '1.125rem', md: '1.25rem' }}
      variant='default'
      {...rest}
    />
  );
};
