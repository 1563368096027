import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: 'app.pink',
  },
  sizes: {
    sm: {
      fontSize: 'md',
    },
    md: {
      fontSize: 'md',
    },
    lg: {
      fontSize: 'xl',
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Link;
