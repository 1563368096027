import React, { FC, SVGProps } from 'react';

export const SettlmentConfirmIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#0CB04E',
  ...props
}) => (
  <svg
    fill='none'
    height={20}
    viewBox='0 0 21 20'
    width={21}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119ZM5.48267 11.4282C5.08911 11.4282 4.74951 11.2886 4.46387 11.0093C4.18457 10.7236 4.04492 10.384 4.04492 9.99048C4.04492 9.59692 4.18457 9.2605 4.46387 8.9812C4.74951 8.69556 5.08911 8.55273 5.48267 8.55273C5.86987 8.55273 6.20312 8.69556 6.48242 8.9812C6.76807 9.2605 6.91089 9.59692 6.91089 9.99048C6.91089 10.384 6.77124 10.7236 6.49194 11.0093C6.21265 11.2886 5.87622 11.4282 5.48267 11.4282ZM10.0625 11.4282C9.66895 11.4282 9.33252 11.2886 9.05322 11.0093C8.77393 10.7236 8.63428 10.384 8.63428 9.99048C8.63428 9.59692 8.77393 9.2605 9.05322 8.9812C9.33252 8.69556 9.66895 8.55273 10.0625 8.55273C10.4624 8.55273 10.802 8.69556 11.0813 8.9812C11.3606 9.2605 11.5002 9.59692 11.5002 9.99048C11.5002 10.384 11.3606 10.7236 11.0813 11.0093C10.802 11.2886 10.4624 11.4282 10.0625 11.4282ZM14.6519 11.4282C14.252 11.4282 13.9124 11.2886 13.6331 11.0093C13.3538 10.7236 13.2141 10.384 13.2141 9.99048C13.2141 9.59692 13.3538 9.2605 13.6331 8.9812C13.9124 8.69556 14.252 8.55273 14.6519 8.55273C15.0391 8.55273 15.3723 8.69556 15.6516 8.9812C15.9373 9.2605 16.0801 9.59692 16.0801 9.99048C16.0801 10.384 15.9373 10.7236 15.6516 11.0093C15.3723 11.2886 15.0391 11.4282 14.6519 11.4282Z'
      fill={color}
    />
  </svg>
);
