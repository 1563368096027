export enum StorageKeysEnum {
  authToken = 'remuno-auth-jwt',
  refreshTokenLeader = 'rtknl',
  isCookieAccepted = 'isCookieAccepted',
  merchantId = 'merchantId',
  merchantsOptionsList = 'merchantsOptionsList',
  cashbackONAccepted = 'cashbackONAccepted',
  cashbackOFFAccepted = 'cashbackOFFAccepted',
  txnFeeDecreaseAccepted = 'txnFeeDecreaseAccepted',
  txnFeeIncreaseAccepted = 'txnFeeIncreaseAccepted',
}
