import React from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store/hooks';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';
import { MediaQueryAmountsEnum } from '@/enum/mediaQueryAmounts.enum';

import { useAuth } from '@/context/auth.context';

import { HomePageMobileNav } from '../HomePage/mobile-nav';

import { SidebarDesktop } from './sidebar-desktop';
import { SidebarMobile } from './sidebar-mobile';

/**
 * Component for choosing needed Sidebar
 * @component
 * @example
 * <RenderSidebarComponent />
 */
export const RenderSidebarComponent: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const isActiveNav = useAppSelector((state) => state.header.isActiveNav);
  const [notADesktop] = useMediaQuery(MediaQueryAmountsEnum.isNotADesktop);
  const location = useLocation();

  const isHomePage = location.pathname === AppRoutesEnum.main;
  const isKYCPage = location.pathname === AppRoutesEnum.merchantVerify;
  const isSignInPage = location.pathname === AppRoutesEnum.login;
  const isSignUpPage = location.pathname === AppRoutesEnum.signup;
  const isThankYouPage = location.pathname === AppRoutesEnum.thankYou;
  const isInviteUserPage =
    location.pathname === AppRoutesEnum.handleActionInvite;
  const isKYCFeedbackPage = location.pathname === AppRoutesEnum.kycFeedback;

  const pagesWithoutSidebar =
    isKYCPage ||
    isSignInPage ||
    isSignUpPage ||
    isThankYouPage ||
    isInviteUserPage ||
    isKYCFeedbackPage;

  //@TODO: rewrite to easy understanding code without a lot of conditions
  const renderSidebarByCondition = () => {
    if (isLoggedIn) {
      if (isHomePage && isActiveNav) {
        return <HomePageMobileNav />;
      } else if ((isHomePage && !isActiveNav) || pagesWithoutSidebar) {
        return;
      } else if (!notADesktop) {
        return <SidebarDesktop />;
      } else if (isActiveNav) {
        return <SidebarMobile />;
      }
    } else {
      if (isActiveNav) {
        return <HomePageMobileNav />;
      }
    }
  };

  return <>{renderSidebarByCondition()}</>;
};
