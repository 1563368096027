import React, { forwardRef } from 'react';

import {
  Button as ChakraButton,
  ButtonProps,
  LinkProps,
  useBreakpointValue,
} from '@chakra-ui/react';

import { IScrollLink } from '@/types/domain';

export type AppButtonProps = ButtonProps & IScrollLink & LinkProps;

const AppButton = forwardRef<
  HTMLButtonElement & HTMLAnchorElement,
  AppButtonProps
>((rest, ref) => {
  const buttonSize = useBreakpointValue({ base: 'sm', lg: 'md' });
  return <ChakraButton ref={ref} size={buttonSize} {...rest} />;
});

AppButton.displayName = 'Button';

export default AppButton;
