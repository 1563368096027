import React from 'react';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppButton } from '@/elements';

import { ButtonOverrideStyles } from '@/styles/Button';

import { addMerchantIdToRoute, AppRoutesEnum } from '@/enum/appRoutes.enum';
import { MediaQueryAmountsEnum } from '@/enum/mediaQueryAmounts.enum';
import { adminPermissions } from '@/enum/permission.enum';

import { useAuth } from '@/context/auth.context';
import { useCurrentMerchant } from '@/context/merchant.context';

import { ToastTypes, useCustomToast } from '@/hooks/useCustomToast';
import useUserAccess from '@/hooks/useUserAccess';

interface IDesktopMenuProps {
  onOpen(): void;
}

/**
 * Desktop menu component
 * @component
 * @example
 * <DesktopMenu />.
 */
export const DesktopMenu: React.FC<IDesktopMenuProps> = ({ onOpen }) => {
  const { t } = useTranslation();
  const customToast = useCustomToast();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery([
    MediaQueryAmountsEnum.isMobile,
    MediaQueryAmountsEnum.isNotADesktop,
  ]);
  const { hasAccess: hasAnyAdminPermission } = useUserAccess(adminPermissions);
  const { logout } = useAuth();

  const { merchant, availableMerchants } = useCurrentMerchant();
  const { user } = useAuth();

  const navigateToDashboard = (closeMenu: () => void) => {
    if (hasAnyAdminPermission) {
      navigate(AppRoutesEnum.adminSummary);
    } else {
      navigate(
        addMerchantIdToRoute(AppRoutesEnum.summary, merchant?.displayId),
      );
    }

    closeMenu();
  };

  const handleSignOut = (closeMenu: () => void) => {
    logout();

    closeMenu();

    customToast(t('common.logoutMessage'), ToastTypes.success);

    navigate(AppRoutesEnum.main);
  };

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            as={Button}
            isDisabled={!user}
            pr='0'
            {...ButtonOverrideStyles}
          >
            <Avatar
              h={{ base: '32px', md: '40px' }}
              w={{ base: '32px', md: '40px' }}
            />
          </MenuButton>

          <MenuList
            backgroundColor='app.neutralGray'
            border='1px solid'
            borderColor='app.borderGray'
            borderRadius='8px'
            boxShadow='0px 2px 8px rgba(15, 15, 15, 0.08)'
            pb='0px'
          >
            <Flex alignItems='center' justify='space-between' p='12px'>
              <Image
                mr='150px'
                scale={`${isMobile ? '0.8' : '1'}`}
                src='/images/logo.svg'
                w={{ base: '100px', md: '130px' }}
              />

              <Box
                color='app.pink'
                cursor='pointer'
                fontSize='17px'
                fontWeight='700'
                onClick={() => handleSignOut(onClose)}
              >
                {t('common.user.signOut')}
              </Box>
            </Flex>

            <Flex
              alignItems='flex-start'
              backgroundColor='app.white'
              borderRadius='8px'
              fontWeight='normal'
              mx='12px'
              p='12px 15px 12px 12px'
            >
              <Avatar
                h={{ base: '32px', md: '40px' }}
                mr='8px'
                w={{ base: '32px', md: '40px' }}
              />

              <Box>
                <Flex pb='5px'>
                  <Box color='app.textDark' fontWeight='bold'>
                    {user?.name}
                  </Box>
                  <Box
                    color='app.textDark'
                    fontSize='16px'
                    fontWeight='700'
                    px='4px'
                  >
                    •
                  </Box>
                  <Box fontSize='17px'>
                    {user?.roles.map((x) => x.name).join(', ')}
                  </Box>
                </Flex>

                <Box color='app.textDark' fontSize='17px'>
                  {user?.email}
                </Box>

                {merchant && (
                  <Box mt='20px'>
                    <Box
                      color='app.textDark'
                      fontSize='18px'
                      fontWeight='700'
                      pb='4px'
                    >
                      {merchant?.name}
                    </Box>

                    <Box fontSize='17px' pb='8px'>
                      {t('common.user.merchantId')} {merchant?.displayId}
                    </Box>

                    <Box fontSize='17px'>
                      {merchant?.roles.map((x) => x.name).join(', ')}
                    </Box>

                    {availableMerchants.length > 1 && (
                      <AppButton
                        borderColor='app.borderGray'
                        mt='xs'
                        onClick={onOpen}
                        size='sm'
                        variant='outlined'
                      >
                        {t('common.user.switchMerchant')}
                      </AppButton>
                    )}
                  </Box>
                )}
              </Box>
            </Flex>

            <Button
              color='app.pink'
              fontSize='17px'
              fontWeight='bold'
              mb='18px'
              mt='16px'
              onClick={() => navigateToDashboard(onClose)}
              {...ButtonOverrideStyles}
            >
              {t('common.user.returnToDashboard')}
            </Button>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
