import * as React from 'react';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDisclosure } from '@chakra-ui/react';
import jwtDecode from 'jwt-decode';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

import { IUser } from '@/types/domain';
import { LoginParams } from '@/types/query-params';

import { EnterTFACodeModal } from '@/pages/login/EnterTFACodeModal';

import { SignupSuccessModal } from '@/components/Signup/success-modal';

import { useAppDispatch } from '@/store/hooks';

import { ApiTagsEnum } from '@/enum/apiTags.enum';
import { ErrorCodes } from '@/enum/errorCodes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import {
  api,
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useResendForConfirmEmailMutation,
} from '@/services/api.service';

import { ToastTypes, useCustomToast } from '@/hooks/useCustomToast';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRefreshToken } from '@/hooks/useRefreshToken';

import { clearCacheData } from '@/utils/cleanCacheData';
import { RECAPTCHA_KEY } from '@/utils/constants';
import { getErrorMessageByCode } from '@/utils/getErrorMessage';

type LoginNextFn = (accessToken?: string) => any;

type AuthContextType = {
  login: (
    data: Omit<LoginParams, 'security'>,
    next?: LoginNextFn,
  ) => Promise<void>;
  logout: () => Promise<void>;
  clearCache: () => void;
  user?: IUser;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
};

const AuthContext = React.createContext<AuthContextType>({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  clearCache: () => undefined,
  user: undefined,
  isLoggingIn: false,
  isLoggedIn: false,
  isLoading: true,
});

const tenMinutes = 1000 * 60 * 10;

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customToast = useCustomToast();
  const captchaElement = useRef<ReCAPTCHA | null>();
  const {
    value: jwt,
    removeItem: removeJwt,
    setItem: setJwtToLocalStorage,
  } = useLocalStorage<string>(StorageKeysEnum.authToken);
  const decodedJwt = useMemo<{ id: string; exp: number } | null>(
    () => (jwt ? jwtDecode(jwt) : null),
    [jwt],
  );
  const lastUserId = useRef<string | null | undefined>();
  const userId = decodedJwt?.id;
  const userChanged = !!(
    lastUserId.current &&
    userId &&
    lastUserId.current !== userId
  );

  lastUserId.current = userId;
  const isLoggedIn = !!(decodedJwt?.exp && decodedJwt.exp * 1000 > Date.now());
  const {
    data: user,
    isLoading,
    error,
  } = useGetUserQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [requestLogout] = useLogoutMutation();
  const [loginRequest, { isLoading: isLoggingIn }] = useLoginMutation();
  const [resendLink] = useResendForConfirmEmailMutation();
  const [{ next: doAfterLogin }, setNext] = useState<{
    next?: LoginNextFn;
  }>({ next: undefined });
  const [loginData, setLoginData] = useState<Omit<
    LoginParams,
    'security'
  > | null>(null);
  const [loginErrorCode, setLoginErrorCode] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showUnverifiedWarning, setShowUnverifiedWarning] = useState(false);

  const clearCache = useCallback(() => {
    sessionStorage.removeItem(StorageKeysEnum.merchantId);
    dispatch(api.util.resetApiState());
  }, [dispatch]);

  useEffect(() => {
    if (userChanged) {
      sessionStorage.removeItem(StorageKeysEnum.merchantId);
      dispatch(api.util.invalidateTags(Object.values(ApiTagsEnum)));
    }
    // we only need to refetch user when userId in accessToken changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const login = useCallback(
    async (
      loginData: Omit<LoginParams, 'security'>,
      doNext?: LoginNextFn,
    ): Promise<void> => {
      try {
        const captchaValue = await captchaElement.current?.executeAsync();

        setLoginData(loginData);

        const res = await loginRequest({
          ...loginData,
          security: captchaValue as string,
        });

        if ('error' in res) {
          throw res.error;
        }

        const { data } = res;

        if ('mfaRequired' in data && !isOpen) {
          setNext({ next: doNext });
          onOpen();

          return;
        }

        onClose();

        if ('accessToken' in data) {
          setJwtToLocalStorage(data.accessToken);
          if (doNext) {
            doNext(data.accessToken);
          } else if (doAfterLogin) {
            doAfterLogin(data.accessToken);
          }
        }
      } catch (e) {
        if (e?.data?.code === ErrorCodes.AUTH_UNCONFIRMED_EMAIL) {
          try {
            const captchaValue = await captchaElement.current?.executeAsync();
            setShowUnverifiedWarning(true);
            await resendLink({
              security: captchaValue || '',
              email: loginData?.email,
            }).unwrap();
          } catch (err) {
            customToast(
              t(getErrorMessageByCode(err.data.code)),
              ToastTypes.error,
            );
          }
        } else if (e?.data?.code !== ErrorCodes.AUTH_TFA_INVALID_CODE) {
          customToast(
            t(getErrorMessageByCode(e?.data?.code)),
            ToastTypes.error,
          );
        } else if (isOpen) {
          setLoginErrorCode(e);
        }
        if (doNext) {
          doNext();
        } else if (doAfterLogin) {
          doAfterLogin();
        }
      }
    },
    [
      customToast,
      doAfterLogin,
      isOpen,
      loginRequest,
      onClose,
      onOpen,
      resendLink,
      setJwtToLocalStorage,
      t,
    ],
  );

  const loginWithoutCache = useCallback<typeof login>(
    (...args) => {
      clearCache();
      return login(...args);
    },
    [clearCache, login],
  );

  const handleTfa = useCallback(
    async (code) => {
      if (loginData) {
        await login({ ...loginData, code });
      }
    },
    [login, loginData],
  );

  const logout = useCallback(async () => {
    requestLogout();
    removeJwt();
    clearCache();
    clearCacheData();
  }, [clearCache, removeJwt, requestLogout]);

  useEffect(() => {
    if (error) {
      customToast(t('errorMessages.userNotFound'), ToastTypes.success);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onIdle = useCallback(async () => {
    if (user) {
      await logout();
    }
  }, [logout, user]);

  useIdleTimer({
    onIdle,
    timeout: tenMinutes,
    crossTab: true,
    startOnMount: true,
  });

  useRefreshToken({ onExpire: onIdle });

  return (
    <AuthContext.Provider
      value={{
        login: loginWithoutCache,
        logout,
        isLoggingIn,
        isLoggedIn,
        user,
        isLoading,
        clearCache,
      }}
    >
      {children}

      <EnterTFACodeModal
        codeError={loginErrorCode}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleTfa}
      />

      <SignupSuccessModal
        email={loginData?.email}
        isOpen={showUnverifiedWarning}
        onClose={() => setShowUnverifiedWarning(false)}
      />

      {RECAPTCHA_KEY && (
        <ReCAPTCHA
          ref={(el: any) => (captchaElement.current = el)}
          sitekey={RECAPTCHA_KEY}
          size='invisible'
        />
      )}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextType {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
