import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

export type HintProps = {
  text: string;
  icon: React.ReactNode;
  bgColor: string;
};
const Hint: React.FC<HintProps> = ({ text, icon, bgColor }) => {
  return (
    <Flex background={bgColor} borderRadius='4px' my='sm' p='sm'>
      <Box minWidth='1.25rem'>{icon}</Box>
      <Box>
        <Text
          color='app.textDark'
          fontSize='sm'
          fontWeight='600'
          lineHeight='15.6px'
          ml='sm'
        >
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

export default Hint;
