import React from 'react';

import { components, DropdownIndicatorProps } from 'react-select';

import { ReactComponent as DropdownArrowClose } from '@/assets/icons/dropdown-arrow-close.svg';
import { ReactComponent as DropdownArrowOpen } from '@/assets/icons/dropdown-arrow-open.svg';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps } = props;
  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = <DropdownArrowOpen />;
  } else {
    arrow = <DropdownArrowClose />;
  }
  return (
    <components.DropdownIndicator {...props}>
      {arrow}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
