import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        border: '1px solid',
        borderColor: 'app.borderGray',
        borderRadius: '4px',
        transition: 'all 0.5s ease-out',
        _placeholder: {
          color: 'app.widgetGray',
        },
        _focus: {
          border: '1px solid',
          borderColor: 'app.widgetGray',
        },
        _invalid: {
          border: '1px solid',
          borderColor: 'app.red',
          boxShadow: 'none',
        },
        ':not(:disabled):hover': {
          border: '1px solid',
          borderColor: 'app.widgetGray',
        },
        _disabled: {
          backgroundColor: 'app.neutralGray',
          _hover: {
            cursor: 'not-allowed',
          },
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'app.neutralGray',
      },
    },
  },
};

export default Input;
