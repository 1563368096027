import { AppRoutesEnum } from '@/enum/appRoutes.enum';

export const homePageNavLinks = [
  {
    label: 'common.homeNav.acceptPayments',
    link: 'accept-payments',
  },
  {
    label: 'common.homeNav.supportedCoins',
    link: 'supported-coins',
  },
  {
    label: 'common.homeNav.fees',
    link: 'fees',
  },
];

export function isRelativeLink(link: string) {
  return link.charAt(0) === AppRoutesEnum.main;
}
