const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_LOCAL_API_BASE_URL,
    REACT_APP_RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    REACT_APP_SYSTEM_LOGIN_URL: process.env.REACT_APP_SYSTEM_LOGIN_URL,
    REACT_APP_SWAGGER_URL: process.env.REACT_APP_SWAGGER_URL,
    REACT_APP_KYC_URL: process.env.REACT_APP_KYC_URL,
    REACT_APP_KYB_URL: process.env.REACT_APP_KYB_URL,
    REACT_APP_SENTRY_DOMAIN: process.env.REACT_APP_SENTRY_DOMAIN,
    REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME,
    REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID,
  };
}

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;
export const RECAPTCHA_KEY = window.config.REACT_APP_RECAPTCHA_KEY as string;
export const SYSTEM_LOGIN_URL = window.config
  .REACT_APP_SYSTEM_LOGIN_URL as string;
export const SWAGGER_URL = window.config.REACT_APP_SWAGGER_URL as string;
export const REACT_APP_KYC_URL = window.config.REACT_APP_KYC_URL as string;
export const REACT_APP_KYB_URL = window.config.REACT_APP_KYB_URL as string;
export const REACT_APP_SENTRY_DOMAIN = window.config
  .REACT_APP_SENTRY_DOMAIN as string;
export const REACT_APP_ENV_NAME = window.config.REACT_APP_ENV_NAME as string;
export const REACT_APP_GTM_ID = window.config.REACT_APP_GTM_ID as string;

export const DEFAULT_PAGE_SIZE = 25;
