import { useCallback, useEffect, useRef } from 'react';

import jwtDecode from 'jwt-decode';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useRefreshTokenMutation } from '@/services/api.service';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { getStorageItem, setStorageItem } from '@/utils/localStorage';

const twoMinute = 60000 * 2;

const isLeader = async () => {
  const id = `${Math.random()}-${Date.now()}`;

  setStorageItem(StorageKeysEnum.refreshTokenLeader, id);
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(getStorageItem(StorageKeysEnum.refreshTokenLeader) === id),
      500,
    );
  });
};

export const useRefreshToken = (params: { onExpire: Function }) => {
  const { value: jwt, setItem: setJwt } = useLocalStorage<string>(
    StorageKeysEnum.authToken,
  );
  const { onExpire } = params;
  const timerRef = useRef<any>();
  const [refreshToken] = useRefreshTokenMutation();

  const requestTokenRefresh = useCallback(async () => {
    const res = await refreshToken();

    if ('data' in res) {
      setJwt(res.data.accessToken);
    } else if ('error' in res) {
      onExpire();
    }
  }, [onExpire, refreshToken, setJwt]);

  const runTimer = useCallback(
    (token: string | null) => {
      clearTimeout(timerRef.current);
      let expiresAt = 0;

      if (token) {
        expiresAt = (jwtDecode(token) as { exp: number }).exp * 1000;
      }

      if (expiresAt > Date.now()) {
        timerRef.current = setTimeout(async () => {
          // edge case when timer is scheduled, but token was removed from storage before refresh is called, e.g. user pressed logout
          // if no token, do nothing
          if (getStorageItem(StorageKeysEnum.authToken)) {
            if (await isLeader()) {
              await requestTokenRefresh();
            }
            runTimer(getStorageItem(StorageKeysEnum.authToken));
          }
        }, expiresAt - Date.now() - twoMinute);
      } else {
        onExpire();
      }
    },
    [onExpire, requestTokenRefresh],
  );

  // start refreshToken timer on page load
  useEffect(() => {
    runTimer(jwt);
    return () => clearTimeout(timerRef.current);
  }, [jwt, runTimer]);
};
