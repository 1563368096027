import Button from './button';
import Checkbox from './checkbox';
import Input from './input';
import FormLabel from './label';
import Link from './link';
import Modal from './modal';

export default {
  Input,
  FormLabel,
  Modal,
  Checkbox,
  Button,
  Link,
};
