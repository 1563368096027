import React, { useMemo } from 'react';

import { Box, Flex, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';

import { addMerchantIdToRoute, AppRoutesEnum } from '@/enum/appRoutes.enum';

import { useCurrentMerchant } from '@/context/merchant.context';

import { useSidebarLinks } from '@/hooks/useSidebarLinks';

import { ISidebarLink, ISidebarSubLink, sidebarLinks } from '../data';

/**
 * Desktop version of sidebar component
 * @component
 * @example
 * <SidebarDesktop />
 */
export const SidebarDesktop: React.FC = () => {
  const { t } = useTranslation();
  const { merchant } = useCurrentMerchant();
  const theme = useTheme();
  const rawLinks = useMemo(() => sidebarLinks(), []);
  const { menu } = useSidebarLinks(rawLinks);

  const isCashBack = (label: string) => {
    const isCashBackPages =
      label === 'common.dashboardNav.cashback' ||
      label === 'common.dashboardNav.viewCashback';

    return (
      (merchant && merchant.cashbackPercent <= 0 && isCashBackPages) ||
      (!merchant && isCashBackPages)
    );
  };

  const isLinkedMerchant = (label: string) => {
    const isLinkedPage = label === 'common.dashboardNav.linkedMerchant';

    return (
      (merchant && merchant.parentMerchantId && isLinkedPage) ||
      (!merchant && isLinkedPage)
    );
  };

  const getLeftGapForLink = (navLink: ISidebarLink) => {
    if (navLink.subLinks) {
      return '32px';
    }
    if (
      [
        addMerchantIdToRoute(AppRoutesEnum.summary, merchant?.displayId),
        AppRoutesEnum.adminSummary,
      ].includes(navLink.link as string)
    ) {
      return '32px';
    }
    return '52px';
  };

  const getSimpleLinkStyles = (isActive: boolean, navLink: ISidebarLink) => {
    return {
      display: 'block',
      fontSize: [
        addMerchantIdToRoute(AppRoutesEnum.summary, merchant?.displayId),
        AppRoutesEnum.adminSummary,
      ].includes(navLink.link as string)
        ? '20px'
        : '16px',
      fontWeight: isActive ? '700' : '400',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: getLeftGapForLink(navLink),
      color: isActive ? theme.colors.app.pink : theme.colors.app.borderGray,
      background: isActive ? theme.colors.app.bgLightDark : 'transparent',
      borderLeft: isActive ? '6px solid' : '0',
      borderColor: isActive ? theme.colors.app.pink : 'transparent',
    };
  };

  const simpleLink = (navLink: ISidebarLink) => (
    <NavLink
      key={navLink.link}
      style={({ isActive }) => getSimpleLinkStyles(isActive, navLink)}
      to={navLink.link!}
    >
      {t(navLink.label)}
    </NavLink>
  );

  const linkWithLockIcon = (navLink: ISidebarLink) => (
    <Flex align='center' key={navLink.link} mb='15px'>
      <NavLink
        key={navLink.link}
        style={({ isActive }) => {
          return {
            ...getSimpleLinkStyles(isActive, navLink),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          };
        }}
        to={navLink.link!}
      >
        <Box>{t(navLink.label)}</Box>

        <Box ml='6px'>
          <LockIcon height='20px' width='20px' />
        </Box>
      </NavLink>
    </Flex>
  );

  const linkWithSubLinks = (navLink: ISidebarLink) => (
    <Box fontSize='xl' key={navLink.label} mt='sm'>
      {navLink?.subLinks?.map((item: ISidebarSubLink) => {
        if (isCashBack(item.label) || isLinkedMerchant(item.label)) return;

        return item.isKycRequired && merchant && !merchant.kyc
          ? linkWithLockIcon(item)
          : simpleLink(item);
      })}
    </Box>
  );

  return (
    <Box
      sx={{
        width: '300px',
        padding: '150px 0 50px',
        backgroundColor: 'app.bgDark',
        height: '100%',
        zIndex: '998',
        boxSizing: 'border-box',
        position: 'fixed',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '20px',
        }}
      >
        {menu.map((navLink: ISidebarLink) => (
          <React.Fragment key={navLink.label}>
            {navLink.link ? (
              simpleLink(navLink)
            ) : (
              <Box pl='32px'>{t(navLink.label)}</Box>
            )}

            {navLink.subLinks && linkWithSubLinks(navLink)}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
