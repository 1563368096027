import React, { FC, SVGProps } from 'react';

export const TransferredIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#0CB04E',
  ...props
}) => (
  <svg
    fill='none'
    height={20}
    viewBox='0 0 20 20'
    width={20}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119ZM8.99609 14.6465C8.83105 14.6465 8.68188 14.6116 8.54858 14.5417C8.41528 14.4719 8.28516 14.3545 8.1582 14.1895L5.76831 11.2568C5.69849 11.1616 5.64136 11.0632 5.59692 10.9617C5.55884 10.8538 5.53979 10.7458 5.53979 10.6379C5.53979 10.4221 5.61279 10.238 5.75879 10.0857C5.90479 9.927 6.08569 9.84766 6.30151 9.84766C6.44116 9.84766 6.56494 9.87622 6.67285 9.93335C6.78711 9.99048 6.90454 10.0984 7.02515 10.2571L8.95801 12.7517L13.0237 6.21997C13.2014 5.92798 13.4299 5.78198 13.7092 5.78198C13.9124 5.78198 14.0964 5.84863 14.2615 5.98193C14.4265 6.10889 14.509 6.28345 14.509 6.50562C14.509 6.61353 14.4836 6.72144 14.4329 6.82935C14.3821 6.93726 14.3281 7.03882 14.271 7.13403L9.7959 14.1895C9.69434 14.3418 9.5769 14.4561 9.4436 14.5322C9.3103 14.6084 9.16113 14.6465 8.99609 14.6465Z'
      fill={color}
    />
  </svg>
);
