import React, { FC } from 'react';

import { FormControlProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { Select, SelectProps } from '@/elements/Select';

export type FormSelectProps = Omit<
  FormControlProps,
  'label' | 'defaultValue' | 'isReadOnly'
> &
  Omit<SelectProps, 'onChange'>;

export const FormSelect: FC<FormSelectProps> = ({ name, options, ...rest }) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <Select
      containerProps={{ mb: 'lg' }}
      error={fieldState.error?.message}
      isInvalid={Boolean(fieldState.error)}
      options={options}
      {...rest}
      {...field}
    />
  );
};

export default FormSelect;
