import React from 'react';

import { Global } from '@emotion/react';

/**
 * Component of fonts for app
 * @component
 * @example
 * <Fonts />
 */
const Fonts = () => (
  <Global
    styles={`
          /* latin */
          @font-face {
            font-family: 'Axiforma';
            font-style: normal;
            font-weight: 800;
            font-display: swap;
            src: url('./fonts/Axiforma/Axiforma-ExtraBold.woff2') format('woff2'),
             url('./fonts/Axiforma/Axiforma-ExtraBold.eot?#iefix') format('embedded-opentype'),
             url('./fonts/Axiforma/Axiforma-ExtraBold.ttf') format('truetype'),
             url('./fonts/Axiforma/Axiforma-ExtraBold.woff') format('woff');
          }
      `}
  />
);

export default Fonts;
