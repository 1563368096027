import React, { useCallback } from 'react';

import { Box, Container, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ISimpleMerchant } from '@/types/domain';

import { page404 } from '@/routes/public';

import { PageLoader } from '@/components/Loaders/PageLoader';
import MerchantSelect from '@/components/MerchantSelect';

import { MerchantPermissions } from '@/enum/permission.enum';

import { useCurrentMerchant } from '@/context/merchant.context';

import useMerchantAccess from '@/hooks/useMerchantAccess';

/**
 * Require auth component for making private merchant routes
 * @component
 * @example
 * <MerchantGuard />
 */
export const MerchantGuard: React.FC<{
  permissions?: MerchantPermissions | MerchantPermissions[];
}> = ({ permissions }) => {
  const { t } = useTranslation();
  const {
    merchant,
    merchantId,
    availableMerchants,
    isLoading,
    isFetching,
    changeMerchant,
  } = useCurrentMerchant();
  const { hasAccess } = useMerchantAccess(permissions);

  const handleMerchantSelect = useCallback(
    (selected: ISimpleMerchant) => {
      changeMerchant(selected.displayId);
    },
    [changeMerchant],
  );

  if (merchant) {
    if (!hasAccess) {
      return page404.element;
    }
    return (
      <>
        {isFetching && merchant.displayId !== merchantId && <PageLoader />}
        <Outlet />
      </>
    );
  }

  if (isLoading || (availableMerchants?.length === 1 && !merchant)) {
    return <PageLoader />;
  }

  return (
    <Container
      alignItems='center'
      display='flex'
      height='100%'
      maxWidth='350px'
      mx='auto'
      width='100%'
    >
      <Box>
        <Heading color='app.pink' mb='40px' textAlign='center'>
          {t('merchant.selectToContinue')}
        </Heading>

        <MerchantSelect
          merchants={availableMerchants}
          onChange={handleMerchantSelect}
        />
      </Box>
    </Container>
  );
};
