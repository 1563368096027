export enum ErrorCodes {
  UNDEFINED = 1000,
  VALIDATION = 1001,
  AUTH = 1002,
  NOT_FOUND = 1003,

  REGISTRATION_ACCOUNT_VERIFIED = 1100,
  REGISTRATION_EMAIL_IN_USE = 1101,
  REGISTRATION_FAILED_TO_CREATE_MERCHANT = 1102,
  REGISTRATION_INVALID_CONFIRMATION_CODE = 1103,
  REGISTRATION_MERCHANT_NOT_FOUND = 1104,
  REGISTRATION_EMAIL_IN_USE_CAN_RESEND = 1105,
  REGISTRATION_COUNTRY_NOT_FOUND = 1106,

  AUTH_INVALID_CREDENTIALS = 1200,
  AUTH_TFA_INVALID_CODE = 1201,
  AUTH_TFA_INVALID_CONFIRMATION = 1202,
  AUTH_ACCOUNT_DISABLED = 1203,
  AUTH_UNCONFIRMED_EMAIL = 1204,

  USER_MANAGEMENT_USER_NOT_FOUND = 1300,
  USER_MANAGEMENT_USER_NOT_ADMIN = 1301,

  INVITE_USER_ALREADY_INVITED = 1400,
  INVITE_INVALID_CODE = 1401,
}
