import { WithCSSVar } from '@chakra-ui/react';
import { StylesConfig } from 'react-select';

const secondaryStyles = (
  theme: WithCSSVar<any>,
): StylesConfig<any, boolean, any> => ({
  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.app.widgetGray,
  }),
  option: (_, props) => ({
    backgroundColor: props.isSelected ? theme.colors.app.neutralGray : 'none',
    color: theme.colors.app.black,
    padding: '8px 9px',
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colors.app.textGray,
  }),
  control: (provided) => ({
    ...provided,
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    alignItems: 'center',
  }),
  container: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: '0',
  }),
  menuPortal: (provided) => ({
    ...provided,
    border: 'none',
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: '0px',
    border: 'none',
    boxShadow: 'none',
    minWidth: '70px',
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 9999,
    paddingTop: '0px',
    margin: '-1px',
    border: '1px solid',
    borderColor: theme.colors.app.neutralGray,
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
});

export default secondaryStyles;
