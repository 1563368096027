import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { getStorageItem } from '@/utils/localStorage';

export const prepareHeaders = (headers: Headers) => {
  const jwt = getStorageItem<string>(StorageKeysEnum.authToken);

  if (jwt) {
    headers.set('authorization', `Bearer ${jwt}`);
  }

  return headers;
};

export const extractAttachmentNameFromHeader = (header: string) => {
  const [, filename] = /filename="([^"]+)/.exec(header) || [];
  return filename;
};
