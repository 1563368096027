import * as React from 'react';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ICurrentMerchant, IMerchant, ISimpleMerchant } from '@/types/domain';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import {
  useGetMerchantInfoQuery,
  useGetMerchantsListQuery,
} from '@/services/api.service';

import { useAuth } from '@/context/auth.context';

import { ToastTypes, useCustomToast } from '@/hooks/useCustomToast';

type CurrentMerchantContextType = {
  merchantId?: string;
  merchantDisplayId?: string;
  merchant?: ICurrentMerchant;
  parentMerchant?: IMerchant | undefined;
  isLoading: boolean;
  isFetching: boolean;
  availableMerchants: ISimpleMerchant[];
  loadingAvailableMerchants: boolean;
  changeMerchant: (merchantId: string, options?: { replace?: boolean }) => void;
  refetch: () => void;
};

const CurrentMerchantContext = React.createContext<CurrentMerchantContextType>({
  merchantId: undefined,
  merchant: undefined,
  isLoading: true,
  isFetching: true,
  availableMerchants: [],
  loadingAvailableMerchants: true,
  changeMerchant: () => undefined,
  refetch: () => null,
});

const CurrentMerchantProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const customToast = useCustomToast();
  const { merchantDisplayId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname, search, hash, state } = useLocation();
  const [, triggerUpdate] = useState(0);
  const lastUserId = useRef<string | null | undefined>();
  const userHasChanged =
    lastUserId.current && user?.id && lastUserId.current !== user.id;
  lastUserId.current = user?.id;

  const {
    data: merchantsList,
    isLoading: loadingMerchantsList,
    isFetching: fetchingMerchantsList,
    error: merchantListError,
  } = useGetMerchantsListQuery(undefined, {
    skip: !user,
  });

  const merchantIdRef = useRef<string | undefined>(
    sessionStorage.getItem(StorageKeysEnum.merchantId) || undefined,
  );

  if (userHasChanged) {
    // refresh merchantId when user was changed,
    // e.g. when accepted invite while being authenticated
    merchantIdRef.current =
      sessionStorage.getItem(StorageKeysEnum.merchantId) || undefined;
  }

  merchantIdRef.current =
    !merchantDisplayId || merchantDisplayId === ':merchantDisplayId'
      ? merchantIdRef.current
      : merchantDisplayId;

  const currentMerchant = useMemo(
    () => merchantsList?.find((x) => x.displayId === merchantIdRef.current),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [merchantsList, merchantIdRef.current],
  );

  const {
    data: merchantData,
    isLoading: loadingMerchantData,
    isFetching: fetchingMerchantData,
    refetch: refetchMerchant,
  } = useGetMerchantInfoQuery(currentMerchant?.id as string, {
    skip: !user || !currentMerchant,
  });

  const refetch = () => {
    refetchMerchant();
  };

  const availableMerchants = useMemo(
    () => merchantsList || [],
    [merchantsList],
  );

  const changeMerchant = useCallback<
    CurrentMerchantContextType['changeMerchant']
  >(
    (merchantDisplayId, options) => {
      if (/\/merchant\/([^/]+)\/(.*)/.test(pathname)) {
        const rewrotePath = pathname.replace(
          /\/merchant\/([^/]+)\/(.*)/,
          `/merchant/${merchantDisplayId}/$2`,
        );

        navigate(rewrotePath + search + hash, {
          state,
          replace: options?.replace,
        });
      } else {
        merchantIdRef.current = merchantDisplayId;
        triggerUpdate(Math.random());
      }
    },
    [hash, navigate, pathname, search, state],
  );

  useEffect(() => {
    if (merchantIdRef.current) {
      sessionStorage.setItem(StorageKeysEnum.merchantId, merchantIdRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantIdRef.current]);

  useEffect(() => {
    if (merchantListError) {
      customToast(t('errorMessages.errorGetMerchantList'), ToastTypes.error);
    }
  }, [customToast, merchantListError, t]);

  useEffect(() => {
    if (!merchantData && availableMerchants?.length === 1) {
      changeMerchant(availableMerchants[0].displayId, { replace: true });
    }
  }, [availableMerchants, changeMerchant, merchantData]);

  return (
    <CurrentMerchantContext.Provider
      value={{
        merchantDisplayId: merchantIdRef.current,
        merchantId: merchantData?.id,
        merchant: merchantData,
        parentMerchant: merchantData?.parentMerchant,
        isLoading: loadingMerchantsList || loadingMerchantData,
        isFetching: fetchingMerchantsList || fetchingMerchantData,
        availableMerchants,
        loadingAvailableMerchants: loadingMerchantsList,
        changeMerchant,
        refetch,
      }}
    >
      {children}
    </CurrentMerchantContext.Provider>
  );
};

function useCurrentMerchant(): CurrentMerchantContextType {
  const context = React.useContext(CurrentMerchantContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentMerchant must be used within a CurrentMerchantProvider',
    );
  }
  return context;
}

const CurrentMerchantRouteElement: FC = () => (
  <CurrentMerchantProvider>
    <Outlet />
  </CurrentMerchantProvider>
);

export {
  CurrentMerchantRouteElement,
  CurrentMerchantProvider,
  useCurrentMerchant,
};
