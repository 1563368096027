export enum UserPermissions {
  ADMIN_USER_MANAGEMENT_READ = 'ADMIN_USER_MANAGEMENT_READ',
  ADMIN_USER_MANAGEMENT_UPDATE = 'ADMIN_USER_MANAGEMENT_UPDATE',
  ADMIN_USER_MANAGEMENT_CREATE = 'ADMIN_USER_MANAGEMENT_CREATE',
  ADMIN_USER_MANAGEMENT_DELETE = 'ADMIN_USER_MANAGEMENT_DELETE',
  ADMIN_SYSTEM_USER_MANAGEMENT_READ = 'ADMIN_SYSTEM_USER_MANAGEMENT_READ',
  ADMIN_SYSTEM_USER_MANAGEMENT_UPDATE = 'ADMIN_SYSTEM_USER_MANAGEMENT_UPDATE',
  ADMIN_SYSTEM_USER_MANAGEMENT_CREATE = 'ADMIN_SYSTEM_USER_MANAGEMENT_CREATE',
  ADMIN_SYSTEM_USER_MANAGEMENT_DELETE = 'ADMIN_SYSTEM_USER_MANAGEMENT_DELETE',
  ADMIN_REPORTING_READ = 'ADMIN_REPORTING_READ',
  ADMIN_MERCHANTS_MANAGEMENT_READ = 'ADMIN_MERCHANTS_MANAGEMENT_READ',
  ADMIN_MERCHANTS_MANAGEMENT_UPDATE = 'ADMIN_MERCHANTS_MANAGEMENT_UPDATE',
  ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE = 'ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE',
  ADMIN_TRANSACTIONS_READ = 'ADMIN_TRANSACTIONS_READ',
  ADMIN_TRANSACTIONS_UPDATE = 'ADMIN_TRANSACTIONS_UPDATE',
  ADMIN_TRANSFERS_READ = 'ADMIN_TRANSFERS_READ',
  ADMIN_ROLE_MANAGEMENT_READ = 'ADMIN_ROLE_MANAGEMENT_READ',
  ADMIN_ROLE_MANAGEMENT_UPDATE = 'ADMIN_ROLE_MANAGEMENT_UPDATE',
  ADMIN_ROLE_MANAGEMENT_CREATE = 'ADMIN_ROLE_MANAGEMENT_CREATE',
  ADMIN_ROLE_MANAGEMENT_DELETE = 'ADMIN_ROLE_MANAGEMENT_DELETE',
  ADMIN_NETWORKS_MANAGEMENT_READ = 'ADMIN_NETWORKS_MANAGEMENT_READ',
  SYSTEM_OWNER = 'SYSTEM_OWNER',
}

export enum MerchantPermissions {
  MERCHANT_USER_MANAGEMENT_READ = 'MERCHANT_USER_MANAGEMENT_READ',
  MERCHANT_USER_MANAGEMENT_UPDATE = 'MERCHANT_USER_MANAGEMENT_UPDATE',
  MERCHANT_USER_MANAGEMENT_CREATE = 'MERCHANT_USER_MANAGEMENT_CREATE',
  MERCHANT_USER_MANAGEMENT_DELETE = 'MERCHANT_USER_MANAGEMENT_DELETE',
  MERCHANT_ROLE_MANAGEMENT_READ = 'MERCHANT_ROLE_MANAGEMENT_READ',
  MERCHANT_ROLE_MANAGEMENT_UPDATE = 'MERCHANT_ROLE_MANAGEMENT_UPDATE',
  MERCHANT_ROLE_MANAGEMENT_CREATE = 'MERCHANT_ROLE_MANAGEMENT_CREATE',
  MERCHANT_ROLE_MANAGEMENT_DELETE = 'MERCHANT_ROLE_MANAGEMENT_DELETE',
  MERCHANT_API_KEYS_MANAGEMENT_READ = 'MERCHANT_API_KEYS_MANAGEMENT_READ',
  MERCHANT_API_KEYS_MANAGEMENT_UPDATE = 'MERCHANT_API_KEYS_MANAGEMENT_UPDATE',
  MERCHANT_API_KEYS_MANAGEMENT_CREATE = 'MERCHANT_API_KEYS_MANAGEMENT_CREATE',
  MERCHANT_API_KEYS_MANAGEMENT_DELETE = 'MERCHANT_API_KEYS_MANAGEMENT_DELETE',
  MERCHANT_REPORTING_READ = 'MERCHANT_REPORTING_READ',
  MERCHANT_TRANSACTIONS_READ = 'MERCHANT_TRANSACTIONS_READ',
  MERCHANT_TRANSFERS_READ = 'MERCHANT_TRANSFERS_READ',
  MERCHANT_WALLET_MANAGEMENT_READ = 'MERCHANT_WALLET_MANAGEMENT_READ',
  MERCHANT_WALLET_MANAGEMENT_UPDATE = 'MERCHANT_WALLET_MANAGEMENT_UPDATE',
  MERCHANT_SETTLEMENT_MANAGEMENT_READ = 'MERCHANT_SETTLEMENT_MANAGEMENT_READ',
  MERCHANT_SETTLEMENT_MANAGEMENT_UPDATE = 'MERCHANT_SETTLEMENT_MANAGEMENT_UPDATE',
  MERCHANT_OWNER = 'MERCHANT_OWNER',
  MERCHANT_PROFILE_UPDATE = 'MERCHANT_PROFILE_UPDATE',
  MERCHANT_ACCEPTED_COINS_READ = 'MERCHANT_ACCEPTED_COINS_READ',
  MERCHANT_ACCEPTED_COINS_UPDATE = 'MERCHANT_ACCEPTED_COINS_UPDATE',
  MERCHANT_STORE_SETTINGS_READ = 'MERCHANT_STORE_SETTINGS_READ',
  MERCHANT_STORE_SETTINGS_UPDATE = 'MERCHANT_STORE_SETTINGS_UPDATE',
  MERCHANT_PAYMENT_WIDGET_READ = 'MERCHANT_PAYMENT_WIDGET_READ',
  MERCHANT_SHOP_DEMO_READ = 'MERCHANT_SHOP_DEMO_READ',
  MERCHANT_CASHBACK_READ = 'MERCHANT_CASHBACK_READ',
  MERCHANT_CASHBACK_UPDATE = 'MERCHANT_CASHBACK_UPDATE',
  MERCHANT_LINKED_READ = 'MERCHANT_LINKED_READ',
  MERCHANT_UNLINK = 'MERCHANT_UNLINK',
  MERCHANT_LINK_CREATE = 'MERCHANT_LINK_CREATE',
  MERCHANT_AUDIT_READ = 'MERCHANT_AUDIT_READ',
  MERCHANT_AUDIT_CREATE = 'MERCHANT_AUDIT_CREATE',
  MERCHANT_AUDIT_TEST = 'MERCHANT_AUDIT_TEST',
  MERCHANT_VERIFICATIONS_READ = 'MERCHANT_VERIFICATIONS_READ',
  MERCHANT_VERIFICATIONS_UPDATE = 'MERCHANT_VERIFICATIONS_UPDATE',
}

export const adminPermissions = [
  UserPermissions.ADMIN_USER_MANAGEMENT_READ,
  UserPermissions.ADMIN_USER_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_USER_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_USER_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_READ,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_REPORTING_READ,
  UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ,
  UserPermissions.ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_TRANSACTIONS_READ,
  UserPermissions.ADMIN_TRANSFERS_READ,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_READ,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_NETWORKS_MANAGEMENT_READ,
  UserPermissions.SYSTEM_OWNER,
];

export const userPermissions = [
  UserPermissions.ADMIN_USER_MANAGEMENT_READ,
  UserPermissions.ADMIN_USER_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_USER_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_USER_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_READ,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_REPORTING_READ,
  UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ,
  UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_TRANSACTIONS_READ,
  UserPermissions.ADMIN_TRANSACTIONS_UPDATE,
  UserPermissions.ADMIN_TRANSFERS_READ,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_READ,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_UPDATE,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_CREATE,
  UserPermissions.ADMIN_ROLE_MANAGEMENT_DELETE,
  UserPermissions.ADMIN_NETWORKS_MANAGEMENT_READ,
  UserPermissions.SYSTEM_OWNER,
];

export const merchantPermissions = [
  MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ,
  MerchantPermissions.MERCHANT_USER_MANAGEMENT_UPDATE,
  MerchantPermissions.MERCHANT_USER_MANAGEMENT_CREATE,
  MerchantPermissions.MERCHANT_USER_MANAGEMENT_DELETE,
  MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_READ,
  MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_UPDATE,
  MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_CREATE,
  MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_DELETE,
  MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_READ,
  MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_UPDATE,
  MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_CREATE,
  MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_DELETE,
  MerchantPermissions.MERCHANT_REPORTING_READ,
  MerchantPermissions.MERCHANT_TRANSACTIONS_READ,
  MerchantPermissions.MERCHANT_TRANSFERS_READ,
  MerchantPermissions.MERCHANT_WALLET_MANAGEMENT_READ,
  MerchantPermissions.MERCHANT_WALLET_MANAGEMENT_UPDATE,
  MerchantPermissions.MERCHANT_SETTLEMENT_MANAGEMENT_READ,
  MerchantPermissions.MERCHANT_SETTLEMENT_MANAGEMENT_UPDATE,
  MerchantPermissions.MERCHANT_OWNER,
  MerchantPermissions.MERCHANT_PROFILE_UPDATE,
  MerchantPermissions.MERCHANT_ACCEPTED_COINS_READ,
  MerchantPermissions.MERCHANT_ACCEPTED_COINS_UPDATE,
  MerchantPermissions.MERCHANT_STORE_SETTINGS_READ,
  MerchantPermissions.MERCHANT_STORE_SETTINGS_UPDATE,
  MerchantPermissions.MERCHANT_PAYMENT_WIDGET_READ,
  MerchantPermissions.MERCHANT_SHOP_DEMO_READ,
  MerchantPermissions.MERCHANT_CASHBACK_READ,
  MerchantPermissions.MERCHANT_CASHBACK_UPDATE,
  MerchantPermissions.MERCHANT_LINKED_READ,
  MerchantPermissions.MERCHANT_UNLINK,
  MerchantPermissions.MERCHANT_LINK_CREATE,
  MerchantPermissions.MERCHANT_AUDIT_READ,
  MerchantPermissions.MERCHANT_AUDIT_CREATE,
  MerchantPermissions.MERCHANT_AUDIT_TEST,
  MerchantPermissions.MERCHANT_VERIFICATIONS_READ,
  MerchantPermissions.MERCHANT_VERIFICATIONS_UPDATE,
];
