import React from 'react';

import {
  Box,
  Link,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { AppButton } from '@/elements';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

/**
 * Modal component for accepting cookies
 * @component
 * @example
 * <CookieModal />.
 */
export const CookieModal: React.FC = () => {
  const { onClose } = useDisclosure();
  const { value, setItem } = useLocalStorage<string>(
    StorageKeysEnum.isCookieAccepted,
  );
  const { t } = useTranslation();

  const acceptCookie = () => {
    setItem('true');
  };

  if (value) {
    return null;
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={!value}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf='flex-end'
        borderRadius='20px'
        height={{ base: '260px', md: '100px' }}
        marginBottom='1.75rem'
        marginTop='3.75rem'
        maxWidth={{ base: '325px', md: '700px', lg: '780px' }}
      >
        <ModalBody
          borderRadius='20px'
          display='flex'
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          sx={{
            alignItems: 'center',
            background: 'app.white',
            padding: { base: '20px', md: '20px 30px' },
            width: '100%',
          }}
        >
          <Box maxW={{ base: '70px', md: '60px' }} w='100%'>
            <Image alt='cookies' src='/icons/cookies.png' />
          </Box>
          <Box
            fontSize={{ base: '14px', md: '16px' }}
            maxW={{ md: '390px', lg: '480px' }}
            textAlign={{ base: 'center', md: 'left' }}
            w='100%'
          >
            {t('cookieModal.text')}{' '}
            <Link
              _focus={{ boxShadow: 'none' }}
              as={RouterLink}
              color='app.linkBlue'
              rel='noopener noreferrer'
              target='_blank'
              to='/documents/termsOfUse.pdf'
            >
              {t('cookieModal.termsOfUse')}
            </Link>{' '}
            {t('cookieModal.and')}{' '}
            <Link
              _focus={{ boxShadow: 'none' }}
              as={RouterLink}
              color='app.linkBlue'
              rel='noopener noreferrer'
              target='_blank'
              to='/documents/privacyPolicy.pdf'
            >
              {t('cookieModal.privacyPolicy')}
            </Link>
            .
          </Box>

          <AppButton onClick={acceptCookie} size='md' variant='primary'>
            {t('cookieModal.acceptButton')}
          </AppButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
