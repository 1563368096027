import React, { useMemo } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/icons/cross.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';

import { SidebarMobileWrapper } from '@/components/Sidebar/sidebar-mobile-wrapper';

import { useAppDispatch } from '@/store/hooks';
import { changeNavState } from '@/store/slices/headerSlice';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';

import { useCurrentMerchant } from '@/context/merchant.context';

import { useSidebarLinks } from '@/hooks/useSidebarLinks';

import { ISidebarLink, ISidebarSubLink, sidebarLinks } from '../data';

/**
 * Mobile version of sidebar component
 * @component
 * @example
 * <SidebarMobile />
 */
export const SidebarMobile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { merchant } = useCurrentMerchant();
  const location = useLocation();
  const theme = useTheme();
  const rawLinks = useMemo(() => sidebarLinks(), []);
  const { menu } = useSidebarLinks(rawLinks);

  const isCashBack = (label: string) => {
    const isCashBackPages =
      label === 'common.dashboardNav.cashback' ||
      label === 'common.dashboardNav.viewCashback';

    return (
      (merchant && merchant.cashbackPercent <= 0 && isCashBackPages) ||
      (!merchant && isCashBackPages)
    );
  };

  const isLinkedMerchant = (label: string) => {
    const isLinkedPage = label === 'common.dashboardNav.linkedMerchant';

    return (
      (merchant && merchant.parentMerchantId && isLinkedPage) ||
      (!merchant && isLinkedPage)
    );
  };

  const getLeftGapForLink = (navLink: ISidebarLink) => {
    if (navLink.subLinks) {
      return '32px';
    }
    if (navLink.link === AppRoutesEnum.summary) {
      return '16px';
    }
    return '20px';
  };

  const getSimpleLinkStyles = (navLink: ISidebarLink) => {
    const isActive = navLink.link === location.pathname;
    return {
      display: 'block',
      fontSize: '23px',
      fontWeight: isActive ? '700' : '400',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: getLeftGapForLink(navLink),
      color: isActive ? theme.colors.app.pink : theme.colors.app.borderGray,
      background: isActive ? theme.colors.app.bgLightDark : 'transparent',
      borderLeft: isActive ? '6px solid' : '0',
      borderColor: isActive ? theme.colors.app.pink : 'transparent',
    };
  };

  const simpleLink = (navLink: ISidebarLink) => (
    <Box
      key={navLink.link}
      sx={{
        '>div:not(:last-child)': {
          mb: '10px',
        },
      }}
    >
      <Link
        as={NavLink}
        onClick={() => dispatch(changeNavState())}
        sx={getSimpleLinkStyles(navLink)}
        to={navLink.link as string}
      >
        {t(navLink.label)}
      </Link>
    </Box>
  );

  const linkWithLockIcon = (navLink: ISidebarLink) => (
    <Flex
      key={navLink.link}
      sx={{
        ...getSimpleLinkStyles(navLink),
        display: 'flex',
        width: '100%',
        alignItems:
          navLink.link === location.pathname ? 'flex-start' : 'center',
        '>div:not(:last-child)': {
          mb: '10px',
        },
      }}
    >
      <Link
        as={NavLink}
        onClick={() => dispatch(changeNavState())}
        to={navLink.link as string}
      >
        {t(navLink.label)}
      </Link>
      <Box ml='6px' mt={navLink.link === location.pathname ? '7px' : '0'}>
        <LockIcon height='20px' width='20px' />
      </Box>
    </Flex>
  );

  const linkWithSubLinks = (navLink: ISidebarLink) => (
    <AccordionItem
      borderBottomWidth='1px'
      borderTopWidth='0'
      key={navLink.label}
    >
      <h2>
        <AccordionButton
          sx={{
            color: 'app.white',
            pr: '10px',
            display: 'flex',
            fontSize: '23px',
            justifyContent: 'space-between',
            _focus: {
              boxShadow: 'none',
            },
          }}
        >
          <Box color='app.white'>{t(navLink.label)}</Box>

          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel color='app.white' pl='lg'>
        {navLink.subLinks && (
          <Box my='5px'>
            {navLink.subLinks.map((subLink: ISidebarSubLink) => {
              if (isCashBack(subLink.label) || isLinkedMerchant(subLink.label))
                return;

              return subLink.isKycRequired && merchant && !merchant.kyc
                ? linkWithLockIcon(subLink)
                : simpleLink(subLink);
            })}
          </Box>
        )}
      </AccordionPanel>
    </AccordionItem>
  );

  return (
    <SidebarMobileWrapper>
      <Accordion
        allowToggle
        maxH={{ base: '600px', sm: '800px' }}
        overflowX='hidden'
        overflowY='auto'
        pb='30px'
        pr='15px'
        w='100%'
      >
        {menu.map((navLink: ISidebarLink) => {
          return navLink.link ? simpleLink(navLink) : linkWithSubLinks(navLink);
        })}
      </Accordion>

      <Box
        left='30px'
        onClick={() => dispatch(changeNavState())}
        pos='absolute'
        top='30px'
      >
        <CloseIcon />
      </Box>
    </SidebarMobileWrapper>
  );
};
