import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { components, OptionProps } from 'react-select';

import { ReactComponent as RadioEmpty } from '@/assets/icons/radio-empty.svg';
import { ReactComponent as RadioSelected } from '@/assets/icons/radio-selected.svg';

const InputOption = (props: OptionProps) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props}>
      <Flex alignItems='center' justifyContent='space-between'>
        <Box flex='1 1 100%' maxWidth='100%' overflow='hidden'>
          {children}
        </Box>
        <Box flex='0 0 auto' ml='sm'>
          {isSelected ? <RadioSelected /> : <RadioEmpty />}
        </Box>
      </Flex>
    </components.Option>
  );
};

export default InputOption;
