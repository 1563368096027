import React, { FC } from 'react';

import { useTheme } from '@chakra-ui/react';
import { MdCancel } from 'react-icons/md';
import { components, ClearIndicatorProps } from 'react-select';

const ClearIndicator: FC<ClearIndicatorProps> = (props) => {
  const theme = useTheme();

  return (
    <components.ClearIndicator {...props}>
      <MdCancel
        color={theme.colors.app.textGray}
        cursor='pointer'
        size='1.375rem'
      />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
