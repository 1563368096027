import React from 'react';

import { Box, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { ReactComponent as CloseIcon } from '@/assets/icons/cross.svg';

import { mobileNavLinkStyles } from '@/styles/NavLink';

import { SidebarMobileWrapper } from '@/components/Sidebar/sidebar-mobile-wrapper';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changeNavState } from '@/store/slices/headerSlice';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';

import { homePageNavLinks, isRelativeLink } from '../data/links';

/**
 * Mobile navigation component with nav-links for Home page
 * @component
 * @example
 * <HomePageMobileNav />.
 */
export const HomePageMobileNav: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isHomePage = location.pathname === AppRoutesEnum.main;

  const { isStickyHeader } = useAppSelector((state) => state.header);

  return (
    <SidebarMobileWrapper>
      {homePageNavLinks.map((navLink) => {
        return (
          <React.Fragment key={navLink.link}>
            {isHomePage && !isRelativeLink(navLink.link) ? (
              <Link
                activeClass='active-link--mobile'
                as={ScrollLink}
                duration={500}
                onClick={() => dispatch(changeNavState())}
                smooth={true}
                spy={true}
                sx={{
                  ...mobileNavLinkStyles,
                  color: isStickyHeader ? 'app.white' : 'app.black',
                  _last: {
                    borderBottom: '0',
                  },
                }}
                to={navLink.link}
              >
                {t(navLink.label)}
              </Link>
            ) : (
              <Link
                as={NavLink}
                key={navLink.link}
                onClick={() => dispatch(changeNavState())}
                sx={{
                  ...mobileNavLinkStyles,
                  color: isStickyHeader ? 'app.white' : 'app.black',
                  _hover: {
                    textDecoration: 'none',
                  },
                }}
                to={
                  isRelativeLink(navLink.link)
                    ? navLink.link
                    : `/#${navLink.link}`
                }
              >
                {t(navLink.label)}
              </Link>
            )}
          </React.Fragment>
        );
      })}

      <Box
        left='30px'
        onClick={() => dispatch(changeNavState())}
        pos='absolute'
        top='30px'
      >
        <CloseIcon />
      </Box>
    </SidebarMobileWrapper>
  );
};
