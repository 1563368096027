import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as LinkedinIcon } from '@/assets/icons/linkedin.svg';
import { AppButton } from '@/elements';

import { ButtonOverrideStyles } from '@/styles/Button';

import { SignUpPage, LoginPage } from '@/routes/public';

import { DesktopMenu } from '@/components/Header/desktop-menu';
import { WebPImage } from '@/components/WebPImage';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changeStickyHeader } from '@/store/slices/headerSlice';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';
import { MediaQueryAmountsEnum } from '@/enum/mediaQueryAmounts.enum';

import { useAuth } from '@/context/auth.context';

import { HomePageDesktopNav } from '../HomePage/desktop-nav';

import { BurgerMenu } from './burger-menu';
import { SelectMerchantModal } from './select-merchant-modal';

/**
 * Header component
 * @component
 * @example
 * <Header />.
 */
export const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isHomePage = location.pathname === AppRoutesEnum.main;
  const isSignUpPage = location.pathname === AppRoutesEnum.signup;

  const { isStickyHeader, showSignInLink } = useAppSelector(
    (state) => state.header,
  );

  useEffect(() => {
    dispatch(changeStickyHeader(!isHomePage));
  }, [dispatch, isHomePage]);

  const [isMobile, notADesktop] = useMediaQuery([
    MediaQueryAmountsEnum.isMobile,
    MediaQueryAmountsEnum.isNotADesktop,
  ]);

  const navigateToHomePage = () => {
    if (!isHomePage) {
      navigate(AppRoutesEnum.main);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const makeHeaderSticky = () => {
    if (window.scrollY > 120) {
      dispatch(changeStickyHeader(true));
    } else if (!isHomePage) {
      dispatch(changeStickyHeader(true));
    } else {
      dispatch(changeStickyHeader(false));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', makeHeaderSticky);

    return () => {
      window.removeEventListener('scroll', makeHeaderSticky);
    };
  });

  useEffect(() => {
    if (!isLoggedIn) {
      SignUpPage.preload();
      LoginPage.preload();
    }
  }, [isLoggedIn]);

  const getHeaderBgColor = () => {
    if (!isHomePage) {
      return 'app.white';
    } else if (isHomePage && isStickyHeader) {
      return 'app.white';
    } else if (isHomePage && !isStickyHeader) {
      return 'transparent';
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: isStickyHeader ? 'fixed' : 'absolute',
          zIndex: '999',
          top: 0,
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            backgroundColor: getHeaderBgColor(),
            boxShadow: isStickyHeader
              ? '0 3px 5px rgba(57, 63, 72, 0.3)'
              : 'none',
          }}
        >
          <Container
            borderBottom={{ base: 'none', xl: '2px solid' }}
            borderColor={{ base: 'transparent', xl: 'app.white' }}
            maxW={isHomePage ? '1440px' : '100%'}
            p={{
              base: '15px',
              md: '20px 40px',
              lg: isStickyHeader ? '30px 40px' : '40px',
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              w='100%'
            >
              {!notADesktop && (
                <Flex
                  align={{ base: 'flex-start', md: 'flex-end' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Button
                    as={Link}
                    onClick={navigateToHomePage}
                    pr={{ base: '0', md: 'md' }}
                    to={AppRoutesEnum.main}
                    {...ButtonOverrideStyles}
                  >
                    <WebPImage
                      alt='Remuno logo'
                      commonPath={
                        isStickyHeader
                          ? '/images/logo.svg'
                          : '/images/RemunoLogoWhite.png'
                      }
                      sx={{
                        scale: isMobile ? '0.8' : '1',
                        width: '206px',
                      }}
                      webpPath={
                        isStickyHeader
                          ? '/images/webp/logo.webp'
                          : '/images/webp/RemunoLogoWhite.webp'
                      }
                    />
                  </Button>
                </Flex>
              )}

              <Flex
                align='center'
                justify={
                  isHomePage || notADesktop ? 'space-between' : 'flex-end'
                }
                sx={{
                  width: '100%',
                  maxWidth: isLoggedIn
                    ? { base: '100%', lg: '600px' }
                    : { base: '100%', lg: '740px', xl: '790px' },
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '100%',
                  color: 'app.lightBlack',
                }}
              >
                {!notADesktop && isHomePage && (
                  <HomePageDesktopNav isStickyHeader={isStickyHeader} />
                )}
                {notADesktop && <BurgerMenu isStickyHeader={isStickyHeader} />}

                {notADesktop && (
                  <Button
                    as={Link}
                    onClick={navigateToHomePage}
                    to={AppRoutesEnum.main}
                    {...ButtonOverrideStyles}
                  >
                    <WebPImage
                      alt='Remuno logo'
                      commonPath={
                        isStickyHeader
                          ? '/images/webp/logo.webp'
                          : '/images/RemunoLogoWhite.png'
                      }
                      sx={{
                        scale: isMobile ? '0.8' : '1',
                        width: {
                          base: '130px',
                          md: '150px',
                        },
                      }}
                      webpPath={
                        isStickyHeader
                          ? '/images/webp/logo.webp'
                          : '/images/RemunoLogoWhite.png'
                      }
                    />
                  </Button>
                )}

                {isLoggedIn ? (
                  <DesktopMenu onOpen={onOpen} />
                ) : (
                  <Flex direction='row'>
                    {!isSignUpPage && (
                      <>
                        {isStickyHeader ? (
                          <AppButton
                            as={Link}
                            to={AppRoutesEnum.signup}
                            variant='primary'
                          >
                            {t('common.signUp')}
                          </AppButton>
                        ) : (
                          <AppButton
                            as={Link}
                            to={AppRoutesEnum.signup}
                            variant='secondary'
                          >
                            {t('common.signUp')}
                          </AppButton>
                        )}
                      </>
                    )}

                    {showSignInLink && (
                      <AppButton
                        as={Link}
                        borderColor={isStickyHeader ? 'app.black' : 'app.white'}
                        color={isStickyHeader ? 'app.black' : 'app.white'}
                        ml={{ base: 'sm', md: 'lg' }}
                        to={AppRoutesEnum.login}
                        variant='outlined'
                      >
                        {t('common.signIn')}
                      </AppButton>
                    )}
                    <Flex align='center' ml={{ base: 'sm', md: 'lg' }}>
                      <a
                        href='https://www.linkedin.com/company/remuno/'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <LinkedinIcon
                          fill={
                            isStickyHeader
                              ? theme.colors.app.black
                              : theme.colors.app.white
                          }
                        />
                      </a>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Container>
        </Flex>

        <SelectMerchantModal isOpen={isOpen} onClose={onClose} />
      </Box>
    </>
  );
};
