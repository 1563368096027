import React from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from '@/AppLayout';

import {
  MerchantRoutes,
  PrivateRoutes,
  SysAdminRoutes,
} from '@/routes/private';
import { page404, PublicRoutes } from '@/routes/public';

import { AuthGuard } from '@/components/Guards/AuthGuard';
import { MerchantGuard } from '@/components/Guards/MerchantGuard';

import { useAppSelector } from '@/store/hooks';

import { MediaQueryAmountsEnum } from '@/enum/mediaQueryAmounts.enum';
import { MerchantPermissions, UserPermissions } from '@/enum/permission.enum';

function App() {
  const isActiveNav = useAppSelector((state) => state.header.isActiveNav);
  const [notADesktop] = useMediaQuery(MediaQueryAmountsEnum.isNotADesktop);

  // Prevent page scrolling when mobile navigation is open
  if (isActiveNav && notADesktop) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {PublicRoutes.map((route) => (
          <Route element={route.element} key={route.path} path={route.path} />
        ))}
        {PrivateRoutes.map((route) => (
          <Route
            element={
              <AuthGuard permissions={route.permissions as UserPermissions[]} />
            }
            key={route.path}
          >
            <Route element={route.element} path={route.path} />
          </Route>
        ))}
        <Route element={<AuthGuard />}>
          <Route path='/merchant/:merchantDisplayId'>
            {MerchantRoutes.map((route) => (
              <Route
                element={
                  <MerchantGuard
                    permissions={route.permissions as MerchantPermissions[]}
                  />
                }
                key={route.path}
              >
                <Route element={route.element} path={route.path} />
              </Route>
            ))}
            <Route
              element={page404.element}
              key={page404.path}
              path={page404.path}
            />
          </Route>
        </Route>
        <Route path='/admin'>
          {SysAdminRoutes.map((route) => (
            <Route
              element={
                <AuthGuard
                  permissions={route.permissions as UserPermissions[]}
                />
              }
              key={route.path}
            >
              <Route element={route.element} path={route.path} />
            </Route>
          ))}
        </Route>
        <Route
          element={page404.element}
          key={page404.path}
          path={page404.path}
        />
      </Route>
    </Routes>
  );
}

export default App;
