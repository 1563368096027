export const colors = {
  blue: {
    400: '#3182ce',
    300: '#3182ce',
    200: '#3182ce',
    100: '#c6ceff',
  },
  app: {
    white: '#FFFFFF',
    lightWhite: '#FBFBFB',
    black: '#000000',
    bgDark: '#121212',
    bgLightDark: '#1A1A1A',
    pink: '#FD108A',
    neutralPink: '#E2368F',
    neutralGray: '#F5F5F5',
    borderGray: '#EDEDED',
    widgetRed: '#EB3D3D',
    darkRed: '#FAE0E0',
    textGray: '#999999',
    widgetGray: '#757575',
    lightGray: '#CBD5E0',
    lightPink: '#EEC8DA',
    appBlack: '#3C3C3B',
    lightBlack: '#555555',
    lightBlue: '#c6ceff',
    widgetBlue: '#DDF1FD',
    blue: '#3182ce',
    lighterRed: '#EB3D3D50',
    red: '#ff0000',
    lighterGreen: '#0CB04E50',
    lightGreen: '#0CB04E',
    green: '#38a169',
    bgGreen: '#e4fae0',
    textGreen: '#43cf2e',
    lighterOrange: '#FD9D4950',
    neutralOrange: '#FD9D49',
    textDark: '#0F0F0F',
    linkBlue: '#64acff',
    bgGray: 'rgba(244, 244, 244, 0.85)',
    gray: '#A7A7A7',
    bgBoldGray: 'rgba(116, 116, 128, 0.08)',
    boldGray: '#747480',
    tableGrayBg: '#f8f8f8',
    bgLightGray: '#949494',
  },
};

export default colors;
