import React from 'react';

import { Box, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';

import { homePageNavLinks, isRelativeLink } from '../data/links';

interface HomePageDesktopNav {
  isStickyHeader: boolean;
}

/**
 * Desktop navigation component with nav-links for Home page
 * @component
 * @param    {HomePageDesktopNav}   props     Component props
 * @param    {boolean}  props.isStickyHeader  Is header sticky or not
 * @example
 * <HomePageDesktopNav/>
 */
export const HomePageDesktopNav: React.FC<HomePageDesktopNav> = ({
  isStickyHeader,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isHomePage = location.pathname === AppRoutesEnum.main;

  return (
    <>
      {homePageNavLinks.map((navLink) => {
        return (
          <Box key={navLink.link}>
            {isHomePage && !isRelativeLink(navLink.link) ? (
              <Link
                activeClass='active-link--desktop'
                as={ScrollLink}
                duration={500}
                smooth={true}
                spy={true}
                sx={{
                  _hover: {
                    textDecoration: 'none',
                  },
                  color: isStickyHeader ? 'app.black' : 'app.white',
                }}
                to={navLink.link}
              >
                {t(navLink.label)}
              </Link>
            ) : (
              <Link
                as={NavLink}
                key={navLink.link}
                sx={{
                  color:
                    location.pathname === navLink.link ? 'app.pink' : 'inherit',
                  _focus: {
                    outline: 'none',
                  },
                  _hover: {
                    textDecoration: 'none',
                  },
                }}
                to={
                  isRelativeLink(navLink.link)
                    ? navLink.link
                    : `/#${navLink.link}`
                }
              >
                {t(navLink.label)}
              </Link>
            )}
          </Box>
        );
      })}
    </>
  );
};
