import { extendTheme } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';

import '@fontsource/urbanist/400.css';
import '@fontsource/urbanist/500.css';
import '@fontsource/urbanist/600.css';
import '@fontsource/urbanist/700.css';

import breakpoints from '@/utils/extendTheme/foundations/breakpoints';
import colors from '@/utils/extendTheme/foundations/colors';
import fonts from '@/utils/extendTheme/foundations/fonts';
import radius from '@/utils/extendTheme/foundations/radius';
import shadows from '@/utils/extendTheme/foundations/shadows';
import sizes from '@/utils/extendTheme/foundations/sizes';
import space from '@/utils/extendTheme/foundations/space';

import components from './foundations/components';

const styles: Styles = {
  global: () => ({
    html: {
      width: '100%',
      height: '100%',
    },

    body: {
      width: '100%',
      height: '100%',
    },

    '#root': {
      width: '100%',
      height: '100%',
    },

    '*': {
      boxSizing: 'border-box',
    },
  }),
};

const theme = extendTheme({
  fonts,
  colors,
  shadows,
  sizes,
  space,
  radii: radius,
  breakpoints,
  styles,
  components,
});

export default theme;
