import React, { lazy } from 'react';

import { lazyWithPreload } from 'react-lazy-with-preload';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';

import { IRoutes } from './type';

const MainPage = lazy(() => import('../pages/main'));
const ChangedEmailPage = lazy(() => import('../pages/changed-email'));
const ExpiredTokenPage = lazy(() => import('../pages/expired-token'));
const WrongTokenPage = lazy(() => import('../pages/wrong-token'));
export const SignUpPage = lazyWithPreload(() => import('../pages/signup'));
export const LoginPage = lazyWithPreload(() => import('../pages/login'));
const KYCPage = lazy(() => import('../pages/kyc'));
const ConfirmEmailPage = lazy(() => import('../pages/confirm-change-email'));
const RequestResetPasswordPage = lazy(
  () => import('../pages/request-reset-password'),
);
const ForgotPasswordPage = lazy(() => import('../pages/forgot-password'));
const KYCFeedbackPage = lazy(() => import('../pages/KYCFeedback'));
const ThankYouPage = lazy(() => import('../pages/thank-you'));
const AlreadyConfirmedPage = lazy(() => import('../pages/already-confirmed'));
const InviteUserPage = lazy(() => import('../pages/invite-user'));
const Page404 = lazy(() => import('../pages/404'));

export const page404 = {
  path: AppRoutesEnum.page404,
  element: <Page404 />,
};

export const PublicRoutes: IRoutes[] = [
  {
    path: AppRoutesEnum.main,
    element: <MainPage />,
  },
  {
    path: AppRoutesEnum.signup,
    element: <SignUpPage />,
  },
  {
    path: AppRoutesEnum.login,
    element: <LoginPage />,
  },
  {
    path: AppRoutesEnum.merchantVerify,
    element: <KYCPage />,
  },
  {
    path: AppRoutesEnum.requestResetPassword,
    element: <RequestResetPasswordPage />,
  },
  {
    path: AppRoutesEnum.forgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    path: AppRoutesEnum.kycFeedback,
    element: <KYCFeedbackPage />,
  },
  {
    path: AppRoutesEnum.thankYou,
    element: <ThankYouPage />,
  },
  {
    path: AppRoutesEnum.expiredToken,
    element: <ExpiredTokenPage />,
  },
  {
    path: AppRoutesEnum.wrongToken,
    element: <WrongTokenPage />,
  },
  {
    path: AppRoutesEnum.changedEmail,
    element: <ChangedEmailPage />,
  },
  {
    path: AppRoutesEnum.alreadyConfirmed,
    element: <AlreadyConfirmedPage />,
  },
  {
    path: AppRoutesEnum.handleActionInvite,
    element: <InviteUserPage />,
  },
  {
    path: AppRoutesEnum.confirmEmail,
    element: <ConfirmEmailPage />,
  },
];
