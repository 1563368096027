import { useEffect, useState } from 'react';

type DocumentWithHidden = Document & {
  msHidden?: boolean;
  webkitHidden?: boolean;
};
const getVisibilityChangeEventState = () => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return { type: 'visibilitychange', hidden: 'hidden' };
  } else if (
    typeof (document as DocumentWithHidden)?.msHidden !== 'undefined'
  ) {
    return { type: 'msvisibilitychange', hidden: 'msHidden' };
  } else if (
    typeof (document as DocumentWithHidden)?.webkitHidden !== 'undefined'
  ) {
    return { type: 'webkitvisibilitychange', hidden: 'webkitHidden' };
  }
  return { type: 'visibilitychange', hidden: 'hidden' };
};

const visibilityChange = getVisibilityChangeEventState();

const useTabVisibility = () => {
  const [isHidden, setIsHidden] = useState(
    document[visibilityChange.hidden as keyof Document],
  );

  useEffect(() => {
    const handler = () =>
      setIsHidden(document[visibilityChange.hidden as keyof Document]);
    window.addEventListener(visibilityChange.type, handler);

    return () => {
      window.removeEventListener(visibilityChange.type, handler);
    };
  }, []);

  return isHidden;
};
export default useTabVisibility;
