/**
 * Function that checks if the size of the files in the array is too large
 * @function
 * @param {Array<File>} files - Array of files
 * @return {boolean} - Is file valid or not
 */
export function checkIfFilesAreTooBig(files?: [File]): boolean {
  let valid = true;
  if (files) {
    // eslint-disable-next-line array-callback-return
    Array.from(files).map((file) => {
      const size = file.size / 1024 / 1024;
      // file size should not exceed 4MB
      if (size > 4) {
        valid = false;
      }
    });
  }
  return valid;
}

/**
 * Function to rename file name to needed format
 * @function
 * @param {string} newName - Part of new file name
 * @param {string} fileName - Current file name
 * @return {boolean} - New file name
 */
export const renameFile = (newName: string, fileName: string) => {
  const parts = fileName.split('.');
  const ext = parts[parts.length - 1];
  return `${newName}.${ext}`;
};
