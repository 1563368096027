import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changeNavState } from '@/store/slices/headerSlice';

/**
 * Route change watcher component for watching on router change
 * @component
 * @param    {React.ReactNode}  children  All our routes
 * @example
 * <RouteChangeWatcher>
 *   <Routes>
 *     <Route path="/" element={<MainPage/>}/>
 *   </Routes>
 * </RouteChangeWatcher>
 */
export const RouteChangeWatcher: React.FC = ({ children }) => {
  const isActiveNav = useAppSelector((state) => state.header.isActiveNav);
  const dispatch = useAppDispatch();
  const location = useLocation();

  //Close burger menu during route change
  useEffect(() => {
    if (isActiveNav) {
      dispatch(changeNavState());
    }
    // eslint-disable-next-line
    }, [location.pathname]);

  //Scroll page to top during route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return <>{children}</>;
};
