import React, { FC } from 'react';

import { Box, BoxProps, Flex, Tooltip } from '@chakra-ui/react';

import CopyText from '@/components/CopyText';

export const Snippet: FC<BoxProps & { text: string; label: string }> = ({
  text,
  label,
  ...rest
}) => (
  <Flex
    align='center'
    bg='app.neutralGray'
    borderRadius='4px'
    pl='md'
    pr='xs'
    py='sm'
    {...rest}
  >
    <Box wordBreak='break-word'>{text}</Box>

    <Tooltip fontSize='md' label={label} placement='auto' shouldWrapChildren>
      <CopyText data={text} />
    </Tooltip>
  </Flex>
);
