import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { useAppSelector } from '@/store/hooks';

/**
 * Wrapper component for mobile version of sidebar
 * @component
 * @param    {React.ReactNode}  children  List of sidebar links
 * @example
 * <SidebarMobileWrapper>
 *   ...sidebar links
 * </SidebarMobileWrapper>
 */
export const SidebarMobileWrapper: React.FC = ({ children }) => {
  const { isStickyHeader } = useAppSelector((state) => state.header);
  return (
    <Box
      h='fit-content'
      p='8px'
      pos='fixed'
      right={0}
      top={0}
      w='100%'
      zIndex='1000'
    >
      <Box
        backgroundColor={isStickyHeader ? 'app.black' : 'app.white'}
        borderRadius='36px'
        maxH='100vh'
        w='100%'
      >
        <Flex
          direction='column'
          h='100%'
          justifyContent='center'
          p='55px 30px 25px 45px'
        >
          {children}
        </Flex>
      </Box>
    </Box>
  );
};
