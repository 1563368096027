import { useMemo } from 'react';

import {
  containNumberRegexp,
  lowerCaseRegexp,
  neededLengthRegexp,
  specialSymbolRegexp,
  upperCaseRegexp,
} from '@/utils/regexp/validations';

function usePasswordStrength({ password }: { password: string }) {
  const passwordValidation = useMemo(() => {
    const strengthRules = {
      hasLower: false,
      hasUpper: false,
      hasNumber: false,
      hasSpecSymbol: false,
      hasNeededLength: false,
    };

    strengthRules.hasLower = !!password.match(lowerCaseRegexp);
    strengthRules.hasUpper = !!password.match(upperCaseRegexp);
    strengthRules.hasNumber = !!password.match(containNumberRegexp);
    strengthRules.hasSpecSymbol = !!password.match(specialSymbolRegexp);
    strengthRules.hasNeededLength = !!password.match(neededLengthRegexp);

    return strengthRules;
  }, [password]);

  const maxStrength = useMemo(
    () => Object.keys(passwordValidation).reduce((a) => a + 1, 0),
    [passwordValidation],
  );

  const strength = useMemo(
    () =>
      Object.values(passwordValidation).reduce(
        (a, item) => a + Number(item),
        0,
      ),
    [passwordValidation],
  );

  return {
    strength,
    maxStrength,
  };
}

export default usePasswordStrength;
