import { createSlice } from '@reduxjs/toolkit';

const headerSlice = createSlice({
  name: 'header',
  initialState: {
    isActiveNav: false,
    isStickyHeader: false,
    showSignInLink: true,
  },
  reducers: {
    changeNavState(state) {
      state.isActiveNav = !state.isActiveNav;
    },
    changeStickyHeader(state, action) {
      state.isStickyHeader = action.payload;
    },
    toggleSignInLink(state, action) {
      state.showSignInLink = action.payload;
    },
  },
});

export default headerSlice.reducer;
export const { changeNavState } = headerSlice.actions;
export const { changeStickyHeader } = headerSlice.actions;
export const { toggleSignInLink } = headerSlice.actions;
