import { TFunction } from 'i18next';
import * as yup from 'yup';

export const twoFactorCodeSchema = (t: TFunction) => {
  return yup
    .object({
      code: yup.string().required(
        t('validationErrors.requiredField', {
          name: t('login.twoFactorModal.label'),
        }),
      ),
    })
    .required();
};
