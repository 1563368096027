import React, { createContext, useEffect, useState } from 'react';

import {
  useCloseBannerMutation,
  useGetBannersQuery,
} from '@/services/api.service';

import { useCurrentMerchant } from './merchant.context';

export type OptionalKeys<
  Obj extends Record<string, any>,
  Key extends keyof Obj,
> = {
  [key in keyof Obj]: key extends Key ? Obj[key] | undefined : Obj[key];
};

export enum BannerSeverity {
  warnings = 'warnings',
  mandatoryAction = 'mandatoryAction',
  info = 'information',
  success = 'success',
}

export interface IBanner {
  createdAt: string;
  id: string;
  bannerId: string;
  banner: {
    bannerName: string;
    canDismiss: boolean;
    createdAt: string;
    id: string;
    severityType: BannerSeverity;
    updatedAt: string;
  };
}

export type BannerContextType = {
  banners: IBanner[];
  removeBanner: (id: string) => void;
};

const BannerContext = createContext<BannerContextType>({
  banners: [],
  removeBanner: () => {
    throw 'Not implemented';
  },
});

const typeSortValues: Record<BannerSeverity, number> = {
  [BannerSeverity.mandatoryAction]: 10,
  [BannerSeverity.warnings]: 5,
  [BannerSeverity.info]: 3,
  [BannerSeverity.success]: 1,
};

export const severityLabelMap: Record<BannerSeverity, string> = {
  [BannerSeverity.mandatoryAction]: 'mandatoryAction',
  [BannerSeverity.warnings]: 'warnings',
  [BannerSeverity.info]: 'information',
  [BannerSeverity.success]: 'success',
};

const getBannerSortValue = (banner: IBanner): number => {
  let total = 0;
  if (!banner.banner.canDismiss) total += 100;

  total += typeSortValues[banner.banner.severityType];

  return total;
};

const sortBanners = (banners: IBanner[]): IBanner[] => {
  return [...banners].sort(
    (a, b) => getBannerSortValue(b) - getBannerSortValue(a),
  );
};

export const BannerProvider: React.FC<{
  children?: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [banners, setBanners] = useState<IBanner[]>([]);
  const { merchant } = useCurrentMerchant();

  const { data: dataBanners, isLoading } = useGetBannersQuery(
    { merchantId: merchant?.id || '' },
    {
      skip: !merchant?.id,
    },
  );

  const [closeBanner] = useCloseBannerMutation();

  const removeBanner = (bannerid: string) => {
    if (merchant) {
      closeBanner({ merchantId: merchant.id, bannerid });
      setBanners((banners) =>
        banners.filter((currBanner) => currBanner.id !== bannerid),
      );
    }
  };

  useEffect(() => {
    if (dataBanners) {
      const banners: IBanner[] = sortBanners(dataBanners);
      setBanners(banners);
    }
  }, [dataBanners, isLoading]);

  const bannerContextValue: BannerContextType = {
    banners,
    removeBanner,
  };

  return (
    <BannerContext.Provider value={bannerContextValue}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanners = (): BannerContextType => {
  const context = React.useContext(BannerContext);
  if (context === undefined) {
    throw new Error('useBanner must be used within a BannerProvider');
  }
  return context;
};
