import { AppRoutesEnum } from '@/enum/appRoutes.enum';
import {
  adminPermissions,
  MerchantPermissions,
  UserPermissions,
} from '@/enum/permission.enum';

export interface ISidebarLink {
  label: string;
  link?: string;
  subLinks?: ISidebarSubLink[];
  merchantAccess?: boolean | MerchantPermissions | MerchantPermissions[];
  userAccess?: boolean | UserPermissions | UserPermissions[];
}

export interface ISidebarSubLink {
  label: string;
  link: string;
  isKycRequired?: boolean;
  merchantAccess?: boolean | MerchantPermissions | MerchantPermissions[];
  userAccess?: boolean | UserPermissions | UserPermissions[];
}

export const sidebarLinks = (): ISidebarLink[] => [
  {
    label: 'common.dashboardNav.summary',
    link: AppRoutesEnum.adminSummary,
    userAccess: adminPermissions,
  },

  {
    label: 'common.dashboardNav.summary',
    link: AppRoutesEnum.summary,
    merchantAccess: true,
  },

  {
    label: 'common.dashboardNav.account',
    subLinks: [
      {
        label: 'common.dashboardNav.merchants',
        link: AppRoutesEnum.merchants,
        userAccess: UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.merchantUsers',
        link: AppRoutesEnum.merchantUsers,
        userAccess: UserPermissions.ADMIN_USER_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.adminUsers',
        link: AppRoutesEnum.adminUsers,
        userAccess: UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.adminRoleManagement',
        link: AppRoutesEnum.adminRoleManagement,
        userAccess: UserPermissions.ADMIN_ROLE_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.merchantProfile',
        link: AppRoutesEnum.merchantProfile,
        merchantAccess: true,
      },
      {
        label: 'common.dashboardNav.userProfile',
        link: AppRoutesEnum.profileSettings,
        userAccess: true,
      },
      {
        label: 'common.dashboardNav.security',
        link: AppRoutesEnum.security,
        userAccess: true,
      },
      {
        label: 'common.dashboardNav.userManagement',
        link: AppRoutesEnum.users,
        merchantAccess: MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.roleManagement',
        link: AppRoutesEnum.roleManagement,
        merchantAccess: MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.verification',
        link: AppRoutesEnum.merchantVerification,
        merchantAccess: MerchantPermissions.MERCHANT_VERIFICATIONS_READ,
      },
      {
        label: 'common.dashboardNav.linkedMerchant',
        link: AppRoutesEnum.linked,
        merchantAccess: MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ,
      },
    ],
  },

  {
    label: 'common.dashboardNav.setup',
    subLinks: [
      {
        label: 'common.dashboardNav.networks', // blockchain config page
        link: AppRoutesEnum.networks,
        userAccess: UserPermissions.ADMIN_NETWORKS_MANAGEMENT_READ,
      },
      // {
      //   //TODO: add path into enum
      //   label: 'common.dashboardNav.sysWallets', // new page
      //   link: '/system-wallets',
      //   userAccess: adminPermissions,
      // },
      {
        //TODO: add path into enum
        label: 'common.dashboardNav.settlement', // new page
        link: '/admin/settlement',
        userAccess: adminPermissions,
      },
      {
        label: 'common.dashboardNav.systemMaintenance',
        link: AppRoutesEnum.systemMaintenance,
        userAccess: UserPermissions.ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE,
      },
      {
        label: 'common.dashboardNav.acceptedCoins',
        link: AppRoutesEnum.coinsSettings,
        merchantAccess: MerchantPermissions.MERCHANT_ACCEPTED_COINS_READ,
      },
      {
        label: 'common.dashboardNav.storeSettings',
        link: AppRoutesEnum.storeSettings,
        merchantAccess: MerchantPermissions.MERCHANT_STORE_SETTINGS_READ,
      },
      {
        label: 'common.dashboardNav.cashback',
        link: AppRoutesEnum.cashback,
        merchantAccess: MerchantPermissions.MERCHANT_CASHBACK_READ,
      },
      // {
      //   label: 'common.dashboardNav.settlement', // new page
      //   link: AppRoutesEnum.settlement,
      //   isKycRequired: true,
      //   merchantAccess: MerchantPermissions.MERCHANT_STORE_SETTINGS_READ,
      // },
      // {
      //   //TODO: add path into enum
      //   label: 'common.dashboardNav.wallets', // new page
      //   link: '/wallets',
      //   isKycRequired: true,
      // },
    ],
  },
  {
    label: 'common.dashboardNav.integration',
    subLinks: [
      {
        label: 'common.dashboardNav.apiKeys',
        link: AppRoutesEnum.apiKeys,
        merchantAccess: MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_READ,
      },
      {
        label: 'common.dashboardNav.paymentWidget',
        link: AppRoutesEnum.paymentWidget,
        merchantAccess: MerchantPermissions.MERCHANT_PAYMENT_WIDGET_READ,
      },
      {
        label: 'common.dashboardNav.remunoShopDemo',
        link: AppRoutesEnum.remunoShopDemo,
        merchantAccess: MerchantPermissions.MERCHANT_SHOP_DEMO_READ,
      },
    ],
  },
  {
    label: 'common.dashboardNav.revenue',
    subLinks: [
      {
        label: 'common.dashboardNav.systemTransfers',
        link: AppRoutesEnum.systemSettlementTransfers,
        userAccess: UserPermissions.ADMIN_TRANSFERS_READ,
      },
      {
        label: 'common.dashboardNav.transactions',
        link: AppRoutesEnum.systemSettlementTransactions,
        userAccess: UserPermissions.ADMIN_TRANSACTIONS_READ,
      },
      {
        label: 'common.dashboardNav.lastMonthReports',
        link: AppRoutesEnum.lastMonthReports,
        userAccess: UserPermissions.ADMIN_REPORTING_READ,
      },
      {
        label: 'common.dashboardNav.reportingByPeriod',
        link: AppRoutesEnum.reporting,
        userAccess: UserPermissions.ADMIN_REPORTING_READ,
      },
      {
        label: 'common.dashboardNav.viewTransactions',
        link: AppRoutesEnum.viewTransactions,
        merchantAccess: MerchantPermissions.MERCHANT_TRANSACTIONS_READ,
      },
      {
        label: 'common.dashboardNav.viewTransfers',
        link: AppRoutesEnum.merchantTransfer,
        merchantAccess: MerchantPermissions.MERCHANT_TRANSFERS_READ,
      },
      {
        label: 'common.dashboardNav.viewCashback',
        link: AppRoutesEnum.viewCashback,
        merchantAccess: MerchantPermissions.MERCHANT_TRANSACTIONS_READ,
      },
      {
        label: 'common.dashboardNav.lastMonthReports',
        link: AppRoutesEnum.merchantLastMonthReports,
        merchantAccess: MerchantPermissions.MERCHANT_REPORTING_READ,
      },
      {
        label: 'common.dashboardNav.generalReporting',
        link: AppRoutesEnum.merchantReporting,
        merchantAccess: MerchantPermissions.MERCHANT_REPORTING_READ,
      },
    ],
  },
];

export const navbarLinks = (): ISidebarLink[] => [
  {
    label: 'common.dashboardNav.merchants',
    link: AppRoutesEnum.merchants,
    userAccess: UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.adminUsers',
    link: AppRoutesEnum.adminUsers,
    userAccess: UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.networks',
    link: AppRoutesEnum.networks,
    userAccess: UserPermissions.ADMIN_NETWORKS_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.systemTransfers',
    link: AppRoutesEnum.systemSettlementTransfers,
    userAccess: UserPermissions.ADMIN_TRANSFERS_READ,
  },
  {
    label: 'common.dashboardNav.transactions',
    link: AppRoutesEnum.systemSettlementTransactions,
    userAccess: UserPermissions.ADMIN_TRANSACTIONS_READ,
  },
  {
    label: 'common.dashboardNav.reportingByPeriod',
    link: AppRoutesEnum.reporting,
    userAccess: UserPermissions.ADMIN_REPORTING_READ,
  },
  {
    label: 'common.dashboardNav.lastMonthReports',
    link: AppRoutesEnum.lastMonthReports,
    userAccess: UserPermissions.ADMIN_REPORTING_READ,
  },

  {
    label: 'common.dashboardNav.merchantProfile',
    link: AppRoutesEnum.merchantProfile,
    merchantAccess: true,
  },
  {
    label: 'common.dashboardNav.userProfile',
    link: AppRoutesEnum.profileSettings,
    userAccess: true,
  },
  {
    label: 'common.dashboardNav.security',
    link: AppRoutesEnum.security,
    userAccess: true,
  },
  {
    label: 'common.dashboardNav.userManagement',
    link: AppRoutesEnum.users,
    merchantAccess: MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.roleManagement',
    link: AppRoutesEnum.roleManagement,
    merchantAccess: MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.storeSettings',
    link: AppRoutesEnum.storeSettings,
    merchantAccess: MerchantPermissions.MERCHANT_STORE_SETTINGS_READ,
  },
  {
    label: 'common.dashboardNav.acceptedCoins',
    link: AppRoutesEnum.coinsSettings,
    merchantAccess: MerchantPermissions.MERCHANT_ACCEPTED_COINS_READ,
  },
  {
    label: 'common.dashboardNav.viewTransactions',
    link: AppRoutesEnum.viewTransactions,
    merchantAccess: MerchantPermissions.MERCHANT_TRANSACTIONS_READ,
  },
  {
    label: 'common.dashboardNav.viewTransfers',
    link: AppRoutesEnum.merchantTransfer,
    merchantAccess: MerchantPermissions.MERCHANT_TRANSFERS_READ,
  },
  {
    label: 'common.dashboardNav.lastMonthReports',
    link: AppRoutesEnum.merchantLastMonthReports,
    merchantAccess: MerchantPermissions.MERCHANT_REPORTING_READ,
  },
  {
    label: 'settlement.title',
    link: AppRoutesEnum.settlement,
    merchantAccess: MerchantPermissions.MERCHANT_SETTLEMENT_MANAGEMENT_READ,
  },
  {
    label: 'common.dashboardNav.generalReporting',
    link: AppRoutesEnum.merchantReporting,
    merchantAccess: MerchantPermissions.MERCHANT_REPORTING_READ,
  },
];
