import React, { useCallback, useEffect } from 'react';

import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppButton, FormInput } from '@/elements';
import { twoFactorCodeSchema } from '@/validations/twoFactorCode.schema';

import { CloseModalIconButton } from '@/components/IconButton/close-modal';

interface ITwoFactorModalProps {
  isOpen: boolean;
  codeError: boolean;
  onClose(): void;
  onSubmit(code: string): any;
}

export const EnterTFACodeModal: React.FC<ITwoFactorModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  codeError,
}) => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(twoFactorCodeSchema(t)),
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (codeError) {
      setError('code', {
        type: 'custom',
        message: t('login.twoFactorModal.error'),
      });
    }
  }, [codeError, setError, t]);

  const onFormSubmit = useCallback(
    async (codeData: { code: string }) => {
      onSubmit(codeData.code);
    },
    [onSubmit],
  );

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />

      <ModalContent>
        <CloseModalIconButton onClick={onClose} />

        <ModalBody>
          <Heading as='h3' colorScheme='app.pink' m='35px 0 20px 0' size='md'>
            {t('login.twoFactorModal.title')}
          </Heading>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <FormInput
                label={t('login.twoFactorModal.label')}
                name='code'
                placeholder='123456'
              />

              <Flex justify='center' py='lg'>
                <AppButton
                  isDisabled={!isValid}
                  type='submit'
                  variant='primary'
                >
                  {t('login.twoFactorModal.button')}
                </AppButton>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
