import React, { FC, SVGProps } from 'react';

export const SortDescIcon: FC<SVGProps<any> & { color?: string }> = ({
  color,
  ...props
}) => (
  <svg
    fill='none'
    height={15}
    viewBox='0 0 15 15'
    width={15}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.68188 14.6914H12.1584C12.9762 14.6914 13.5881 14.4883 13.9944 14.082C14.4058 13.681 14.6116 13.0768 14.6116 12.2695V2.73047C14.6116 1.92318 14.4058 1.31901 13.9944 0.917969C13.5881 0.511719 12.9762 0.308594 12.1584 0.308594H2.68188C1.86418 0.308594 1.24959 0.511719 0.838135 0.917969C0.431885 1.31901 0.22876 1.92318 0.22876 2.73047V12.2695C0.22876 13.0768 0.431885 13.681 0.838135 14.082C1.24959 14.4883 1.86418 14.6914 2.68188 14.6914ZM7.4397 3.37891C7.61678 3.37891 7.76001 3.4362 7.86938 3.55078C7.98397 3.66536 8.04126 3.8112 8.04126 3.98828V7.99609L7.97876 9.71484L8.79126 8.73828L9.73657 7.77734C9.84595 7.65755 9.98918 7.59766 10.1663 7.59766C10.3329 7.59766 10.4736 7.65495 10.5881 7.76953C10.7027 7.87891 10.76 8.01693 10.76 8.18359C10.76 8.35547 10.7053 8.49609 10.5959 8.60547L7.90845 11.2852C7.75741 11.4362 7.60116 11.5117 7.4397 11.5117C7.27824 11.5117 7.12199 11.4362 6.97095 11.2852L4.28345 8.60547C4.17407 8.5013 4.11938 8.36068 4.11938 8.18359C4.11938 8.01693 4.17407 7.87891 4.28345 7.76953C4.39803 7.65495 4.53866 7.59766 4.70532 7.59766C4.87199 7.59766 5.01522 7.65755 5.13501 7.77734L6.08813 8.73828L6.89282 9.70703L6.83032 7.99609V3.98828C6.83032 3.8112 6.88761 3.66536 7.0022 3.55078C7.11678 3.4362 7.26261 3.37891 7.4397 3.37891Z'
      fill={color}
    />
  </svg>
);
