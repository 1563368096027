import React, { FC, SVGProps } from 'react';

export const FailureFinalIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#EB3D3D',
  ...props
}) => (
  <svg
    fill='none'
    height={20}
    viewBox='0 0 20 20'
    width={20}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119ZM10.0625 11.771C9.55469 11.771 9.29443 11.5107 9.28174 10.9902L9.15796 5.80103C9.15161 5.55347 9.23096 5.34717 9.396 5.18213C9.56738 5.01709 9.78638 4.93457 10.053 4.93457C10.3132 4.93457 10.5291 5.02026 10.7004 5.19165C10.8782 5.35669 10.9639 5.56299 10.9575 5.81055L10.8147 10.9902C10.8083 11.5107 10.5576 11.771 10.0625 11.771ZM10.0625 14.9607C9.77686 14.9607 9.52612 14.8623 9.3103 14.6655C9.10083 14.4688 8.99609 14.2275 8.99609 13.9419C8.99609 13.6626 9.10083 13.4214 9.3103 13.2183C9.51978 13.0151 9.77051 12.9136 10.0625 12.9136C10.3481 12.9136 10.5957 13.0151 10.8052 13.2183C11.021 13.415 11.1289 13.6562 11.1289 13.9419C11.1289 14.2275 11.021 14.4688 10.8052 14.6655C10.5957 14.8623 10.3481 14.9607 10.0625 14.9607Z'
      fill={color}
    />
  </svg>
);
