import { ComponentStyleConfig } from '@chakra-ui/react';

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '1rem',
    fontWeight: '700',
  },
};

export default FormLabel;
