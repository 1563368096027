import React, { FC, SVGProps } from 'react';

export const ExpiredIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#A7A7A7',
  ...props
}) => (
  <svg
    fill='none'
    height={20}
    viewBox='0 0 24 20'
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M12.229 19.4507C11.5942 19.5903 10.9404 19.6602 10.2676 19.6602C8.94092 19.6602 7.69043 19.4062 6.51611 18.8984C5.34814 18.3906 4.31665 17.6892 3.42163 16.7942C2.52661 15.8992 1.8252 14.8677 1.31738 13.6997C0.80957 12.5254 0.555664 11.2749 0.555664 9.94824C0.555664 8.62158 0.806396 7.37427 1.30786 6.2063C1.81567 5.03198 2.51709 3.99731 3.41211 3.10229C4.30713 2.20728 5.33862 1.50586 6.50659 0.998047C7.68091 0.490234 8.9314 0.236328 10.2581 0.236328C11.4958 0.236328 12.667 0.458496 13.7715 0.902832C14.876 1.34717 15.8662 1.96606 16.7422 2.75952C17.6182 3.55298 18.3323 4.47339 18.8845 5.52075C19.4368 6.56812 19.7795 7.698 19.9128 8.9104C19.5383 8.82788 19.1448 8.78662 18.7322 8.78662C17.8879 8.78662 17.0945 8.94849 16.3518 9.27222C15.6155 9.5896 14.9648 10.0308 14.3999 10.5957C13.835 11.1606 13.3906 11.8145 13.0669 12.5571C12.7495 13.2935 12.5908 14.0837 12.5908 14.928C12.5908 15.677 12.7209 16.3848 12.9812 17.0513C13.2415 17.7241 13.6001 18.3303 14.0571 18.8699C13.4731 19.1238 12.8638 19.3174 12.229 19.4507ZM4.81177 10.7861C4.93872 10.9131 5.09424 10.9766 5.27832 10.9766H10.2581C10.4421 10.9766 10.5977 10.9131 10.7246 10.7861C10.8579 10.6592 10.9246 10.5005 10.9246 10.3101V3.89258C10.9246 3.7085 10.8579 3.55298 10.7246 3.42603C10.5977 3.29907 10.4421 3.2356 10.2581 3.2356C10.074 3.2356 9.91846 3.29907 9.7915 3.42603C9.66455 3.55298 9.60107 3.7085 9.60107 3.89258V9.65308H5.27832C5.09424 9.65308 4.93872 9.71655 4.81177 9.84351C4.68481 9.97046 4.62134 10.126 4.62134 10.3101C4.62134 10.5005 4.68481 10.6592 4.81177 10.7861Z'
      fill={color}
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M18.7322 19.7649C19.386 19.7649 20.0049 19.6379 20.5889 19.384C21.1729 19.1301 21.687 18.781 22.1313 18.3367C22.582 17.8923 22.9343 17.3782 23.1882 16.7942C23.4421 16.2102 23.5691 15.5881 23.5691 14.928C23.5691 14.2615 23.4421 13.6362 23.1882 13.0522C22.9407 12.4683 22.5947 11.9541 22.1504 11.5098C21.7061 11.0654 21.1919 10.7195 20.6079 10.4719C20.0239 10.218 19.3987 10.0911 18.7322 10.0911C18.072 10.0911 17.45 10.218 16.866 10.4719C16.282 10.7195 15.7678 11.0686 15.3235 11.5193C14.8792 11.9636 14.53 12.4778 14.2761 13.0618C14.0222 13.6394 13.8953 14.2615 13.8953 14.928C13.8953 15.5945 14.0222 16.2197 14.2761 16.8037C14.53 17.3877 14.8792 17.9019 15.3235 18.3462C15.7678 18.7905 16.282 19.1365 16.866 19.384C17.45 19.6379 18.072 19.7649 18.7322 19.7649ZM16.9421 17.3464C17.1072 17.3591 17.25 17.302 17.3706 17.175L18.7703 15.7754L20.0652 17.0608C20.1794 17.175 20.3159 17.229 20.4746 17.2227C20.6333 17.2227 20.7698 17.1687 20.884 17.0608C20.9919 16.9529 21.0459 16.8196 21.0459 16.6609C21.0459 16.4958 20.9919 16.3562 20.884 16.2419L19.5986 14.9565L20.9888 13.5569C21.1157 13.4363 21.1729 13.2935 21.1602 13.1284C21.1475 12.9634 21.0808 12.8206 20.9602 12.7C20.8459 12.5857 20.7063 12.5222 20.5413 12.5095C20.3762 12.4968 20.2334 12.5508 20.1128 12.6714L18.7131 14.071L17.4182 12.7856C17.304 12.6777 17.1675 12.6238 17.0088 12.6238C16.8564 12.6238 16.7231 12.6777 16.6089 12.7856C16.4946 12.8936 16.4375 13.03 16.4375 13.1951C16.4375 13.3538 16.4946 13.4902 16.6089 13.6045L17.8848 14.8994L16.4946 16.2896C16.3677 16.4165 16.3105 16.5625 16.3232 16.7275C16.3359 16.8926 16.4026 17.0322 16.5232 17.1465C16.6375 17.2671 16.7771 17.3337 16.9421 17.3464Z'
      fill='#EB3D3D'
      fillRule='evenodd'
    />
  </svg>
);
