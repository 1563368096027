import * as React from 'react';

import { BoxProps, IconButtonProps, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AppIconButton, CopyIcon } from '@/elements';

import { ToastTypes, useCustomToast } from '@/hooks/useCustomToast';

type CopyTextProps = Omit<IconButtonProps, 'aria-label'> & {
  data: { [key: string]: string } | string;
  sx?: BoxProps;
};

const CopyText: React.FC<CopyTextProps> = ({ data, ...rest }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const customToast = useCustomToast();

  const onCopy = () => {
    if (typeof data === 'object') {
      let str = '';
      Object.entries(data).forEach(([key, value]) => {
        str += `${key}: ${value ? value : '-'}\r\n`;
      });
      navigator.clipboard.writeText(str);
    } else {
      navigator.clipboard.writeText(data);
    }

    customToast(t('common.copyToClipboard'), ToastTypes.success);
  };

  return (
    <AppIconButton
      aria-label='copy'
      onClick={onCopy}
      variant='unstyled'
      {...rest}
    >
      <CopyIcon
        boxSize='1.5rem'
        color={theme.colors.app.pink}
        cursor='pointer'
      />
    </AppIconButton>
  );
};

export default CopyText;
