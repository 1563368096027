import React, { FC, SVGProps } from 'react';

export const CopyIcon: FC<
  SVGProps<any> & { color?: string; boxSize?: string }
> = ({ color = '#E2368F', boxSize = '1rem', ...props }) => (
  <svg
    fill='none'
    height={`${parseFloat(boxSize) * 0.8125}rem`}
    viewBox='0 0 13 16'
    width={boxSize}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.39844 15.7168H7.88281C8.51172 15.7168 8.98633 15.5547 9.30664 15.2305C9.62695 14.9062 9.78711 14.4277 9.78711 13.7949V9.24805H5.62109C5.24609 9.24805 4.9668 9.15625 4.7832 8.97266C4.59961 8.78516 4.50781 8.50586 4.50781 8.13477V3.81641H2.39844C1.76953 3.81641 1.29492 3.98047 0.974609 4.30859C0.654297 4.63281 0.494141 5.11133 0.494141 5.74414V13.7949C0.494141 14.4316 0.654297 14.9102 0.974609 15.2305C1.29492 15.5547 1.76953 15.7168 2.39844 15.7168ZM5.67969 8.39258L9.67578 8.38672C9.64453 8.26562 9.58984 8.14453 9.51172 8.02344C9.43359 7.89844 9.32812 7.76758 9.19531 7.63086L6.10742 4.49609C5.97461 4.35938 5.84766 4.24609 5.72656 4.15625C5.60547 4.06641 5.48242 4.00391 5.35742 3.96875V8.07031C5.35742 8.28516 5.46484 8.39258 5.67969 8.39258ZM10.6426 12.7109H10.8242C11.1094 12.7109 11.3652 12.6719 11.5918 12.5938C11.8223 12.5156 12.0176 12.3984 12.1777 12.2422C12.3418 12.082 12.4668 11.8809 12.5527 11.6387C12.6426 11.3965 12.6875 11.1133 12.6875 10.7891V5.25781H9.40039C9.10352 5.25781 8.86914 5.17383 8.69727 5.00586C8.52539 4.83398 8.43945 4.59961 8.43945 4.30273V0.810547H5.29297C4.67969 0.810547 4.20898 0.972656 3.88086 1.29688C3.55664 1.61719 3.39453 2.08008 3.39453 2.68555V2.9668H4.29688C4.75 2.9668 5.15234 3.0293 5.50391 3.1543C5.85938 3.2793 6.20117 3.50391 6.5293 3.82812L9.85156 7.15039C10.1719 7.4707 10.3828 7.81445 10.4844 8.18164C10.5898 8.54883 10.6426 8.94922 10.6426 9.38281V12.7109ZM9.55859 4.47266H12.5938C12.5781 4.375 12.5371 4.2793 12.4707 4.18555C12.4043 4.0918 12.3145 3.98633 12.2012 3.86914L9.82227 1.4375C9.7168 1.32422 9.61523 1.23437 9.51758 1.16797C9.42383 1.09766 9.32812 1.05859 9.23047 1.05078L9.23633 4.13867C9.23633 4.36133 9.34375 4.47266 9.55859 4.47266Z'
      fill={color}
    />
  </svg>
);
