import React, { FC, SVGProps } from 'react';

export const RecivedIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#2174F1',
  ...props
}) => (
  <svg
    fill='none'
    height='20'
    viewBox='0 0 20 20'
    width='20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119ZM6.96802 7.5625C7.19019 7.5625 7.36792 7.63232 7.50122 7.77197C7.63452 7.90527 7.70117 8.09253 7.70117 8.33374V10.019L7.55835 11.5425L8.97705 10.0381L12.5667 6.42944C12.6365 6.35962 12.719 6.30566 12.8142 6.26758C12.9094 6.22314 13.0173 6.20093 13.1379 6.20093C13.3538 6.20093 13.5251 6.26758 13.6521 6.40088C13.7854 6.53418 13.8521 6.71509 13.8521 6.9436C13.8521 7.13403 13.7727 7.30859 13.614 7.46729L10.0149 11.0664L8.48193 12.4946L9.93872 12.3423H11.7097C11.9446 12.3423 12.1318 12.4089 12.2715 12.5422C12.4175 12.6755 12.4905 12.8501 12.4905 13.0659C12.4905 13.2817 12.4207 13.4563 12.281 13.5896C12.1414 13.7229 11.9573 13.7896 11.7288 13.7896H7.07275C6.81885 13.7896 6.6189 13.7261 6.4729 13.5991C6.3269 13.4722 6.25391 13.2627 6.25391 12.9707V8.35278C6.25391 8.11792 6.31738 7.92749 6.44434 7.78149C6.57764 7.6355 6.7522 7.5625 6.96802 7.5625Z'
      fill={color}
    />
  </svg>
);
