import { ErrorCodes } from '@/enum/errorCodes.enum';

export const getErrorMessageByCode = (code: any) => {
  switch (code) {
    case ErrorCodes.REGISTRATION_ACCOUNT_VERIFIED:
    case ErrorCodes.REGISTRATION_EMAIL_IN_USE:
    case ErrorCodes.REGISTRATION_FAILED_TO_CREATE_MERCHANT:
    case ErrorCodes.REGISTRATION_INVALID_CONFIRMATION_CODE:
    case ErrorCodes.REGISTRATION_MERCHANT_NOT_FOUND:
    case ErrorCodes.REGISTRATION_EMAIL_IN_USE_CAN_RESEND:
    case ErrorCodes.REGISTRATION_COUNTRY_NOT_FOUND:
    case ErrorCodes.AUTH_INVALID_CREDENTIALS:
    case ErrorCodes.AUTH_TFA_INVALID_CODE:
    case ErrorCodes.AUTH_TFA_INVALID_CONFIRMATION:
    case ErrorCodes.AUTH_ACCOUNT_DISABLED:
    case ErrorCodes.AUTH_UNCONFIRMED_EMAIL:
    case ErrorCodes.INVITE_USER_ALREADY_INVITED:
    case ErrorCodes.INVITE_INVALID_CODE:
      return `errorCode.${code}`;
    default:
      return 'errorCode.unexpected';
  }
};

export const getInviteUserErrorMessage = (code: any) => {
  const msg = getErrorMessageByCode(code);

  if (msg === 'errorCode.unexpected') {
    return 'inviteUserPage.errorAcceptMessage';
  }
  return msg;
};
