import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { default as ReactSelect, Props } from 'react-select';

import { ISelectOption } from '@/types/domain';

import FormLabel from '../FormLabel';

import variants from './variants';

export type SelectProps = Omit<
  Props,
  | 'onChange'
  | 'indicator'
  | 'formatValue'
  | 'floatingLabel'
  | 'options'
  | 'value'
  | 'variant'
  | 'isMulti'
> & {
  containerProps?: FormControlProps;
  error?: string;
  value?: unknown;
  options: ISelectOption[];
  onChange: (val: any) => void;
  indicator?: ReactNode;
  formatValue?: (value: ISelectOption) => string;
  floatingLabel?: boolean;
  allOptionLabel?: string;
  indicatorPosition?: 'left' | 'right';
  variant?: 'primary' | 'secondary' | 'readonly';
  hasTooltip?: boolean;
  label?: string;
  name: string;
  tooltipText?: string | React.ReactNode;
  defaultValue?: any;
  autoHeight?: boolean;
  id?: string;
  search?: string;
  hintText?: string;
  isInvalid?: boolean;
  disablePortal?: boolean;
  onSearchChange?: (search: string) => void;
  alignCenter?: boolean;
  disableFilter?: boolean;
  isClearable?: boolean;
};

function getVariant(variant: string) {
  switch (variant) {
    case 'primary':
      return variants.primary;
    case 'secondary':
      return variants.secondary;
    default:
      return variants.primary;
  }
}

export const Select: FC<SelectProps> = ({
  error,
  hasTooltip,
  isInvalid,
  isSearchable = false,
  label,
  options,
  tooltipText,
  value,
  onChange,
  containerProps,
  id,
  hintText,
  variant = 'primary',
  disablePortal,
  disableFilter = false,
  isClearable = false,
  isDisabled = false,
  ...rest
}) => {
  const theme = useTheme();

  const config = getVariant(variant);

  const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);
  const noFilter = useCallback(() => true, []);

  return (
    <FormControl
      {...containerProps}
      id={inputId}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      {label && (
        <FormLabel
          hasTooltip={hasTooltip}
          id={inputId}
          label={label}
          tooltipText={tooltipText}
        />
      )}

      {hintText && (
        <Text color='app.widgetGray' fontSize='sm' fontWeight='500' mb='xs'>
          {hintText}
        </Text>
      )}

      <ReactSelect
        components={config.components}
        filterOption={disableFilter ? noFilter : undefined}
        inputId={inputId}
        isClearable={isClearable}
        isDisabled={variant === 'readonly' || isDisabled}
        isSearchable={isSearchable}
        menuPortalTarget={disablePortal ? undefined : document.body}
        menuPosition='fixed'
        onChange={(opt) => onChange(opt?.value ?? null)}
        options={options}
        styles={config.styles(theme)}
        value={options.find((x) => x.value === value)}
        {...rest}
      />

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
