import { useMemo } from 'react';

import { ISidebarLink, ISidebarSubLink } from '@/components/Sidebar/data';

import { addMerchantIdToRoute } from '@/enum/appRoutes.enum';

import { useCurrentMerchant } from '@/context/merchant.context';

import useMerchantAccess from '@/hooks/useMerchantAccess';
import useUserAccess from '@/hooks/useUserAccess';

export const useSidebarLinks = (links: ISidebarLink[]) => {
  const { merchantDisplayId } = useCurrentMerchant();
  const { checkAccess: checkMerchantAccess } = useMerchantAccess();
  const { checkAccess: checkUserAccess } = useUserAccess();

  const menu = useMemo(() => {
    const shouldRenderItem = (item: ISidebarLink | ISidebarSubLink) => {
      let hasMerchantAccess = true;
      let hasUserAccess = true;

      if (item.merchantAccess !== undefined) {
        hasMerchantAccess = checkMerchantAccess(
          typeof item.merchantAccess === 'boolean' ? [] : item.merchantAccess,
        );
      }
      if (item.userAccess !== undefined) {
        hasUserAccess = checkUserAccess(
          typeof item.userAccess === 'boolean' ? [] : item.userAccess,
        );
      }
      return hasMerchantAccess && hasUserAccess;
    };

    return links
      .map((x) => ({
        ...x,
        link: x.link ? addMerchantIdToRoute(x.link, merchantDisplayId) : x.link,
        subLinks: x.subLinks
          ?.filter((y) => shouldRenderItem(y))
          .map((y) => ({
            ...y,
            link: addMerchantIdToRoute(y.link, merchantDisplayId),
          })),
      }))
      .filter(
        (section) =>
          shouldRenderItem(section) &&
          (section.subLinks === undefined || section.subLinks.length),
      );
  }, [checkMerchantAccess, checkUserAccess, links, merchantDisplayId]);

  return { menu };
};
