import { ComponentStyleConfig } from '@chakra-ui/react';

const defaultButtonStyles = {
  overflow: 'hidden',
  h: '2.875rem',
  border: '2px solid',
  borderRadius: '4xl',
  fontWeight: '700',
  cursor: 'pointer',
  transform: 'scale(1)',
  WebkitAppearance: 'none',
  position: 'relative',
  boxShadow: '4px 3px 19px 1px rgba(0, 0, 0, 0.07)',
  transition: 'background .5s, color .5s, border-color .5s',
  '-webkit-transform': 'translateZ(0)',
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    xs: {
      fontSize: 'sm',
      px: '0.6rem',
      height: '1.8rem',
      minWidth: '1.8rem',
    },
    sm: {
      fontSize: 'md',
      px: '0.875rem',
      height: '2.1875rem',
      minWidth: '2.1875rem',
    },
    md: {
      fontSize: 'md',
      px: '2.2rem',
      height: '2.5rem',
      minWidth: '2.5rem',
    },
    lg: {
      fontSize: 'xl',
      px: '2.75rem',
      height: '2.875rem',
      minWidth: '2.875rem',
    },
  },
  variants: {
    primary: {
      ...defaultButtonStyles,
      background: 'app.pink',
      borderColor: 'app.pink',
      color: 'app.white',
      _before: {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '180%',
        background: 'app.pink',
        borderRadius: '0 0 50% 50%',
        zIndex: '-1',
        transition: '.5s',
      },
      _hover: {
        background: 'transparent',
        border: '2px solid',
        borderColor: 'app.black',
        color: 'app.black',
        _before: {
          height: '0%',
        },
        _disabled: {
          background: 'app.pink',
          border: '2px solid',
          borderColor: 'app.pink',
          color: 'app.white',
        },
      },
      _active: {
        background: 'transparent',
        border: '2px solid',
        borderColor: 'app.black',
        color: 'app.black',
        _before: {
          background: 'transparent',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: 'app.white',
      color: 'app.pink',
      _before: {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '180%',
        background: 'app.white',
        borderRadius: '0 0 50% 50%',
        zIndex: '-1',
        transition: '.5s',
      },
      _hover: {
        background: 'app.pink',
        border: '2px solid',
        borderColor: 'app.pink',
        color: 'app.white',
        _before: {
          height: '0%',
        },
        _disabled: {
          background: 'app.white',
          border: '2px solid',
          borderColor: 'inherit',
          color: 'app.pink',
        },
      },
      _active: {
        background: 'app.pink',
        border: '2px solid',
        borderColor: 'app.pink',
        color: 'app.white',
        _before: {
          background: 'transparent',
        },
      },
    },
    outlined: {
      ...defaultButtonStyles,
      borderColor: 'app.black',
      color: 'app.black',
      background: 'transparent',
      _before: {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '0%',
        background: 'app.pink',
        borderRadius: '50% 50% 0 0',
        zIndex: '-1',
        transition: '.5s',
      },
      _hover: {
        color: 'app.white',
        borderColor: 'app.pink',
        background: 'app.pink',
        _before: {
          height: '180%',
        },
      },
      _active: {
        color: 'app.white',
        borderColor: 'app.pink',
        background: 'app.pink',
        _before: {
          background: 'app.pink',
        },
      },
    },
    link: {
      color: 'app.pink',
      px: '0',
      minWidth: 'auto',
      _hover: {
        color: 'app.pink',
        background: 'transparent',
      },
      _active: {
        color: 'app.pink',
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Button;
