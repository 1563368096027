import React, { FC } from 'react';

import { QuestionIcon } from '@chakra-ui/icons';
import { FormLabel as ChakraFormLabel, Tooltip } from '@chakra-ui/react';

export type FormLabelProps = {
  hasTooltip?: boolean;
  label: string | React.ReactNode;
  tooltipText?: string | React.ReactNode;
  id: string;
};

const FormLabel: FC<FormLabelProps> = ({
  hasTooltip,
  label,
  tooltipText,
  id,
}) => {
  return hasTooltip ? (
    <Tooltip
      fontSize='md'
      hasArrow
      label={tooltipText}
      placement='auto'
      shouldWrapChildren
    >
      <ChakraFormLabel display='inline-flex' htmlFor={id}>
        {label}
        <QuestionIcon
          color='app.lightPink'
          cursor='pointer'
          fontSize='1.25rem'
          ml='sm'
        />
      </ChakraFormLabel>
    </Tooltip>
  ) : (
    <ChakraFormLabel htmlFor={id}>{label}</ChakraFormLabel>
  );
};

export default FormLabel;
