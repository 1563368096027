const radius = {
  none: '0',
  sm: '0.125rem', //2px
  base: '0.25rem', //4px
  md: '0.375rem', //6px
  lg: '0.5rem', //8px
  xl: '0.75rem', //12px
  '2xl': '1rem', //16px
  '3xl': '1.5rem', //24px
  '4xl': '6.25rem', //100px
  full: '624.938rem', //9999px
};

export default radius;
