import React, { FC } from 'react';

import {
  InputProps as ChakraInputProps,
  FormControlProps,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { PasswordInput } from '@/elements';

export type FormPasswordInputProps = Omit<
  FormControlProps,
  'label' | 'onChange' | 'defaultValue'
> &
  Omit<ChakraInputProps, 'label'> & {
    containerProps?: FormControlProps;
    hasTooltip?: boolean;
    label: string | React.ReactNode;
    name: string;
    tooltipText?: string | React.ReactNode;
    isStrengthBarNeeded?: boolean;
  };
const FormPasswordInput: FC<FormPasswordInputProps> = ({ name, ...rest }) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <PasswordInput
      containerProps={{ mb: 'lg' }}
      error={fieldState.error?.message}
      isInvalid={Boolean(fieldState.error)}
      password={field.value || ''}
      {...rest}
      {...field}
    />
  );
};

export default FormPasswordInput;
