import React, { useCallback } from 'react';

import { Box, useToast } from '@chakra-ui/react';

export enum ToastTypes {
  success = 'success',
  error = 'error',
}

export const useCustomToast = () => {
  const toast = useToast();
  const customToast = useCallback(
    (message: string | JSX.Element, type: ToastTypes, params?: any) => {
      toast({
        position: 'top',
        isClosable: true,
        duration: 3000,
        ...params,
        render: () => (
          <Box
            bg={type === ToastTypes.success ? 'green.300' : 'red.300'}
            color='white'
            fontWeight='bold'
            p={3}
          >
            {message}
          </Box>
        ),
      });
    },
    [toast],
  );

  return customToast;
};
