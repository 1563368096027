import React, { FC, SVGProps } from 'react';

export const PendingIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#FD9D49',
  ...props
}) => (
  <svg
    fill='none'
    height={20}
    viewBox='0 0 20 20'
    width={20}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.08276 11.0283C4.89233 11.0283 4.73364 10.9648 4.60669 10.8379C4.47974 10.7109 4.41626 10.5522 4.41626 10.3618C4.41626 10.1777 4.47974 10.0222 4.60669 9.89526C4.73364 9.76831 4.89233 9.70483 5.08276 9.70483H9.396V3.94434C9.396 3.76025 9.45947 3.60474 9.58643 3.47778C9.71338 3.35083 9.8689 3.28735 10.053 3.28735C10.2434 3.28735 10.4021 3.35083 10.5291 3.47778C10.656 3.60474 10.7195 3.76025 10.7195 3.94434V10.3618C10.7195 10.5522 10.656 10.7109 10.5291 10.8379C10.4021 10.9648 10.2434 11.0283 10.053 11.0283H5.08276ZM10.0625 19.7119C11.3892 19.7119 12.6365 19.458 13.8044 18.9502C14.9788 18.4424 16.0134 17.741 16.9084 16.8459C17.8035 15.9509 18.5049 14.9194 19.0127 13.7515C19.5205 12.5771 19.7744 11.3267 19.7744 10C19.7744 8.67334 19.5205 7.42603 19.0127 6.25806C18.5049 5.08374 17.8035 4.04907 16.9084 3.15405C16.0134 2.25903 14.9788 1.55762 13.8044 1.0498C12.6301 0.541992 11.3796 0.288086 10.053 0.288086C8.72632 0.288086 7.47583 0.541992 6.30151 1.0498C5.13354 1.55762 4.10205 2.25903 3.20703 3.15405C2.31836 4.04907 1.62012 5.08374 1.1123 6.25806C0.604492 7.42603 0.350586 8.67334 0.350586 10C0.350586 11.3267 0.604492 12.5771 1.1123 13.7515C1.62012 14.9194 2.32153 15.9509 3.21655 16.8459C4.11157 17.741 5.14307 18.4424 6.31104 18.9502C7.48535 19.458 8.73584 19.7119 10.0625 19.7119Z'
      fill={color}
    />
  </svg>
);
