export const mobileNavLinkStyles = {
  fontFamily: 'Axiforma, sans-serif',
  fontSize: '22px !important',
  fontWeight: '800',
  color: 'app.white',
  transition: 'color 0.2s linear',
  borderBottom: '1px solid',
  borderColor: 'app.lightPink',
  paddingBottom: '25px',
  paddingTop: '25px',
  textAlign: 'center',
};
