import * as React from 'react';
import { lazy } from 'react';

import { lazyWithPreload } from 'react-lazy-with-preload';

import { AppRoutesEnum } from '@/enum/appRoutes.enum';
import { MerchantPermissions, UserPermissions } from '@/enum/permission.enum';

import { IRoutes } from './type';

const MerchantProfilePage = lazy(
  () => import('@/pages/merchant-pages/merchant-profile'),
);
const LinkedMerchantPage = lazy(() => import('@/pages/merchant-pages/linked'));
const MerchantUsersPage = lazy(
  () => import('@/pages/sysadmin-pages/merchant-users-page'),
);
const SettlementPage = lazy(() => import('@/pages/settlement'));
const UserManagementPage = lazy(
  () => import('@/pages/merchant-pages/merchant-user-management'),
);
const StoreSettingsPage = lazy(
  () => import('@/pages/merchant-pages/store-settings'),
);
const PaymentWidgetPage = lazy(
  () => import('@/pages/merchant-pages/payment-widget'),
);
const RemunoShopDemo = lazy(
  () => import('@/pages/merchant-pages/remuno-shop-demo'),
);
const CoinsSettingsPage = lazy(
  () => import('@/pages/merchant-pages/coins-settings'),
);
const CashbackPage = lazy(() => import('@/pages/merchant-pages/cashback'));
const ViewCashbackPage = lazy(
  () => import('@/pages/merchant-pages/view-cashback'),
);
const ProfileSettingsPage = lazy(
  () => import('@/pages/merchant-pages/profile-settings'),
);
const SecurityPage = lazy(() => import('@/pages/merchant-pages/security'));
const ViewTransactionsPage = lazy(
  () => import('@/pages/merchant-pages/view-transactions'),
);
const MerchantTransferPage = lazy(
  () => import('@/pages/merchant-pages/merchant-transfer'),
);
const MerchantLastMonthReportsPage = lazy(
  () => import('@/pages/merchant-pages/last-month-reports'),
);
const MerchantReportingPage = lazy(
  () => import('@/pages/merchant-pages/reporting'),
);
export const SummaryPage = lazyWithPreload(
  () => import('@/pages/merchant-pages/summary'),
);
const MerchantVerificationPage = lazy(
  () => import('@/pages/merchant-pages/verification'),
);
const LastMonthReportsPage = lazy(
  () => import('@/pages/sysadmin-pages/last-month-reports'),
);
const AdminUserManagementPage = lazy(
  () => import('@/pages/sysadmin-pages/admin-user-management'),
);
const MerchantsPage = lazy(() => import('@/pages/sysadmin-pages/merchants'));
const NetworkPage = lazy(() => import('@/pages/sysadmin-pages/networks'));
const SystemSettlementTransfersPage = lazy(
  () => import('@/pages/sysadmin-pages/system-settlement-transfers'),
);
const AdminMerchantTransfersPage = lazy(
  () => import('@/pages/sysadmin-pages/merchant-transfers'),
);
const SystemSettlementTransactionsPage = lazy(
  () => import('@/pages/sysadmin-pages/system-settlement-transactions'),
);
const AdminTransactionDetailsPage = lazy(
  () => import('@/pages/sysadmin-pages/transaction-details'),
);
const TransactionDetailsPage = lazy(
  () => import('@/pages/merchant-pages/transaction-details'),
);
const ReportingPage = lazy(() => import('@/pages/sysadmin-pages/reporting'));
const SystemMaintenancePage = lazy(
  () => import('@/pages/sysadmin-pages/system-maintenance'),
);
const ApiKeysPage = lazy(() => import('@/pages/merchant-pages/api-keys'));
const RoleManagementPage = lazy(
  () => import('@/pages/merchant-pages/role-management'),
);
const AdminRoleManagementPage = lazy(
  () => import('@/pages/sysadmin-pages/admin-role-management'),
);

export const PrivateRoutes: IRoutes[] = [
  {
    path: AppRoutesEnum.profileSettings,
    element: <ProfileSettingsPage />,
  },
  {
    path: AppRoutesEnum.security,
    element: <SecurityPage />,
  },
];

export const MerchantRoutes: IRoutes[] = [
  {
    path: AppRoutesEnum.summary,
    element: <SummaryPage />,
  },
  {
    path: AppRoutesEnum.merchantProfile,
    element: <MerchantProfilePage />,
  },
  {
    path: AppRoutesEnum.settlement,
    element: <SettlementPage />,
    permissions: [MerchantPermissions.MERCHANT_SETTLEMENT_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.users,
    element: <UserManagementPage />,
    permissions: [MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.storeSettings,
    element: <StoreSettingsPage />,
    permissions: [MerchantPermissions.MERCHANT_STORE_SETTINGS_READ],
  },
  {
    path: AppRoutesEnum.cashback,
    element: <CashbackPage />,
    permissions: [MerchantPermissions.MERCHANT_CASHBACK_READ],
  },
  {
    path: AppRoutesEnum.linked,
    element: <LinkedMerchantPage />,
    permissions: [MerchantPermissions.MERCHANT_USER_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.paymentWidget,
    element: <PaymentWidgetPage />,
    permissions: [MerchantPermissions.MERCHANT_PAYMENT_WIDGET_READ],
  },
  {
    path: AppRoutesEnum.remunoShopDemo,
    element: <RemunoShopDemo />,
    permissions: [MerchantPermissions.MERCHANT_SHOP_DEMO_READ],
  },
  {
    path: AppRoutesEnum.coinsSettings,
    element: <CoinsSettingsPage />,
    permissions: [MerchantPermissions.MERCHANT_ACCEPTED_COINS_READ],
  },
  {
    path: AppRoutesEnum.viewTransactions,
    element: <ViewTransactionsPage />,
    permissions: [MerchantPermissions.MERCHANT_TRANSACTIONS_READ],
  },
  {
    path: AppRoutesEnum.transactionDetails,
    element: <TransactionDetailsPage />,
    permissions: [MerchantPermissions.MERCHANT_TRANSACTIONS_READ],
  },
  {
    path: AppRoutesEnum.merchantTransfer,
    element: <MerchantTransferPage />,
    permissions: [MerchantPermissions.MERCHANT_TRANSFERS_READ],
  },
  {
    path: AppRoutesEnum.viewCashback,
    element: <ViewCashbackPage />,
  },
  {
    path: AppRoutesEnum.merchantLastMonthReports,
    element: <MerchantLastMonthReportsPage />,
    permissions: [MerchantPermissions.MERCHANT_REPORTING_READ],
  },
  {
    path: AppRoutesEnum.merchantReporting,
    element: <MerchantReportingPage />,
    permissions: [MerchantPermissions.MERCHANT_REPORTING_READ],
  },
  {
    path: AppRoutesEnum.merchantVerification,
    element: <MerchantVerificationPage />,
  },
  {
    path: AppRoutesEnum.apiKeys,
    element: <ApiKeysPage />,
    permissions: [MerchantPermissions.MERCHANT_API_KEYS_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.roleManagement,
    element: <RoleManagementPage />,
    permissions: [MerchantPermissions.MERCHANT_ROLE_MANAGEMENT_READ],
  },
];

export const SysAdminRoutes: IRoutes[] = [
  {
    path: AppRoutesEnum.lastMonthReports,
    element: <LastMonthReportsPage />,
    permissions: [UserPermissions.ADMIN_REPORTING_READ],
  },
  {
    path: AppRoutesEnum.merchantUsers,
    element: <MerchantUsersPage />,
    permissions: [UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.adminUsers,
    element: <AdminUserManagementPage />,
    permissions: [UserPermissions.ADMIN_SYSTEM_USER_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.adminRoleManagement,
    element: <AdminRoleManagementPage />,
    permissions: [UserPermissions.ADMIN_ROLE_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.merchants,
    element: <MerchantsPage />,
    permissions: [UserPermissions.ADMIN_MERCHANTS_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.networks,
    element: <NetworkPage />,
    permissions: [UserPermissions.ADMIN_NETWORKS_MANAGEMENT_READ],
  },
  {
    path: AppRoutesEnum.systemSettlementTransfers,
    element: <SystemSettlementTransfersPage />,
    permissions: [UserPermissions.ADMIN_TRANSFERS_READ],
  },
  {
    path: AppRoutesEnum.adminMerchantTransfer,
    element: <AdminMerchantTransfersPage />,
    permissions: [UserPermissions.ADMIN_TRANSFERS_READ],
  },
  {
    path: AppRoutesEnum.systemSettlementTransactions,
    element: <SystemSettlementTransactionsPage />,
    permissions: [UserPermissions.ADMIN_TRANSACTIONS_READ],
  },
  {
    path: AppRoutesEnum.adminTransactionDetails,
    element: <AdminTransactionDetailsPage />,
    permissions: [UserPermissions.ADMIN_TRANSACTIONS_READ],
  },
  {
    path: AppRoutesEnum.reporting,
    element: <ReportingPage />,
    permissions: [UserPermissions.ADMIN_REPORTING_READ],
  },
  {
    path: AppRoutesEnum.systemMaintenance,
    element: <SystemMaintenancePage />,
    permissions: [UserPermissions.ADMIN_SYSTEM_WALLET_MANAGEMENT_UPDATE],
  },
];
