import React, { lazy } from 'react';

import { Outlet } from 'react-router-dom';

import { page404 } from '@/routes/public';

import { PageLoader } from '@/components/Loaders/PageLoader';

import { UserPermissions } from '@/enum/permission.enum';

import { useAuth } from '@/context/auth.context';

import useUserAccess from '@/hooks/useUserAccess';

const LoginPage = lazy(() => import('@/pages/login'));

/**
 * Require auth component for making private routes
 * @component
 * @example
 * <AuthGuard />
 */
export const AuthGuard: React.FC<{
  permissions?: UserPermissions | UserPermissions[];
}> = ({ permissions }) => {
  const { isLoggedIn, user, isLoading } = useAuth();
  const { hasAccess } = useUserAccess(permissions);

  if (!isLoggedIn) {
    return <LoginPage shouldRedirect={false} />;
  }

  if (user) {
    if (!hasAccess) {
      return page404.element;
    }
    return <Outlet />;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return <Outlet />;
};
