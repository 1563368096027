import { useCallback, useMemo } from 'react';

import { UserPermissions } from '@/enum/permission.enum';

import { useAuth } from '@/context/auth.context';

function useUserAccess(permission: UserPermissions | UserPermissions[] = []) {
  const { user } = useAuth();
  const checkAccess = useCallback(
    (permission: UserPermissions | UserPermissions[] = []) => {
      const permissionsToCheck = Array.isArray(permission)
        ? permission
        : [permission];

      return (
        !!user &&
        (!permissionsToCheck.length ||
          permissionsToCheck.some((x) =>
            user.roles.some((y) => y.permissions.includes(x)),
          ))
      );
    },
    [user],
  );
  const hasAccess = useMemo(
    () => checkAccess(permission),
    [checkAccess, permission],
  );

  return { hasAccess, checkAccess };
}

export default useUserAccess;
