import * as React from 'react';
import { forwardRef, useMemo } from 'react';

import { FormControl, FormErrorMessage, useTheme } from '@chakra-ui/react';
import { default as PhoneInputComponent } from 'react-phone-input-2';

import { FormPhoneInputProps } from '@/elements/FormPhoneInput';

import FormLabel from '../FormLabel';
import 'react-phone-input-2/lib/bootstrap.css';

export type PhoneInputProps = FormPhoneInputProps & { error?: string };

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      id,
      error,
      label,
      country,
      containerProps,
      hasTooltip,
      tooltipText,
      isInvalid,
      isDisabled,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    return (
      <FormControl
        css={`
          .react-tel-input .form-control {
            border-color: ${theme.colors.app.borderGray} !important;
          }
          .react-tel-input .form-control.invalid {
            border-color: ${theme.colors.app.widgetRed} !important;
          }
          .react-tel-input .form-control:hover {
            border-color: ${theme.colors.app.widgetGray} !important;
          }
          .react-tel-input .form-control:disabled {
            background-color: ${theme.colors.app.neutralGray} !important;
          }
        `}
        id={inputId}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        {...containerProps}
      >
        <FormLabel
          hasTooltip={hasTooltip}
          id={inputId}
          label={label}
          tooltipText={tooltipText}
        />

        <PhoneInputComponent
          country={country || 'us'}
          disabled={isDisabled}
          enableAreaCodes={true}
          inputClass={isInvalid ? 'invalid' : ''}
          inputProps={{
            id: inputId,
            ref,
          }}
          inputStyle={{
            border: '0.063rem solid',
            boxShadow: 'none',
            borderRadius: '0.25rem',
            color: theme.colors.app.textBlack,
            width: '100%',
            padding: '0.438rem 0.625rem 0.438rem 3.75rem',
          }}
          {...rest}
        />

        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
