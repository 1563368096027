import React, { FC, SVGProps } from 'react';

export const SortAscIcon: FC<SVGProps<any> & { color?: string }> = ({
  color,
  ...props
}) => (
  <svg
    fill='none'
    height={15}
    viewBox='0 0 15 15'
    width={15}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.68188 14.6914H12.1584C12.9762 14.6914 13.5881 14.4883 13.9944 14.082C14.4058 13.681 14.6116 13.0768 14.6116 12.2695V2.73047C14.6116 1.92318 14.4058 1.31901 13.9944 0.917969C13.5881 0.511719 12.9762 0.308594 12.1584 0.308594H2.68188C1.86418 0.308594 1.24959 0.511719 0.838135 0.917969C0.431885 1.31901 0.22876 1.92318 0.22876 2.73047V12.2695C0.22876 13.0768 0.431885 13.681 0.838135 14.082C1.24959 14.4883 1.86418 14.6914 2.68188 14.6914ZM7.4397 11.5117C7.26261 11.5117 7.11678 11.457 7.0022 11.3477C6.88761 11.2331 6.83032 11.0846 6.83032 10.9023V6.89453L6.89282 5.18359L6.08813 6.15234L5.13501 7.11328C5.01522 7.23307 4.87199 7.29297 4.70532 7.29297C4.53866 7.29297 4.39803 7.23828 4.28345 7.12891C4.17407 7.01432 4.11938 6.8737 4.11938 6.70703C4.11938 6.53516 4.17407 6.39453 4.28345 6.28516L6.97095 3.61328C7.12199 3.45703 7.27824 3.37891 7.4397 3.37891C7.60116 3.37891 7.75741 3.45703 7.90845 3.61328L10.5959 6.28516C10.7053 6.39974 10.76 6.54036 10.76 6.70703C10.76 6.8737 10.7027 7.01432 10.5881 7.12891C10.4736 7.23828 10.3329 7.29297 10.1663 7.29297C9.98918 7.29297 9.84595 7.23307 9.73657 7.11328L8.79126 6.15234L7.97876 5.17578L8.04126 6.89453V10.9023C8.04126 11.0846 7.98397 11.2331 7.86938 11.3477C7.76001 11.457 7.61678 11.5117 7.4397 11.5117Z'
      fill={color}
    />
  </svg>
);
