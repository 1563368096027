import React, { FC, Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { CookieModal } from '@/components/CookieModal';
import { Header } from '@/components/Header';
import { PageLoader } from '@/components/Loaders/PageLoader';
import { RouteChangeWatcher } from '@/components/RouteChangeWatcher';
import { RenderSidebarComponent } from '@/components/Sidebar/renderSidebar';

import { AuthProvider } from '@/context/auth.context';
import { LocalStorageProvider } from '@/context/localStorage.context';
import { CurrentMerchantProvider } from '@/context/merchant.context';

import { BannerProvider } from './context/banner.context';

const AppLayout: FC = () => {
  return (
    <LocalStorageProvider>
      <AuthProvider>
        <CurrentMerchantProvider>
          <BannerProvider>
            <RouteChangeWatcher>
              <Header />
              <RenderSidebarComponent />
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
              <CookieModal />
            </RouteChangeWatcher>
          </BannerProvider>
        </CurrentMerchantProvider>
      </AuthProvider>
    </LocalStorageProvider>
  );
};

export default AppLayout;
