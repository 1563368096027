import * as React from 'react';
import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  Input as ChakraInput,
  FormControl,
  Box,
  Button,
  InputProps as ChakraInputProps,
  FormControlProps,
} from '@chakra-ui/react';
import moment from 'moment';
import DatePicker, {
  CalendarContainerProps,
  CalendarContainer,
} from 'react-datepicker';

import { FormLabel } from '@/elements';
import { FormDateRangePickerProps } from '@/elements/FormDateRangePicker';

type DateRangePickerProps = Omit<ChakraInputProps, 'label'> &
  FormDateRangePickerProps & {
    date?: (Date | null)[];
    setDateRange?: Function;
    error?: string | undefined;
    containerProps?: FormControlProps;
  };

export interface IDateOptions {
  id: string;
  label: string;
  value: Date[];
}

interface CustomCalendarContainerProps {
  value: (Date | null)[];
  options?: { id: string; label: string; value: Date[] }[];
  onChange?: Function;
}

interface DatePeriodButtonProps {
  label: string;
  value: Date[];
  isActive: boolean;
  onChange?: Function;
}

const DatePeriodButton: React.FC<DatePeriodButtonProps> = ({
  label,
  value,
  onChange,
  isActive,
}) => {
  const buttonRef = useRef<any>();

  useEffect(() => {
    if (isActive && buttonRef.current) {
      buttonRef.current.scrollIntoView({ block: 'center' });
    }
  }, [isActive]);

  return (
    <Button
      borderLeft='none'
      borderRadius='0px'
      borderRight='none'
      key={label}
      onClick={() => onChange?.(value)}
      ref={buttonRef}
      size='sm'
      variant={isActive ? 'solid' : 'outline'}
      w='100%'
    >
      {label}
    </Button>
  );
};

const CustomCalendarContainer: React.FC<CustomCalendarContainerProps> = ({
  value,
  options,
  onChange,
  children,
  ...props
}) => {
  if (options?.length) {
    return (
      <Box as={CalendarContainer} {...props} overflow='hidden'>
        <Box display='flex' flexDirection={{ base: 'column', md: 'row' }}>
          <Box borderRight='1px solid #aeaeae' position='relative'>
            {children}
          </Box>
          <Box maxHeight='272px' overflow='auto'>
            {options.map((x) => (
              <DatePeriodButton
                isActive={
                  moment(value[0]).isSame(x.value[0]) &&
                  moment(value[1]).isSame(x.value[1])
                }
                key={x.id}
                label={x.label}
                onChange={onChange}
                value={x.value}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
  return <CalendarContainer {...props}>{children}</CalendarContainer>;
};

const DateRangePicker = forwardRef<HTMLInputElement, DateRangePickerProps>(
  (
    {
      date,
      setDateRange,
      label,
      options,
      error,
      id,
      variant,
      containerProps,
      tooltipText,
      hasTooltip,
      selectsRange = true,
      isClearable = true,
      isDisabled,
      ...rest
    },
    externalRef,
  ) => {
    const internalRef = useRef<any>();
    const [startDate, endDate] = date || [];

    const ref = useMemo(
      () => externalRef || internalRef,
      [externalRef, internalRef],
    ) as React.MutableRefObject<any>;

    const inputId = useMemo(() => id || `${Date.now()}-${Math.random()}`, [id]);

    useEffect(() => {
      if (date?.length && ref.current) {
        ref.current.setPreSelection(date[1] ?? date[0]);
      }
    }, [date, ref]);

    const handleDateChange = useCallback(
      ([start, end]: Date[]) => {
        if (setDateRange) {
          const startFormatted = start
            ? moment(start).startOf('day').toDate()
            : null;
          const endFormatted = end ? moment(end).endOf('day').toDate() : null;
          setDateRange([startFormatted, endFormatted]);
        }
      },
      [setDateRange],
    );

    const calendarContainer = useCallback(
      (props: CalendarContainerProps) => (
        <CustomCalendarContainer
          {...props}
          onChange={setDateRange}
          options={options}
          value={date as Date[]}
        />
      ),
      [date, options, setDateRange],
    );

    return (
      <FormControl
        id={inputId}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        {...containerProps}
      >
        {label && (
          <FormLabel
            hasTooltip={hasTooltip}
            id={inputId}
            label={label}
            tooltipText={tooltipText}
          />
        )}
        <ChakraInput
          as={DatePicker}
          calendarContainer={calendarContainer}
          endDate={endDate}
          id={inputId}
          isClearable={isClearable || true}
          onChange={handleDateChange as any}
          ref={ref as RefObject<HTMLInputElement>}
          selectsRange={selectsRange || true}
          startDate={startDate}
          variant={variant}
          {...rest}
        />
      </FormControl>
    );
  },
);

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;
