import React, { FC, SVGProps } from 'react';

export const FilterIcon: FC<SVGProps<any> & { color?: string }> = ({
  color = '#FD108A',
  ...props
}) => (
  <svg
    fill='none'
    height={17}
    viewBox='0 0 17 17'
    width={17}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.36719 16.8281C9.45573 16.8281 10.4792 16.6198 11.4375 16.2031C12.401 15.7865 13.25 15.2109 13.9844 14.4766C14.7188 13.7422 15.2943 12.8958 15.7109 11.9375C16.1276 10.974 16.3359 9.94792 16.3359 8.85938C16.3359 7.77083 16.1276 6.7474 15.7109 5.78906C15.2943 4.82552 14.7188 3.97656 13.9844 3.24219C13.25 2.50781 12.401 1.93229 11.4375 1.51562C10.474 1.09896 9.44792 0.890625 8.35938 0.890625C7.27083 0.890625 6.24479 1.09896 5.28125 1.51562C4.32292 1.93229 3.47656 2.50781 2.74219 3.24219C2.01302 3.97656 1.4401 4.82552 1.02344 5.78906C0.606771 6.7474 0.398438 7.77083 0.398438 8.85938C0.398438 9.94792 0.606771 10.974 1.02344 11.9375C1.4401 12.8958 2.01562 13.7422 2.75 14.4766C3.48438 15.2109 4.33073 15.7865 5.28906 16.2031C6.2526 16.6198 7.27865 16.8281 8.36719 16.8281ZM3.99219 7.25C3.8151 7.25 3.66667 7.19792 3.54688 7.09375C3.43229 6.98958 3.375 6.85417 3.375 6.6875C3.375 6.51562 3.43229 6.3776 3.54688 6.27344C3.66667 6.16406 3.8151 6.10938 3.99219 6.10938H12.7734C12.9505 6.10938 13.0964 6.16406 13.2109 6.27344C13.3307 6.3776 13.3906 6.51562 13.3906 6.6875C13.3906 6.85417 13.3307 6.98958 13.2109 7.09375C13.0964 7.19792 12.9505 7.25 12.7734 7.25H3.99219ZM5.1875 9.92188C5.00521 9.92188 4.85677 9.86979 4.74219 9.76562C4.6276 9.66146 4.57031 9.52344 4.57031 9.35156C4.57031 9.17969 4.6276 9.04167 4.74219 8.9375C4.85677 8.82812 5.00521 8.77344 5.1875 8.77344H11.5781C11.7604 8.77344 11.9089 8.82812 12.0234 8.9375C12.138 9.04167 12.1953 9.17969 12.1953 9.35156C12.1953 9.52344 12.138 9.66146 12.0234 9.76562C11.9089 9.86979 11.7604 9.92188 11.5781 9.92188H5.1875ZM6.4375 12.5938C6.25521 12.5938 6.10677 12.5417 5.99219 12.4375C5.8776 12.3333 5.82031 12.1953 5.82031 12.0234C5.82031 11.8516 5.8776 11.7135 5.99219 11.6094C6.10677 11.5052 6.25521 11.4531 6.4375 11.4531H10.3359C10.513 11.4531 10.6589 11.5052 10.7734 11.6094C10.888 11.7135 10.9453 11.8516 10.9453 12.0234C10.9453 12.1953 10.888 12.3333 10.7734 12.4375C10.6589 12.5417 10.513 12.5938 10.3359 12.5938H6.4375Z'
      fill={color}
    />
  </svg>
);
