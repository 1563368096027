import '@/i18n';
import '@/index.css';

import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import { setupStore } from '@/store';

import { REACT_APP_GTM_ID } from '@/utils/constants';
import Fonts from '@/utils/customFonts';
import theme from '@/utils/extendTheme';

TagManager.initialize({ gtmId: REACT_APP_GTM_ID });

const store = setupStore();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// Must be used without strict mode until Chakra UI supports React 18
// https://github.com/chakra-ui/chakra-ui/issues/5321
root.render(
  <Provider store={store}>
    <ChakraProvider portalZIndex={9999} theme={theme}>
      <Fonts />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
