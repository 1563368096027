import React, { useCallback } from 'react';

import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ISimpleMerchant } from '@/types/domain';

import { CloseModalIconButton } from '@/components/IconButton/close-modal';
import MerchantSelect from '@/components/MerchantSelect';

import { useCurrentMerchant } from '@/context/merchant.context';

interface ISelectMerchantModal {
  isOpen: boolean;
  onClose(): void;
}

/**
 * Modal component for needed merchant selection
 * @component
 * @param    {ISelectMerchantModal}   props     Component props
 * @param    {boolean}  props.isOpen  Open modal or not
 * @param    {() => void}  props.onClose  Function to close modal
 * @example
 * <SelectMerchantModal
 *   isOpen={isOpen}
 *   onClose={onClose}
 * />
 */
export const SelectMerchantModal: React.FC<ISelectMerchantModal> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { merchant, availableMerchants, changeMerchant } = useCurrentMerchant();

  const handleMerchantSelect = useCallback(
    (merchant: ISimpleMerchant) => {
      changeMerchant(merchant.displayId);
      onClose();
    },
    [changeMerchant, onClose],
  );

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <CloseModalIconButton onClick={onClose} />

          <ModalBody p='45px 25px 30px'>
            <Heading color='app.pink' mb='40px' order={1} textAlign='center'>
              {t('login.selectMerchantStep.label')}
            </Heading>

            <MerchantSelect
              merchants={availableMerchants}
              onChange={handleMerchantSelect}
              selected={merchant?.displayId}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
